import { useNavigate } from 'react-router-dom'
import ArrowLeft from 'assets/images/arrow-left.svg'
import { Container } from './styles'

export interface IProps {
	onClick?: () => void
	text?: string
}

function GoBack(props: IProps) {
	const { onClick, text } = props
	const navigate = useNavigate()

	function goBack() {
		navigate(-1)
	}

	return (
		<Container onClick={onClick ? onClick : goBack} id='go-back'>
			<img src={ArrowLeft} alt='Voltar' />
			<p>{text ? text : 'Voltar'}</p>
		</Container>
	)
}

export default GoBack
