import styled from "styled-components";

export const PasswordWrapper = styled.div`
  position: relative;

  button {
    background: 0;
    border: 0;
    width: 35px;
    position: absolute;
    right: 8px;
    top: 20px;
  }
`;
