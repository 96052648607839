import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Container } from './styles'
import { ICupomPage } from './types'
import HeaderPage from 'shared/components/HeaderPage'
import { toMoney } from 'shared/utils/format'
import { IModalDeleteTarget } from 'shared/components/ModalAction/types'
import Ticket from 'shared/components/Ticket'
import Modal from 'shared/components/Modal'
import DiscountBadge from 'shared/components/DiscountBadge'
import {
	IResponseCupons,
	getCupomPaginated
} from 'shared/services/cupom.service'
import { toasterError } from 'shared/utils/toaster'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { formatDate } from 'shared/utils/date'
import Button from 'shared/components/ButtonPrimary'
import Loading from 'shared/components/Loading'

function MyCupons() {
	const ITEMS_PER_PAGE = 12
	const [cuponsPage, setCuponsPage] = useState<ICupomPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
		hasNextPage: false
	})

	const [classAnimated, setAnimated] = useState('animated-right')
	const [showModal, setShowModal] = useState(false)
	const [cashbackDetail, setCashbackdetail] = useState<
		IResponseCupons | undefined
	>()
	const [loading, setLoading] = useState(false)
	const [cupom, setCupom] = useState<IResponseCupons[] | null>(null)

	function openModal(cupom: IResponseCupons) {
		setShowModal((show) => !show)
		setAnimated('')
		setCashbackdetail(cupom)
	}

	function onClose() {
		setShowModal((show) => !show)
	}

	useEffect(processTable, [cuponsPage.page])

	function processTable() {
		;(async () => {
			try {
				setLoading(true)
				const response = await getCupomPaginated({
					limite: ITEMS_PER_PAGE,
					page: cuponsPage.page,
					search: ''
				})

				if (!response.Dados.Itens.length) return
				setCupom(
					cupom ? [...cupom, ...response.Dados.Itens] : response.Dados.Itens
				)
				setCuponsPage({
					numberOfPages: response.Dados.NumeroTotalDePaginas,
					page: cuponsPage.page || 1,
					totalDocs: response.Dados.NumeroTotalDeItens,
					hasNextPage: response.Dados.TemProximaPagina
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Cupons'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function returnVouchers(ticket: IResponseCupons) {
		return (
			<Ticket
				key={ticket.Codigo}
				image={ticket?.Imagem || ''}
				title={ticket.Titulo || ''}
				qtd={ticket.QuantidadeDisponivel}
				value={
					ticket.PorcentagemDeDesconto
						? `${ticket.PorcentagemDeDesconto}% OFF`
						: toMoney(ticket.PorcentagemDeDesconto)
				}
				description={ticket.Descricao}
				name={ticket.Codigo}
				percent={ticket.PorcentagemDeDesconto}
				date={new Date(ticket.DataDeValidade) ?? new Date()}
				openModal={() => openModal(ticket)}
				height={175}
			/>
		)
	}

	function returnDate(date: string) {
		return formatDate(new Date(date), "dd 'de' MMMM")
	}

	const loadMoreData = () => {
		setCuponsPage({
			...cuponsPage,
			page: cuponsPage.page + 1
		})
	}

	return (
		<Container className={classAnimated}>
			<div>
				<div>
					<HeaderPage title='Meus Cupons' />
				</div>

				<div>
					{!!cupom?.length ? (
						<InfiniteScroll
							dataLength={cupom.length}
							next={loadMoreData}
							hasMore={cuponsPage.hasNextPage}
							loader={<Loading />}
							className='tickets'
						>
							{cupom.map((ticket) => returnVouchers(ticket))}
						</InfiniteScroll>
					) : (
						<DefaultPagePlaceholder />
					)}
				</div>
			</div>

			<Modal onClose={onClose} isActive={showModal} title='Detalhe do Ticket'>
				<section>
					<figure className='image-modal'>
						<img src={cashbackDetail?.Imagem} alt={'Imagem logo'} />
					</figure>

					<span>
						<div className='header'>
							<p className='partner-name'>{cashbackDetail?.Parceiro}</p>
							{cashbackDetail?.PorcentagemDeDesconto ? (
								<DiscountBadge
									background='#fdb7d0'
									color='var(--color-rosa)'
									percetage={cashbackDetail?.PorcentagemDeDesconto + '%'}
								/>
							) : null}
						</div>

						<p className='description'>{cashbackDetail?.Titulo}</p>
						<p className='description'>{cashbackDetail?.Descricao}</p>

						<span className='header-value'>
							<p className='value-total'>Validade: </p>
							<p className='value-total'>
								{cashbackDetail?.DataDeValidade
									? returnDate(cashbackDetail?.DataDeValidade)
									: '-'}
							</p>
						</span>
						<span className='header-value'>
							<p className='value-total'>Restantes: </p>
							<p className='value-total'>
								{cashbackDetail?.QuantidadeDisponivel}
							</p>
						</span>

						<span className='header-value'>
							<p className='value-total'>Valor: </p>
							<p className='value-total'>
								{cashbackDetail?.PorcentagemDeDesconto}%
							</p>
						</span>
					</span>

					<h2 className='id-generator'>{cashbackDetail?.Codigo}</h2>
				</section>
			</Modal>
		</Container>
	)
}

export default MyCupons
