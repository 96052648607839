import { ICashback } from 'shared/interfaces/cashback'
import { IPaginated } from 'shared/interfaces/paginated'
import { IResponse } from 'shared/interfaces/response'
import axiosInstance from 'shared/utils/env'
import { removeCharacterSpecial } from 'shared/utils/validators'

export interface IRequestLaunchCashback {
	Valor: number
	CpfParticipante: string
	Cnpj: string
}

export async function createCashback(
	payload: IRequestLaunchCashback
): Promise<void> {
	const response = await axiosInstance.post(`/lancamento/`, {
		...payload
	})

	return response.data
}

export interface IRequestPaginated {
	page: number
	limite: number
	search: string
	dateInit: string
	dateFinal: string
}

export async function getCashback(
	pagePayload: IRequestPaginated
): Promise<IResponse<IPaginated<ICashback[]>>> {
	const { limite, page, search, dateFinal, dateInit } = pagePayload

	const searchString = removeCharacterSpecial(search)
	const response = await axiosInstance.get(`/lancamento
	?pagina=${page}
	&limite=${limite}
	&dataInicio=${dateInit}
	&dataFim=${dateFinal}
	&termoDaBusca=${searchString}`)

	return response.data
}

export async function deleteLancamento(id: string): Promise<void> {
	const response = await axiosInstance.delete(`/lancamento/${id}`)

	return response.data
}
