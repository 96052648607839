export interface ICashback {
	ParticipanteId: string
	UsuarioIdQueLancou: string
	ParceiroId: string
	Valor: number
	Id: string
	DataHoraCadastro: string
	DataHoraAlteracao: string | null
	IdDoUsuarioQueAlterou: string | null
	Status: string
	Motivo?: string
	NomeDoOperador: string
	NomeDoParticipante: string
	RazaoSocial: string
	Validade: string
	JaFoiUtilizado: false
	DataHoraResgate: string | null
	TipoVoucher: CashTypeEnum
	ComoFoiGerado: CashGenerateTypeEnum
	Imagem: string
	LogoMarca: string
	Cnpj: string
	NomeFantasia: string
	Cpf: string
	StatusCupom: StatusCupomEnum
	CarteiraId: string
	Codigo: string
}

export enum CashTypeEnum {
	Cashback = 'Cashback',
	Cupom = 'Cupom'
}

export enum CashGenerateTypeEnum {
	GeradoAutomaticamente = 'GeradoAutomaticamente',
	GeradoPeloProprioUsuario = 'GeradoPeloProprioUsuario'
}

export enum StatusCupomEnum {
	Ativo = 'Ativo',
	EmEspera = 'EmEspera',
	BloqueadoPeloOperador = 'BloqueadoPeloOperador',
	BloqueadoPeloAdministrador = 'BloqueadoPeloAdministrador',
	Expirado = 'Expirado'
}
