import { IResponse } from 'shared/interfaces/response'
import axiosInstance from 'shared/utils/env'

export async function getTermOfAdhesion(): Promise<IResponse<string>> {
	const response = await axiosInstance.get(`/termo-de-adesao`)

	return response.data
}

export async function updateTermsOfAdhesion(term: string): Promise<void> {
	const response = await axiosInstance.post(
		`/termo-de-adesao`,
		{ termo: term },
		{
			headers: { 'Content-Type': 'application/json' }
		}
	)

	return response.data
}
