import { useEffect, useState } from 'react'
import InputPrimary from 'shared/components/InputPrimary'
import useForm from 'shared/hooks/useForm'
import Button from 'shared/components/ButtonPrimary'
import Modal from 'shared/components/Modal'
import {
	toasterError,
	toasterPromise,
	toasterSuccess
} from 'shared/utils/toaster'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { createCashback } from 'shared/services/releases.service'
import { currencyStringToNumber } from 'shared/utils/format'
import { cleanDocumentNumber } from 'shared/utils/validators'
import { isAdmin } from 'shared/utils/isAdmin'

export interface IProps {
	showModal: boolean
	onClose: () => void
	cpfParticipant?: string
	value?: number
}

function ModalLaunchCashback(props: IProps) {
	const { onClose, showModal, cpfParticipant, value } = props

	const { user, partner } = useTypedSelector(['user', 'partner'])
	const valor = useForm('valorMonetario')
	const cpf = useForm('cpf')
	const cnpj = useForm('cnpj')
	const [showModalConfirmation, setShowModalConfirmation] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (!cpfParticipant || !value) return
		cpf.setValue(cpfParticipant)
		valor.setValue(value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cpfParticipant, value])

	function toggleModal() {
		setShowModalConfirmation((show) => !show)
	}

	async function handleSubmit() {
		try {
			setLoading(true)
			toasterPromise()
			if (
				!cpf.fieldIsValid ||
				(!valor.fieldIsValid && isAdmin() && !cpf.fieldIsValid)
			) {
				return
			}

			await createCashback({
				CpfParticipante: cleanDocumentNumber(cpf.value),
				Cnpj: cleanDocumentNumber(cnpj.value) || cleanDocumentNumber(partner.Cnpj),
				Valor: currencyStringToNumber(valor.value)
			})
			cpf.clearValue()
			valor.clearValue()
			cnpj.clearValue()

			toasterSuccess('Cashback cadastrado com sucesso!')
			toggleModal()
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: 'Ops... houve um erro ao criar CashBack, tente mais tarde!'
			)
		} finally {
			setLoading(false)
			setShowModalConfirmation(false)
		}
	}

	return (
		<Modal onClose={onClose} isActive={showModal}>
			<div className='background'>
				<div className='form'>
					<InputPrimary
						{...cpf}
						name='cpf'
						id='cpf'
						label='CPF do participante'
						inputMode='numeric'
						disabled={cpfParticipant ? true : false}
					/>

					{isAdmin() ? (
						<InputPrimary
							{...cnpj}
							name='cnpj'
							id='cnpj'
							label='CNPJ do parceiro'
							inputMode='numeric'
						/>
					) : null}
					<InputPrimary
						{...valor}
						name='valor'
						id='valor'
						label='Valor'
						maxLenght={10}
						inputMode='numeric'
					/>
				</div>

				<Button
					name='Lançar'
					disabled={
						!valor.fieldIsValid ||
						(!cpf.fieldIsValid && isAdmin() && !cpf.fieldIsValid)
					}
					onClick={toggleModal}
					loading={loading}
				/>

				<Modal
					onClose={toggleModal}
					isActive={showModalConfirmation}
					title='Confirmar operação'
				>
					<div>
						<p>Deseja realizar essa ação?</p>

						<Button
							name='Confirmar'
							disabled={false}
							onClick={handleSubmit}
							loading={loading}
						/>
					</div>
				</Modal>
			</div>
		</Modal>
	)
}

export default ModalLaunchCashback
