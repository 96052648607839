import styled from 'styled-components'
import { StylesConfig } from 'react-select'

export const SelectBlock = styled.div`
	position: relative;
	padding: 0.5rem 0;

	label {
		position: absolute;
		background: white;
		padding: 2px;
		font-size: 12px;
		left: 6px;
		top: 0;
		color: var(--color-rosa);
	}
`

export const customStyles: StylesConfig = {
	menu: (styles, state) => ({
		...styles
	}),
	option: (styles, state) => ({
		color: '#5d615d',
		background: state.isSelected ? 'white' : 'white',
		padding: 16,
		cursor: 'pointer',

		':hover': {
			background: '#c7c7c7',
			color: '#5d615d'
		}
	}),
	control: (styles, state) => ({
		// ...styles,
		// width: '100%',
		// height: 30,
		background: 'white',
		border: 'solid 1px #d1d1d1',
		// padding: 12,
		borderRadius: 4,
		// cursor: 'pointer',
		// zIndex: 99,
		display: 'flex',
		'&:hover': {
			// Overwrittes the different states of border
			boxShadow: '0 0 2px 1px var(--color-rosa)',

			borderColor: state.isFocused
				? 'var(--color-instagram)'
				: 'var(--color-instagram)'
		}
	}),
	singleValue: (styles, state) => ({
		// opacity: state.isDisabled ? 0.2 : 0.8,
		color: '#5d615d',
		fontSize: 14
	}),
	placeholder: (styles, state) => ({
		// opacity: state.isDisabled ? 0.2 : 0.4,
		fontSize: 14,
		color: '#5d615d80'
	}),
	noOptionsMessage: (styles, state) => ({
		padding: 15,
		// opacity: state ? 0.2 : 0.4,
		color: '#5d615d'
	}),
	multiValue: (styles, state) => ({
		...styles,
		background: '#7f0bdd1a',
		borderRadius: 4,
		padding: '6px 8px'
	}),
	multiValueLabel: (styles, state) => ({
		...styles,
		color: '#7f0bdd',
		padding: 0,
		paddingRight: 8,
		fontSize: 12
	}),
	multiValueRemove: (styles, state) => ({
		...styles,
		padding: 0,
		color: '#fc5c94',
		'&:hover': {
			background: '#7f0bdd1a'
		}
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: () => ({
		color: '#5d615d80'
	}),

	valueContainer: (styles, state) => ({
		...styles,
		display: 'flex',
		alignItems: 'center',
		overflow: 'auto',
		// flex: 1,
		maxHeight: 65,
		minHeight: 50
	})
}
