import { Action, combineReducers, Reducer, ReducersMapObject } from "redux";
import { IActionsCreators } from "../../interfaces/action";
import { IStore } from "../../interfaces/store";
import token from "./token";
import user from "./user";
import partner from "./partner";
import activity from "./activity";

const reducers: ReducersMapObject<IStore, Action<IActionsCreators>> = {
  user,
  token,
  partner,
  activity
};

export const Reducers: Reducer<
  IStore,
  Action<IActionsCreators>
> = combineReducers(reducers);
