import styled from "styled-components";

export const DefaultPagePlaceholderWrapper = styled.div`
  height: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  figure {
    border-radius: 50%;
    width: 45px;
    padding: 41px;
    height: 45px;
    background: #7f0bdd1a;
    box-shadow: #7f0bdd26 0px 0px 6px 5px;
  }
  .placeholder-image {
    height: 100%;
  }

  .placeholder-text {
    color: #7f0bdd26;
    font-weight: bold;
    margin-top: 16px;
    font-family: Roboto;
    font-size: 14px;
    max-width: 218px;
    text-align: center;
  }
`;
