import { Sentry, Dots, Spinner } from "react-activity";
import "react-activity/dist/library.css";

interface ILoadingProps {
  color?: string;
  size?: number;
  type?: LoadingEnum;
}

export enum LoadingEnum {
  sentry = "sentry",
  spinner = "spinner",
  dots = "dots",
}

function Loading({ color = "#fc5c94", size = 24, type }: ILoadingProps) {
  if (type === LoadingEnum.dots) {
    return <Dots size={size} color={color} speed={0.6} animation={true} />;
  }

  if (type === LoadingEnum.spinner) {
    return <Spinner size={size} color={color} speed={0.6} animation={true} />;
  }
  return <Sentry size={size} color={color} speed={0.6} animation={true} />;
}

export default Loading;