import { useState } from "react";
import HeaderAdmin from "../HeaderAdmin";
import MenuNav from "../MenuNav";

export interface IProps {
  children: JSX.Element;
}

function Page(props: IProps) {
  const { children } = props;

  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu((show) => !show);
  }

  return (
    <div>
      <HeaderAdmin handleOpen={toggleMenu} isOpen={showMenu}/>
      <MenuNav handleOpen={toggleMenu} isOpen={showMenu} />
      <main className="container-middle mt120">{children}</main>
    </div>
  );
}

export default Page;
