import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Container } from './styles'
import useForm from 'shared/hooks/useForm'
import Button from 'shared/components/ButtonPrimary'
import HeaderNotLogged from 'shared/components/HeaderNotLogged'
import GoBack from 'shared/components/GoBack'
import FooterNotLogged from 'shared/components/FooterNotLogged'
import InputPrimary from 'shared/components/InputPrimary'
import {
	toasterError,
	toasterPromise,
	toasterSuccess
} from 'shared/utils/toaster'
import { formatCnpjCpf } from 'shared/utils/format'
import { recoverPassword } from 'shared/services/auth.service'
import { cleanDocumentNumber } from 'shared/utils/validators'

interface IRouteProps {
	cpf?: string
}
function RecoverPassword() {
	const route = useLocation() || null
	const state = route.state as IRouteProps

	const cpf = useForm('cpf')
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	useEffect(initState, [state?.cpf])

	function initState() {
		state.cpf && cpf.setValue(formatCnpjCpf(state.cpf))
	}

	async function handleSubmit() {
		try {
			toasterPromise()
			if (!cpf.fieldIsValid) {
				return
			}
			setLoading(true)

			const response = await recoverPassword(cleanDocumentNumber(cpf.value))

			if (response.Sucesso) {
				toasterSuccess(response.Mensagem || 'Solicitação enviada por e-mail!')
				navigate(-1)
				return
			}
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: 'Ocorreu um erro'
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Container>
			<HeaderNotLogged title='Recuperar Senha' />
			<div className='container-middle'>
				<GoBack />
				<h1 className='title'>Recuperar Senha</h1>
				<h2 className='subtitle'>
					Enviaremos um e-mail para redefinição de senha.
				</h2>
				<div className='form-data'>
					<InputPrimary
						{...cpf}
						type='text'
						id='cpf'
						name='cpf'
						label='CPF'
						className='w50'
						inputMode='numeric'
					/>

					<div className='btn-submit'>
						<Button
							loading={loading}
							name='ENVIAR'
							disabled={!cpf.fieldIsValid}
							onClick={handleSubmit}
						/>
					</div>
				</div>
			</div>
			<FooterNotLogged />
		</Container>
	)
}

export default RecoverPassword
