import { useEffect, useState } from 'react'
import { Container, LoadingWrapper, ModalContainer, TextTitle } from './styles'

import IconUpload from 'assets/images/upload-green.svg'

import HeaderPage from 'shared/components/HeaderPage'
import { useDebounce } from 'use-debounce'
import { ICashbackPage, ITableColumn, ITableNavProps, ITableRow } from './types'

import {
	FORMAT_BIRTH_BIRTH_DATE,
	formatCnpjCpf,
	formatDateToUS,
	toMoney
} from 'shared/utils/format'
import { startOfMonth } from 'date-fns'
import { IFromTo } from 'shared/interfaces/common'
import { toasterError, toasterSuccess } from 'shared/utils/toaster'
import { formatDate } from 'shared/utils/date'
import { CashGenerateTypeEnum, ICashback } from 'shared/interfaces/cashback'
import { tableStyle } from 'shared/styles/functions'
import Table from 'shared/components/Table'
import { IModalDeleteTarget } from 'shared/components/ModalAction/types'
import {
	getCashback,
	reduceVoucherByOperator
} from 'shared/services/cashback.service'
import Modal from 'shared/components/Modal'
import Loading, { LoadingEnum } from 'shared/components/Loading'
import Button from 'shared/components/ButtonPrimary'
import ModalAction from 'shared/components/ModalAction'
import BadgeStatus from 'shared/components/BadgeStatus'

interface ICashbackReduce {
	name: string
	cnpj: string
	id: string
}
function Cashback() {
	const ITEMS_PER_PAGE = 10
	const [cashbackPage, setCashBackPage] = useState<ICashbackPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)

	const [classAnimated, setAnimated] = useState('animated-right')
	const [loading, setLoading] = useState(false)
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [target, setTarget] = useState<IModalDeleteTarget | null>(null)
	const [modal, setModal] = useState(false)
	const [cashbackReduce, setCashbackReduce] = useState<ICashbackReduce | null>(
		null
	)

	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfMonth(new Date()),
		to: new Date()
	})

	function handleSetDates(value: IFromTo) {
		setSelectedDates(value)
	}

	function handleSearch(value: string): void {
		setSearch(value)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(processTable, [searchDebounce, cashbackPage.page, selectedDates])

	function processTable() {
		;(async () => {
			try {
				setLoading(true)

				const params = {
					limite: ITEMS_PER_PAGE,
					page: cashbackPage.page,
					search: searchDebounce,
					dateInit: selectedDates.from
						? formatDateToUS(selectedDates.from, FORMAT_BIRTH_BIRTH_DATE)
						: '',
					dateFinal: selectedDates.to
						? formatDateToUS(selectedDates.to, FORMAT_BIRTH_BIRTH_DATE)
						: ''
				}

				const response = await getCashback(params)

				const data = response.Dados.Itens

				const rows = data.map((item) => {
					return {
						status: renderStatus(item.Status, item.CarteiraId, item.Motivo),
						parceiro: item.NomeFantasia,
						preco: toMoney(item.Valor),
						gerado:
							item.ComoFoiGerado === CashGenerateTypeEnum.GeradoAutomaticamente
								? 'Automático'
								: 'Usuário',
						vencimento: formatDate(new Date(item.Validade)),
						code: item.Codigo,
						dataResgate: item.DataHoraResgate
							? formatDate(
									new Date(item.DataHoraResgate),
									"dd/MM/yyyy 'às' HH:mm"
							  )
							: '-',
						cpf: String(formatCnpjCpf(item.Cpf) || '-'),
						edit: renderEdit(item)
					}
				})

				setTableRows(rows)

				setCashBackPage({
					numberOfPages: response.Dados.NumeroTotalDePaginas || 1,
					page: cashbackPage.page,
					totalDocs: response.Dados.NumeroTotalDeItens
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Cupons'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function renderStatus(status: string, id: string, motivo?: string) {
		return <BadgeStatus status={status} tooltip={motivo} id={id} />
	}

	function reduceVoucher() {
		;(async () => {
			try {
				setLoading(true)

				if (!cashbackReduce) return
				await reduceVoucherByOperator(cashbackReduce.id)
				toasterSuccess('Cashback baixado com sucesso!')
				handleCloseModal()
				processTable()
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro, tente novamente mais tarde.'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function handleDeleteEnd() {
		setTarget(null)
	}

	function handleCloseModal() {
		setModal(false)
		setCashbackReduce(null)
	}

	function openModalReduce(id: string, cnpj: string, name: string) {
		setAnimated('')

		setCashbackReduce({
			id,
			cnpj,
			name
		})
		setModal(true)
	}

	function renderEdit(cashback: ICashback) {
		return (
			<div className='flex w50'>
				{!cashback.DataHoraResgate ? (
					<button
						className='remove'
						onClick={() =>
							openModalReduce(cashback.Id, cashback.Cnpj, cashback.NomeFantasia)
						}
					>
						<img src={IconUpload} alt='Baixar cashback' />
					</button>
				) : (
					<div className='remove' />
				)}

				{/* <button
					className='remove'
					onClick={() =>
						openModalDeleteOrBlock(
							cashback.Id || '',
							cashback.NomeFantasia,
							'DELETE'
						)
					}
				>
					<img src={IconTrash} alt='Deletar Parceiro' />
				</button> */}
			</div>
		)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Status', accessor: 'status' },
		{ Header: 'Codigo', accessor: 'code', sortType: 'basic' },
		{ Header: 'Parceiro', accessor: 'parceiro', sortType: 'basic' },
		{
			Header: 'Valor',
			accessor: 'preco',
			sortType: 'basic'
		},

		{
			Header: 'Participante',
			accessor: 'cpf',
			sortType: 'basic'
		},
		{ Header: 'Gerado Por', accessor: 'gerado', sortType: 'basic' },
		{
			Header: 'Vencimento',
			accessor: 'vencimento',
			sortType: 'basic'
		},
		{
			Header: 'Utilizado em',
			accessor: 'dataResgate',
			sortType: 'basic'
		},
		{
			Header: 'Ações',
			accessor: 'edit',
			disableSortBy: true
		}
	]

	function goToPage(pageIndex: number) {
		setCashBackPage((state) => ({ ...state, page: pageIndex + 1 }))
	}
	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: cashbackPage.numberOfPages,
		pageIndex: cashbackPage.page - 1,
		totalDocs: cashbackPage.totalDocs
	}
	function handleClose() {
		setTarget(null)
	}

	return (
		<Container className={classAnimated}>
			<div className='header-filter'>
				<HeaderPage
					title='Cashback'
					placeholder='Pesquisar'
					returnSearch={handleSearch}
					id='cashback'
					handleSetDates={handleSetDates}
					selectedDates={selectedDates}
				/>
			</div>

			<div className='table'>
				<Table
					columns={tableColumns as any}
					data={tableRows}
					navProps={navProps}
					pageSize={ITEMS_PER_PAGE}
					noItensText='No momento, não há Cupom para serem exibidas.'
					{...tableStyle}
					padding={[16, 16, 16, 16]}
					round={false}
					dashedWhenDisabled={false}
					tertiaryColor=''
					isLoading={loading}
				/>
			</div>

			<ModalAction
				onClose={handleClose}
				target={target}
				type={'CASHBACK'}
				handleDeleteUserEnd={handleDeleteEnd}
			/>
			<Modal
				title={'Deseja baixar cashback?'}
				isActive={modal}
				onClose={handleCloseModal}
				// scrollRef="scroll-ref"
			>
				<ModalContainer>
					<TextTitle>{cashbackReduce?.name}</TextTitle>
					<TextTitle>Essa ação é irreversivel</TextTitle>

					{false ? (
						<LoadingWrapper>
							<Loading type={LoadingEnum.sentry} />
						</LoadingWrapper>
					) : (
						<Button
							onClick={reduceVoucher}
							name={'Confirmar'}
							disabled={false}
						/>
					)}
				</ModalContainer>
			</Modal>
		</Container>
	)
}

export default Cashback
