import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'shared/components/ButtonPrimary'
import GoBack from 'shared/components/GoBack'
import { toasterError, toasterPromise } from 'shared/utils/toaster'
import { Container, LoadingWrapper, ModalContainer, TextTitle } from './styles'
import useForm from 'shared/hooks/useForm'
import InputPassword from 'shared/components/InputPassword'
import Modal from 'shared/components/Modal'
import Loading, { LoadingEnum } from 'shared/components/Loading'
import { changeMyPassword } from 'shared/services/auth.service'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

function ChangePasswordLogged() {
	const password = useForm('password')
	const newPassword = useForm('password')

	const [loading, setLoading] = useState(false)
	const [classAnimated, setAnimated] = useState('animated-right')
	const [modal, setModal] = useState(false)
	const { user } = useTypedSelector(['user'])
	const navigate = useNavigate()

	function openModal() {
		setAnimated('')
		setModal(true)
	}

	function handleCloseModal() {
		setModal(false)
	}

	async function handleSubmit() {
		try {
			toasterPromise()
			if (!password.fieldIsValid || !user.Id) {
				return
			}
			handleCloseModal()
			setLoading(true)

			await changeMyPassword({
				userId: user.Id,
				NovaSenha: password.value,
				SenhaAtual: newPassword.value
			})
			navigate(-1)
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: 'Ocorreu um erro, tente novamente mais tarde.'
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Container className={classAnimated}>
			<div className='container-middle'>
				<GoBack />
				<h1 className='title'>Alterar Senha</h1>
				{/* <h2 className="subtitle">
          Enviaremos um e-mail para redefinição de senha.
        </h2> */}
				<div className='form-data'>
					<InputPassword
						{...password}
						type='text'
						id='password'
						name='password'
						label='Senha anterior'
					/>

					<InputPassword
						{...newPassword}
						type='text'
						id='newPassword'
						name='newPassword'
						label='Nova senha'
					/>

					<div className='btn-submit'>
						<Button
							loading={loading}
							name='Confirmar'
							disabled={!password.fieldIsValid || !newPassword.fieldIsValid}
							onClick={openModal}
						/>
					</div>
				</div>
			</div>

			<Modal
				title={'Deseja alterar sua senha?'}
				isActive={modal}
				onClose={handleCloseModal}
				// scrollRef="scroll-ref"
			>
				<ModalContainer>
					<TextTitle>Essa ação é irreversivel</TextTitle>

					{false ? (
						<LoadingWrapper>
							<Loading type={LoadingEnum.sentry} />
						</LoadingWrapper>
					) : (
						<Button
							onClick={handleSubmit}
							name={'Confirmar'}
							disabled={false}
						/>
					)}
				</ModalContainer>
			</Modal>
		</Container>
	)
}

export default ChangePasswordLogged
