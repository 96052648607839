import { useEffect, useState } from 'react'
import Button from 'shared/components/ButtonPrimary'
import Editor from 'shared/components/Editor'
import { Container } from './styles'
import {
	getTermOfAdhesion,
	updateTermsOfAdhesion
} from 'shared/services/termOfAdhesion.service'
import Loading from 'shared/components/Loading'
import { toasterError, toasterSuccess } from 'shared/utils/toaster'

function CreateTermAdhesion() {
	const [text, setText] = useState('')
	const [loading, setLoading] = useState(false)

	async function onSubmit() {
		try {
			setLoading(true)

			await updateTermsOfAdhesion(JSON.stringify(text))
			toasterSuccess('Termo atualizado com sucesso!')
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: 'Ocorreu um erro ao atualizar o termo'
			)
		} finally {
			setLoading(false)
		}
	}

	function initialize() {
		;(async () => {
			try {
				setLoading(true)
				const termsOfAdhesion = await getTermOfAdhesion()
				setText(JSON.parse(termsOfAdhesion.Dados))
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	useEffect(initialize, [])

	function getText(text: string) {
		setText(text)
	}

	return (
		<Container className='animated-right'>
			<h1 className='title'>Termo de adesão</h1>
			{loading ? (
				<div className='loading-wrapper'>
					<Loading />
				</div>
			) : (
				<Editor returnText={getText} textInit={text} />
			)}

			{!loading && (
				<div className='btn'>
					<Button name='Salvar' disabled={!text} onClick={onSubmit} />
				</div>
			)}
		</Container>
	)
}
export default CreateTermAdhesion
