import styled, { css } from "styled-components";
import { OmitStyledComponentsDefaultProps } from "./types";
import { mediaQuery } from "../../styles/functions";

export const LoadingContent = styled.section`
  display: flex;
  margin-top: 50;
  justify-content: center;
`;

export const TableWrapper = styled.div<
  OmitStyledComponentsDefaultProps<"tertiaryColor">
>`
  ${({ styles }) => css`
    font-size: 12;
    /* color: ${styles.secondaryColor}; */
    overflow-x: auto;
    padding-bottom: 48px;
    border-radius: ${styles.round ? "30px" : "4px"};

    .table {
      &.loading {
        opacity: 0.4;
      }

      border-collapse: collapse;
      &.separate {
        border-collapse: separate;
      }

      width: 99.9%;
      min-width: 1200px;
      thead {
        tr {
          th {
            padding: ${styles.padding};
            text-align: left;
            color: ${styles.primaryColor};
          }
        }
      }
      tbody {
        tr {
          background: ${styles.backgroundColor};

          &:nth-child(odd) {
            background: white;
          }

          td {
            padding: ${styles.padding};
            vertical-align: middle;
            color: ${styles.secondaryColor};
          }
        }
      }

      .ellipsis {
        max-width: 150px;

        ${mediaQuery("sm")(`
			width: 200px;
		`)}

        ${mediaQuery("xs")(`
			width: 50px;
		`)}
      }
    }

    .table-footer {
      margin-top: 24px;

      .result-span {
        display: inline-block;
        margin-top: 12px;
        margin-bottom: 18px;
        /* color: ${styles.primaryColor}; */
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
          border: none;
          background: none;
          cursor: pointer;
          padding: 16px;

          color: ${styles.primaryColor};


          &[disabled] {
            color: var(--color-cinza);
          }
        }

        .page-numbers {
          display: flex;
          align-items: center;
          .page-number {
            padding: 8px 10px;
            border-radius: 4px;
            color: ${styles.primaryColor};

            cursor: pointer;
            &.active {
              background: ${styles.primaryColor};
              color: white;
            }
          }
        }
      }
    }
  `}
`;
