import { createElement } from 'react'
import { RouteEnumsAdmin, RouteEnumsUnauthentication } from './enums'
import Page from 'shared/components/Page'

//pages
import CreateTermAdhesion from '../CreateTermAdhesion'
import Dashboard from '../Dashboard'
import Participant from '../Participant'
import Operator from '../Operator'
import Partner from '../Partner'
import Cashback from '../Cashback'
import Voucher from '../Voucher'
import CreateAccountPartner from '../CreateAccountPartner'
import Cupons from '../Cupons'
import Login from 'modules/Login'
import CreateAccountParticipant from 'modules/CreateAccountParticipant'
import RecoverPassword from 'modules/RecoverPassword'
import ChangePassword from 'modules/ChangePassword'
import CreateAccountOperator from 'modules/CreateAccountOperator'
import RedesCredenciadas from 'modules/RedesCredenciadas'
import MyProfile from 'modules/MyProfile'
import ChangePasswordLogged from 'modules/ChangePasswordLogged'
import CupomParticipantsDetails from 'modules/CupomParticipantsDetails'
import DashboardOperator from 'modules/DashboardOperator'

//icons
import Edit from 'assets/images/edit-go.svg'
import Parceiro from 'assets/images/store-go.svg'
import Operador from 'assets/images/user-go.svg'
import Cash from 'assets/images/cash-go.svg'
import VoucherIcon from 'assets/images/voucher-go.svg'
import User from 'assets/images/client-go.svg'
import Dash from 'assets/images/monitoring.svg'
import Location from 'assets/images/location.svg'
import Payments from 'assets/images/payments.svg'

import { NivelDeAcessoEnum } from 'shared/interfaces/user'
import CreateCupon from 'modules/CreateCupon'
import ListCupons from 'modules/ListCupons'
import Releases from 'modules/Releases'
import MyCupons from 'modules/MyCupons'

export interface IRouteDataV2 {
	accessTypes: NivelDeAcessoEnum[]
	displayOnNavBar: boolean
	name: string
	icon?: string
	key: RouteEnumsUnauthentication | RouteEnumsAdmin
	path: RouteEnumsUnauthentication | RouteEnumsAdmin
	element: JSX.Element
}

export const adminRoutesData: IRouteDataV2[] = [
	{
		accessTypes: [NivelDeAcessoEnum.Administrador],
		displayOnNavBar: true,
		name: 'Relatório',
		key: RouteEnumsAdmin.DASHBOARD,
		path: RouteEnumsAdmin.DASHBOARD,
		icon: Dash,
		element: createElement(Page, {
			children: createElement(Dashboard)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Operador],
		displayOnNavBar: true,
		name: 'Relatório',
		key: RouteEnumsAdmin.DASHBOARD_PARTNER,
		path: RouteEnumsAdmin.DASHBOARD_PARTNER,
		icon: Dash,
		element: createElement(Page, {
			children: createElement(DashboardOperator)
		})
	},

	{
		accessTypes: [NivelDeAcessoEnum.Administrador],
		displayOnNavBar: true,
		name: 'Parceiros',
		key: RouteEnumsAdmin.PARTNER,
		path: RouteEnumsAdmin.PARTNER,
		icon: Parceiro,
		element: createElement(Page, {
			children: createElement(Partner)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador, NivelDeAcessoEnum.Operador],
		displayOnNavBar: true,
		name: 'Operadores',
		key: RouteEnumsAdmin.OPERATOR,
		path: RouteEnumsAdmin.OPERATOR,
		icon: Operador,
		element: createElement(Page, {
			children: createElement(Operator)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador],
		displayOnNavBar: true,
		name: 'Participantes',
		key: RouteEnumsAdmin.PARTICIPANT,
		path: RouteEnumsAdmin.PARTICIPANT,
		icon: User,
		element: createElement(Page, {
			children: createElement(Participant)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador, NivelDeAcessoEnum.Operador],
		displayOnNavBar: true,
		name: 'Lançamentos',
		key: RouteEnumsAdmin.RELEASES,
		path: RouteEnumsAdmin.RELEASES,
		icon: Payments,
		element: createElement(Page, {
			children: createElement(Releases)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador, NivelDeAcessoEnum.Operador],
		displayOnNavBar: true,
		name: 'Cashback',
		key: RouteEnumsAdmin.CASHBACK,
		path: RouteEnumsAdmin.CASHBACK,
		icon: Cash,
		element: createElement(Page, {
			children: createElement(Cashback)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador, NivelDeAcessoEnum.Operador],
		displayOnNavBar: true,
		name: 'Cupom',
		key: RouteEnumsAdmin.VOUCHER,
		path: RouteEnumsAdmin.VOUCHER,
		icon: VoucherIcon,
		element: createElement(Page, {
			children: createElement(Cupons)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Operador, NivelDeAcessoEnum.Administrador],
		displayOnNavBar: false,
		name: 'Cupom',
		key: RouteEnumsAdmin.CREATE_CUPOM,
		path: RouteEnumsAdmin.CREATE_CUPOM,
		element: createElement(Page, {
			children: createElement(CreateCupon)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Participante],
		displayOnNavBar: true,
		name: 'Voucher',
		key: RouteEnumsAdmin.VOUCHER,
		path: RouteEnumsAdmin.VOUCHER,
		icon: Cash,
		element: createElement(Page, {
			children: createElement(Voucher)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador],
		displayOnNavBar: true,
		name: 'Termo de Adesão',
		key: RouteEnumsAdmin.CREATE_THERMS_ADHESION,
		path: RouteEnumsAdmin.CREATE_THERMS_ADHESION,
		icon: Edit,
		element: createElement(Page, {
			children: createElement(CreateTermAdhesion)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador],
		displayOnNavBar: false,
		name: 'Editar Participante',
		key: RouteEnumsAdmin.EDIT_PARTICIPANT,
		path: RouteEnumsAdmin.EDIT_PARTICIPANT,
		icon: Edit,
		element: createElement(Page, {
			children: createElement(CreateAccountParticipant)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador, NivelDeAcessoEnum.Operador],
		displayOnNavBar: false,
		name: 'Editar Operador',
		key: RouteEnumsAdmin.EDIT_OPERATOR,
		path: RouteEnumsAdmin.EDIT_OPERATOR,
		icon: Edit,
		element: createElement(Page, {
			children: createElement(CreateAccountOperator)
		})
	},
	{
		accessTypes: [
			// NivelDeAcessoEnum.Administrador,
			NivelDeAcessoEnum.Operador,
			NivelDeAcessoEnum.Participante
		],
		displayOnNavBar: false,
		name: 'Meu perfil',
		key: RouteEnumsAdmin.MY_PROFILE,
		path: RouteEnumsAdmin.MY_PROFILE,
		icon: Edit,
		element: createElement(Page, {
			children: createElement(MyProfile)
		})
	},
	{
		accessTypes: [
			NivelDeAcessoEnum.Administrador,
			NivelDeAcessoEnum.Operador,
			NivelDeAcessoEnum.Participante
		],
		displayOnNavBar: false,
		name: 'Alteração de senha',
		key: RouteEnumsAdmin.CHANGE_MY_PASSWORD,
		path: RouteEnumsAdmin.CHANGE_MY_PASSWORD,
		icon: Edit,
		element: createElement(Page, {
			children: createElement(ChangePasswordLogged)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador],
		displayOnNavBar: false,
		name: 'Criar/Editar Parceiro',
		key: RouteEnumsAdmin.CREATE_PARTNER,
		path: RouteEnumsAdmin.CREATE_PARTNER,
		icon: Edit,
		element: createElement(Page, {
			children: createElement(CreateAccountPartner)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador, NivelDeAcessoEnum.Operador],
		displayOnNavBar: false,
		name: 'Cupom detalhes',
		key: RouteEnumsAdmin.CUPOM_PARTICIPANTS_DETAILS,
		path: RouteEnumsAdmin.CUPOM_PARTICIPANTS_DETAILS,
		icon: Edit,
		element: createElement(Page, {
			children: createElement(CupomParticipantsDetails)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador, NivelDeAcessoEnum.Operador],
		displayOnNavBar: false,
		name: 'Create operator',
		key: RouteEnumsAdmin.CREATE_OPERATOR,
		path: RouteEnumsAdmin.CREATE_OPERATOR,
		element: createElement(Page, {
			children: createElement(CreateAccountOperator)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Administrador],
		displayOnNavBar: false,
		name: 'Create participant',
		key: RouteEnumsAdmin.CREATE_PARTICIPANT,
		path: RouteEnumsAdmin.CREATE_PARTICIPANT,
		element: createElement(Page, {
			children: createElement(CreateAccountParticipant)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Participante],
		displayOnNavBar: true,
		icon: Location,
		name: 'Redes Credenciadas',
		key: RouteEnumsAdmin.REDES_CREDENCIADAS,
		path: RouteEnumsAdmin.REDES_CREDENCIADAS,
		element: createElement(Page, {
			children: createElement(RedesCredenciadas)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Participante],
		displayOnNavBar: true,
		name: 'Meus Cupons',
		key: RouteEnumsAdmin.MY_CUPOM,
		path: RouteEnumsAdmin.MY_CUPOM,
		icon: Payments,
		element: createElement(Page, {
			children: createElement(MyCupons)
		})
	},
	{
		accessTypes: [NivelDeAcessoEnum.Participante],
		displayOnNavBar: true,
		icon: VoucherIcon,
		name: 'Cupons Disponíveis',
		key: RouteEnumsAdmin.LIST_CUPONS,
		path: RouteEnumsAdmin.LIST_CUPONS,
		element: createElement(Page, {
			children: createElement(ListCupons)
		})
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: 'Login',
		key: RouteEnumsUnauthentication.LOGIN,
		path: RouteEnumsUnauthentication.LOGIN,
		element: createElement(Login)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: 'Create account',
		key: RouteEnumsUnauthentication.CREATE_ACCOUNT_PARTICIPANT,
		path: RouteEnumsUnauthentication.CREATE_ACCOUNT_PARTICIPANT,
		element: createElement(CreateAccountParticipant)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: 'Recover Password',
		key: RouteEnumsUnauthentication.RECOVER_PASSWORD,
		path: RouteEnumsUnauthentication.RECOVER_PASSWORD,
		element: createElement(RecoverPassword)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: 'change password',
		key: RouteEnumsUnauthentication.CHANGE_PASSWORD,
		path: RouteEnumsUnauthentication.CHANGE_PASSWORD,
		element: createElement(ChangePassword)
	}
]
