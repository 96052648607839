import styled from 'styled-components'

export const Container = styled.div`
	height: 100vh;

	.table {
		margin-top: 40px;
	}

	.header-filter {
		displau: block;
	}
	.edit,
	.remove,
	.block {
		width: 35px;
		height: 35px;
	}

	.isActive {
		background: #c9e8aa;
		color: #5ea715;
		border: 1px solid #5ea715;
		text-align: center;
		font-size: 10px;
	}

	.isInactive {
		background: #bcb8b8;
		color: var(--color-primary);
		border: 1px solid var(--color-primary);
		text-align: center;
		font-size: 10px;
	}
	.status {
		border-radius: 30px;
		width: 50px;
		padding: 8px;
	}
`

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const TextTitle = styled.p`
	font-size: 14px;
	color: var(--color-primary);
	text-align: center;
	margin-bottom: 40px;
`

export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
