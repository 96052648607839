import Image from '../Image'
import DiscountBadge from '../DiscountBadge'
import * as S from './styles'

export interface IProps {
	img: string
	partner: string
	value: string
	overdue?: string
	percentage?: string
	rescue: boolean
	onClickModal?: () => void
}

function CashBackCard(props: IProps) {
	const {
		img,
		partner,
		value,
		overdue,
		percentage,
		rescue,
		onClickModal: onPressModal
	} = props
	return (
		<S.CashBackCardWrapper onClick={onPressModal}>
			<S.InfoPartner>
				<figure>
					<Image src={img} alt={partner} height={60} />
				</figure>
				<span className='voucher-title'>
					<p className='price'>+{value}</p>
					<p className='partner'>{partner}</p>
				</span>
			</S.InfoPartner>
			{overdue && (
				<span className='date-overdue'>
					<p className='title-overdue'>Disponível até:</p>
					<p className='date'>{overdue}</p>
				</span>
			)}

			{percentage ? (
				<div className='discount-badge'>
					<DiscountBadge
						background='#fdb7d0'
						color='var(--color-rosa)'
						percetage={percentage}
					/>
				</div>
			) : null}

			{rescue && <button className='rescue'>Resgatar</button>}
		</S.CashBackCardWrapper>
	)
}

export default CashBackCard
