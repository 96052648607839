import axios, { AxiosInstance } from 'axios'
import { store } from '../store'
import { logout } from './logout'
import { NivelDeAcessoEnum } from 'shared/interfaces/user'

export const URI = process.env.REACT_APP_API_URI

const axiosInstance: AxiosInstance = axios.create({
	baseURL: `${URI}`,
	timeout: 300000
})

axiosInstance.interceptors.request.use(
	(request) => {
		if (process.env.NODE_ENV === 'development') {
			/**
			 * The react-query calling a request does't necessary mean that
			 * will make that number of request or even is going to make a request
			 * look at google chrome network to really see when a request is really made.
			 */
			console.log('REQUEST', { url: request.url, data: request.data })
			// console.log('REQUEST', { request })
		}

		const accessToken = store.getState().token.accessToken
		const nivel = store.getState().user.NivelDeAcesso
		const partner = store.getState().partner

		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`
		}

		if (nivel === NivelDeAcessoEnum.Operador && partner) {
			request.headers.Secret = partner.Cnpj
		}

		return request
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('REQUEST', { err })
		}
		throw err
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		// console.log('RESPONSE', {
		// 	data: response.data,
		// 	status: response.status
		// })

		return response
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('RESPONSE', { err })
		}

		if (err.response.status === 401) {
			logout()
		}

		throw err
	}
)

export default axiosInstance
