import { pt } from 'date-fns/locale'
import { format, isFuture, isValid, parse } from 'date-fns'

export function formatCellphone(value: string) {
	const cellphone = value.replace(/\D/g, '')

	// With +55 and 19
	if (cellphone.length === 14) {
		return cellphone.replace(/(\d{3})(\d{2})(\d{5})(\d{4})/g, '$1 ($2) $3-$4')
	}

	// With 55 and 19
	if (cellphone.length === 13) {
		return cellphone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '+$1 ($2) $3-$4')
	}

	// With 19
	if (cellphone.length === 11) {
		return cellphone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
	}

	// With Telephone 19
	if (cellphone.length === 10) {
		return cellphone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3')
	}

	// Only Number
	if (cellphone.length === 9) {
		return cellphone.replace(/(\d{5})(\d{4})/g, '$1-$2')
	}

	return cellphone
}

export function formatPostalCode(value: string) {
	const code = value.replace(/\D/g, '')

	if (code.length === 8) {
		return code.replace(/(\d{5})(\d{3})/g, '$1-$2')
	}

	return code
}

export function formatRG(value: string) {
	const document = value.replace(/\D/g, '')

	if (document.length === 9) {
		return document.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4')
	}

	return document
}

export function formatCnpjCpf(value: string) {

	const formatoLgpd = value.indexOf('*') !== -1;
	if (formatoLgpd)
		return value

	const document = value.replace(/\D/g, '')


	if (document.length === 11) {
		return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
	}

	if (document.length === 14) {
		return document.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
			'$1.$2.$3/$4-$5'
		)
	}

	return document
}

export const FORMAT_BIRTH_BIRTH_DATE = 'yyyy-MM-dd'
export const FORMAT_BIRTH_BIRTH_DATE_BR = 'dd/MM/yyyy'

export function formatBirthDate(date: string) {
	const dateParsed = parse(date, FORMAT_BIRTH_BIRTH_DATE_BR, new Date())
	const formatted = format(dateParsed, FORMAT_BIRTH_BIRTH_DATE, {
		locale: pt
	})
	return formatted
}

export function maskBirthDate(date: string) {
	const formatted = format(new Date(date), FORMAT_BIRTH_BIRTH_DATE_BR, {
		locale: pt
	})
	return formatted
}

export function formatDateToUS(date: Date, formatText: string) {
	const formatted = format(date, formatText, {
		locale: pt
	})
	return formatted
}

export function maskDate(value: string) {
	let v = value.replace(/\D/g, '').slice(0, 10)
	if (v.length >= 5) {
		return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
	} else if (v.length >= 3) {
		return `${v.slice(0, 2)}/${v.slice(2)}`
	}
	return v
}

export function validatorDate(date: string) {
	const year = date.split('/')[2]
	if (!year || year.length < 4) return false
	const parsedDate = parse(date, 'P', new Date(), { locale: pt })

	const isValidDate = isValid(parsedDate)
	const dateIsFuture = isFuture(parsedDate)

	if (dateIsFuture) return false

	return isValidDate
}

export const MONEY_FORMATTER = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL',
	minimumFractionDigits: 2
})

export const formatNumberToBRL = (value: number) => {
	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: 2,
		currency: 'BRL'
	}).format(value)
}

export const decimalFormater = (value: any) => {
	const number = Math.floor(value * 100) / 100
	return Number(number).toLocaleString('pt-BR', {
		minimumFractionDigits: 2,
	})
}

export function toMoney(value: number): string {
	if (isNaN(value)) return ''

	// According to MDN and Node documentation itself, Node.js support only en-US locale
	// https://stackoverflow.com/questions/60011347/intl-numberformat-doesnt-convert-to-pt-br-locale
	return MONEY_FORMATTER.format(value)
}

// export function toNumber(value: string): number {
// 	return Number(value.replace(MONEY_REGEX, '')) / 100
// }
export function toNumber(value: string): boolean {
	const split = value.replace(MONEY_REGEX, '').trim()
	return Boolean(Number(split) / 100)
}

export const MONEY_REGEX = /[R$,.]+/g

export function maskMoney(value: string) {
	value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

	if (!value) return ''
	const options = { minimumFractionDigits: 2 }
	const result = new Intl.NumberFormat('pt-BR', options).format(
		parseFloat(value) / 100
	)

	return 'R$ ' + result
}

export function returnOnlyNumber(str: string) {
	if (isNaN(Number(str))) return ''

	return str
}
export function maskDecimal(value: string) {
	value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

	if (!value) return ''
	const options = { minimumFractionDigits: 2 }
	const result = new Intl.NumberFormat('pt-BR', options).format(
		parseFloat(value) / 100
	)

	return result
}

export function maskPercentage(value: string) {
	value = value.replace('%', '').replace(/\D/g, '')
	if (!value || Number(value) === 0) return ''
	return value
}

export function toPercentageToNumber(value: string): boolean {
	const split = value
	const number = Number(split)
	const valueMinAndMax = number >= 1 && number <= 100 ? true : false
	return valueMinAndMax
}

export function currencyStringToNumber(currencyString: string) {
	const withoutCurrencySymbol = currencyString.replace('R$', '').trim()
	const withDecimalComma = withoutCurrencySymbol.replace('.', '')
	const withDecimalPoint = withDecimalComma.replace(',', '.')
	const numberValue = parseFloat(withDecimalPoint)
	return numberValue
}
