import styled from 'styled-components'
import { mediaQuery } from 'shared/styles/functions'

export const Container = styled.section`
	h1 {
		font-family: var(--font-oswald);
		color: var(--color-boxshadow-cinzaescuro);
		font-weight: 300;
	}
	.w50 {
		width: 50%;
	}

	.w60 {
		width: 60%;
	}

	.w40 {
		width: 40%;
	}
	.form-data {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;
	}

	.file {
		margin: 10px 0;
		padding: 0rem 1rem;
		height: 185px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* font-size: 3rem; */
		background: #fc5c943b;
		/* box-shadow: 0 0 0 3px var(--color-rosa); */
		border: 1px solid var(--color-rosa);
		color: var(--color-rosa);
		border-radius: 0.2rem;
		cursor: pointer;

		img {
			height: 20px;
			width: 20px;
		}
	}

	.info-cash {
		margin: 32px 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;
		/* align-items: center; */
	}

	.info-operator {
		margin: 32px 0;

		h1 {
			margin-bottom: 12px;
		}

		.operator-wrapper {
			background: white;
			border-radius: 4px;
			padding: 16px;
		}
		.flex {
			align-items: center;
		}
	}

	form {
		margin: 59px 0;
	}

	.info-logo {
		width: 400px;
		height: 200px;
		margin: 20px auto;
	}

	.btn-submit {
		width: 200px;
		margin-top: 40px;
		margin: auto;
	}

	.img-upload {
		position: relative;
		align-self: center;
		border: 1px solid var(--color-rosa);
		background: var(--color-branco);
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		height: calc(100% - 32px);

		.logo {
			border-radius: 4px;
			height: 100%;
			object-fit: cover;
		}

		button {
			width: 30px;
			height: 30px;
			background: 0;
			border: 0;
			position: absolute;
			bottom: 24px;
			right: 24px;
		}
	}

	.terms-btn {
		display: flex;
		justify-content: center;
		gap: 1rem;
	}

	${mediaQuery('sm')(`
      .form-data {
        display: block;
      }
	  .info-cash{
		display: block;

		.flex{
			display: block;
		}

		.w50{
			width: 100%;
		}
	  }
      .info-logo{
        width: 100%;
      }

      h1{
        margin: 12px 0;
      }

	  .info-operator{
		.flex{
			display: block;
		}
		.w40, .w60{
			width: 100%;
		}
		.btn-clean{
			margin: auto;
		}
	  }
  `)}
`
