import Logo from "assets/images/logo.png";
import { HeaderWrapper } from "./styles";

export interface IViewProps {
  isOpen: boolean;
  handleOpen: () => void;
}
export default function HeaderAdmin(props: IViewProps) {
  const { handleOpen, isOpen } = props;
  return (
    <HeaderWrapper>
      <div id="nav-icon" className={isOpen ? "open" : ""} onClick={handleOpen}>
        <span />
        <span />
        <span />
      </div>
      <button className="img-logo">
        <img src={Logo} alt="Logo" />
      </button>
      <div></div>
    </HeaderWrapper>
  );
}
