import styled from 'styled-components'

export const Container = styled.div`
	.table {
		margin-top: 40px;

		.image-voucher {
			width: 80px;
			height: 80px;
			img {
				height: 100%;
				object-fit: cover;
			}
		}

		.edit,
		.remove,
		.confirme,
		.block {
			width: 35px;
			height: 35px;
		}
	}
	.isActive {
		background: #c9e8aa;
		color: #5ea715;
		border: 1px solid #5ea715;
		text-align: center;
		font-size: 10px;
	}

	.isInactive {
		background: #bcb8b8;
		color: var(--color-primary);
		border: 1px solid var(--color-primary);
		text-align: center;
		font-size: 10px;
	}
	.status {
		border-radius: 30px;
		width: 50px;
		padding: 8px;
	}
`
