export enum RouteEnumsUnauthentication {
	HOME = '/',
	LOGIN = '/login',
	CREATE_ACCOUNT_PARTICIPANT = '/create-participant',
	RECOVER_PASSWORD = '/recover-password',
	CHANGE_PASSWORD = '/change-password/:requestId'
}

export enum RouteEnumsAdmin {
	CREATE_THERMS_ADHESION = '/terms-adhesion',
	DASHBOARD = '/dashboard',
	PARTICIPANT = '/participant',
	EDIT_PARTICIPANT = '/edit-participant',
	CREATE_PARTICIPANT = '/create-participant',
	OPERATOR = '/operator',
	EDIT_OPERATOR = '/edit-operator',
	CREATE_PARTNER = '/create-partner',
	PARTNER = '/partner',
	VOUCHER = '/voucher',
	CASHBACK = '/cashback',
	CREATE_OPERATOR = '/create-operator',
	CUPONS = '/cupons',
	REDES_CREDENCIADAS = '/redes-credenciadas',
	MY_PROFILE = '/my-profile',
	CHANGE_MY_PASSWORD = '/change-my-password',
	CREATE_CUPOM = '/create-cupom',
	LIST_CUPONS = '/list-cupons',
	RELEASES = '/releases',
	MY_CUPOM = '/my-cupons',
	CUPOM_PARTICIPANTS_DETAILS = '/cupom-participants',
	DASHBOARD_PARTNER = '/dashboard-partner'
}
