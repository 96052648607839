import { useNavigate } from "react-router-dom";
import Logo from "assets/images/logo.png";
import { HeaderWrapper } from "./styles";
import { RouteEnumsUnauthentication } from "../../../modules/routes/enums";

export interface IProps {
  title?: string;
}
export default function HeaderNotLogged(props: IProps) {
  const { title } = props;
  const navigate = useNavigate();
  function goToLogin() {
    // navigate(RouteEnumsUnauthentication.LOGIN);
  }
  return (
    <HeaderWrapper>
      <h2>{title ? title : "Primeiro Acesso"}</h2>
      <button className="img-logo" onClick={goToLogin}>
        <img src={Logo} alt="Logo" />
      </button>
    </HeaderWrapper>
  );
}
