import styled from 'styled-components'

export const Container = styled.div`
	.table {
		margin-top: 40px;

		.edit,
		.remove,
		.block {
			width: 35px;
			height: 35px;
		}
	}
`
