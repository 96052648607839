import React from 'react'
import * as S from './styles'
import { formatDate } from 'shared/utils/date'
import placeholder from 'assets/images/default-placeholder.png'

interface IProps {
	image: string
	title: string
	qtd: number
	value: string
	description: string
	name: string
	percent?: number
	date: Date
	openModal: () => void
	height?: number
}

function Ticket(props: IProps) {
	const {
		description,
		image,
		name,
		qtd,
		title,
		value,
		percent,
		date,
		openModal,
		height
	} = props
	return (
		<S.TicketWrapper onClick={openModal} style={{ height }}>
			<S.TicketContainer>
				<div className='image'>
					<img
						src={image}
						alt={title}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null
							currentTarget.src = placeholder
						}}
					/>
				</div>

				<div className='ticket-info'>
					<p className='date'>
						<h1 className='title'>{title}</h1>
					</p>

					<span className='show-name'>
						<h2>{name}</h2>
						<p className='description'>{description}</p>
					</span>

					<span>
						<p className='qtd-tickets'>
							{/*Restante: {qtd} */}
						</p>
					</span>

					<h1 className='price'>{value}</h1>
				</div>

				<div className='right'>
					{percent ? (
						<span className='discount'>
							<p>{percent}% OFF</p>
						</span>
					) : null}
					<div className='info-date'>
						<h1>{formatDate(date, 'd')}</h1>
						<h2>{formatDate(date, 'MMM')}</h2>
					</div>
				</div>
			</S.TicketContainer>
		</S.TicketWrapper>
	)
}

export default Ticket
