import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ImageBackground, LoginWrapper } from './styles'
import InputPrimary from 'shared/components/InputPrimary'
import ButtonPrimary from 'shared/components/ButtonPrimary'
import useForm from 'shared/hooks/useForm'
import ArrowRight from 'assets/images/arrow-right.svg'
import Logo from 'assets/images/logo.png'
import { RouteEnumsUnauthentication } from '../../modules/routes/enums'
import InputPassword from 'shared/components/InputPassword'
import { toasterError } from 'shared/utils/toaster'
import {
	IGetInfoCompany,
	getAllActivity,
	signIn,
	updateToken
} from 'shared/services/auth.service'
import { cleanDocumentNumber } from 'shared/utils/validators'

import { Creators as TokenActions } from 'shared/store/ducks/token'
import { Creators as UserActions } from 'shared/store/ducks/user'
import { Creators as PartnerActions } from 'shared/store/ducks/partner'
import { Creators as ActivityActions } from 'shared/store/ducks/activity'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { IUser, NivelDeAcessoEnum } from 'shared/interfaces/user'
import { renderRoute } from 'modules/routes/data'
import SelectPrimary from 'shared/components/SelectPrimary'
import { ISelectOption } from 'shared/interfaces/options'
import { logout } from 'shared/utils/logout'

export default function Login() {
	const { user } = useTypedSelector(['user'])
	const cpf = useForm('cpf')
	const password = useForm('password')
	const [loading, setLoading] = useState(false)
	const [showStepProfile, setStepProfile] = useState(false)
	const [profileList, setProfileList] = useState<ISelectOption[]>([])
	const [partnerList, setPartnerList] = useState<ISelectOption[]>([])
	const [partnerSelected, setPartnerSelected] = useState<ISelectOption | null>(
		null
	)
	const [profileSelected, setProfileSelected] = useState<ISelectOption | null>(
		null
	)
	const [oldUser, setUser] = useState<IUser | null>(null)
	const [company, setCompany] = useState<IGetInfoCompany | null>(null)
	const dispatch = useDispatch()

	const navigate = useNavigate()

	async function handleSubmit(e: any) {
		e.preventDefault()
		try {
			// toasterPromise()
			if (!cpf.fieldIsValid || !password.fieldIsValid) {
				return
			}

			setLoading(true)
			const response = await signIn({
				Cpf: cleanDocumentNumber(cpf.value),
				Senha: password.value
			})
			dispatch(TokenActions.setToken({ accessToken: response.Dados.Token }))

			if (response.Dados.NivelDeAcesso === NivelDeAcessoEnum.Operador) {
				setStepProfile(true)
				// setOldToken(response.Dados.Token)

				setCompany({
					NiveDeAcesso: NivelDeAcessoEnum.Operador,
					Parceiros: response.Dados?.ParceirosQueEuTrabalho || []
				})
				const mapProfile = [
					NivelDeAcessoEnum.Operador,
					NivelDeAcessoEnum.Participante
				].map((access) => {
					return {
						label: access,
						value: access
					}
				})
				setProfileList(mapProfile)
				setUser(response.Dados.Usuario)

				// navigate(renderRoute(user.NivelDeAcesso))
				return
			}

			dispatch(TokenActions.setToken({ accessToken: response.Dados.Token }))
			updateUser(response.Dados.Usuario)
			if (response.Dados.NivelDeAcesso === NivelDeAcessoEnum.Administrador) {
				const activityResponse = await getAllActivity()

				dispatch(
					ActivityActions.setActivity(activityResponse.RamosDeatividades)
				)

				return
			}
		} catch (error: any) {
			toasterError(
				error?.response?.data?.Mensagem
					? error?.response.data.Mensagem
					: 'Erro ao realizar login'
			)
		} finally {
			setLoading(false)
		}
	}

	function updateUser(newInfoUser: IUser) {
		dispatch(UserActions.setUser(newInfoUser))
	}

	async function handleProfileAndPartnerSelected() {
		try {
			setLoading(true)

			if (profileSelected?.value === NivelDeAcessoEnum.Participante) {
				const newToken = await updateToken()
				dispatch(
					TokenActions.setToken({
						accessToken: newToken.Dados.Token
					})
				)
			}

			if (
				profileSelected?.value === NivelDeAcessoEnum.Operador &&
				company?.Parceiros
			) {
				const findCompany =
					company?.Parceiros?.length > 1
						? company.Parceiros.find(
								(partner) => partner.Id === partnerSelected?.value
						  )
						: company.Parceiros[0]

				if (!findCompany) return
				dispatch(PartnerActions.setPartner(findCompany))
			}

			oldUser &&
				dispatch(
					UserActions.setUser({
						...oldUser,
						NivelDeAcesso: profileSelected?.value as NivelDeAcessoEnum
					})
				)
			navigate(renderRoute(user.NivelDeAcesso))
		} catch (error: any) {
			toasterError(
				error?.response?.data?.Mensagem
					? error?.response.data.Mensagem
					: 'Erro ao realizar login'
			)
			setStepProfile(false)
			logout()
		} finally {
			setLoading(false)
		}
	}

	function handlePartner(value: ISelectOption) {
		setPartnerSelected(value)
	}

	function handleProfile(value: ISelectOption) {
		setProfileSelected(value)
		setPartnerList([])
	}

	useEffect(buildingOptionPartner, [profileSelected?.value])

	function buildingOptionPartner() {
		if (profileSelected?.value === NivelDeAcessoEnum.Participante) {
			setPartnerList([])
			return
		}

		if (!company?.Parceiros.length) return

		const mapPartner: ISelectOption[] = company.Parceiros.map((partner) => {
			return {
				value: partner?.Id || partner.NomeFantasia,
				label: partner.NomeFantasia
			}
		})

		mapPartner.length && setPartnerList(mapPartner)
	}

	function goToCreateAccount() {
		navigate(RouteEnumsUnauthentication.CREATE_ACCOUNT_PARTICIPANT)
	}

	function goToRecoverPassword() {
		navigate(RouteEnumsUnauthentication.RECOVER_PASSWORD, {
			state: { cpf: cleanDocumentNumber(cpf.value) }
		})
	}

	function toggleStep() {
		setStepProfile((show) => !show)
	}

	function renderSelectProfile() {
		return (
			<div className={'animated-right'}>
				<SelectPrimary
					label='Perfil'
					name='profile'
					options={profileList}
					placeholder='Perfil'
					value={profileSelected}
					onChange={handleProfile}
				/>

				{partnerList.length > 0 ? (
					<SelectPrimary
						label='Loja'
						name='partner'
						options={partnerList}
						placeholder='Loja'
						value={partnerSelected}
						onChange={handlePartner}
					/>
				) : null}

				<div className='btn-submit'>
					<ButtonPrimary
						loading={loading}
						name='Confirmar'
						type='button'
						disabled={false}
						onClick={handleProfileAndPartnerSelected}
					/>
				</div>
			</div>
		)
	}

	return (
		<>
			<LoginWrapper>
				<ImageBackground />

				<div className='login'>
					<div className='logo'>
						<img src={Logo} alt='Logo' />
					</div>
					<section>
						<button className='first-acess' onClick={goToCreateAccount}>
							<h1>Primeiro acesso?</h1>
							<div>
								<img
									src={ArrowRight}
									alt='primeiro acesso icone seta'
									width={20}
									height={20}
								/>
							</div>
						</button>
						<form className={'form'} onSubmit={handleSubmit}>
							{!showStepProfile ? (
								<div>
									<InputPrimary
										{...cpf}
										inputMode='numeric'
										name='cpf'
										type='text'
										id='login-cpf'
										label='CPF'
									/>
									<InputPassword
										{...password}
										type='password'
										id='login-password'
										name='password'
										label='Senha'
									/>

									<div className='btn-submit'>
										<ButtonPrimary
											loading={loading}
											name='ENTRAR'
											type='submit'
											disabled={!cpf.fieldIsValid || !password.fieldIsValid}
											onSubmit={(e) => handleSubmit(e)}
										/>
									</div>
								</div>
							) : (
								renderSelectProfile()
							)}
						</form>

						<p className='recover-password'>
							Esqueceu sua senha?
							<span onClick={goToRecoverPassword}> Clique aqui</span>
						</p>
					</section>
				</div>
			</LoginWrapper>
		</>
	)
}
