export const IS_VALID_PASSWORD =
	/^(?=.\d)(?=.[a-z])(?=.[A-Z])[0-9a-zA-Z$&@#!]{8,}$/

export const ALL_SAME_NUMBER = /^(\d)\1+$/

export const SPECIAL_CHAR_AND_WHITESPACE = /[^\w\s]/gi

export const IS_VALID_EMAIL =
	/^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const CPF_REGEX = /(\d{3})(\d{3})(\d{3})(\d{2})/g

export const NON_DIGIT = /\D/g

export const MONEY_MASK = /[R$,.]+/g

export const MASK_CPF = /(\d{3})(\d{3})(\d{3})(\d{2})/g

export const MASK_CNPJ = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g

export const CELLPHONE_REGEX = /\(?\b([0-9]{2,3}|0((x|[0-9]){2,3}[0-9]{2}))\)?\s*[0-9]{4,5}[- ]*[0-9]{4}\b/g