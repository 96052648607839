import { format } from 'date-fns'
import { pt } from 'date-fns/locale'

import { FORMAT_BIRTH_BIRTH_DATE_BR } from './format'

export function formatDate(date: Date, mask?: string) {
	return format(date, mask ? mask : FORMAT_BIRTH_BIRTH_DATE_BR, {
		locale: pt
	})
}
