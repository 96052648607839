import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// import { Container } from './styles';

export interface IProps {
	returnText: (text: string) => void
	textInit: string
	readOnly?: boolean
}

function Editor(props: IProps) {
	const { returnText, textInit, readOnly } = props
	const [text, setText] = useState<string>(textInit)

	function returnTextEdit() {
		returnText(text)
	}

	React.useEffect(returnTextEdit, [text, textInit])

	return (
		<ReactQuill
			value={text}
			defaultValue={textInit}
			onChange={setText}
			style={{ background: 'white', color: '#333' }}
			readOnly={readOnly}
			modules={{
				toolbar: readOnly
					? false
					: [
							['bold', 'italic', 'underline', 'strike'], // toggled buttons
							[{ header: 1 }, { header: 2 }], // custom button values
							[{ list: 'ordered' }, { list: 'bullet' }],
							[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
							[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
							[{ header: [1, 2, 3, 4, 5, 6, false] }]

							// ["blockquote", "code-block"],
							// [{ direction: "rtl" }], // text direction
							// [{ script: "sub" }, { script: "super" }], // superscript/subscript
							// [{ color: [] }, { background: [] }], // dropdown with defaults from theme
							// [{ font: [] }],
							// [{ align: [] }],
							// ["clean"],
					  ]
			}}
		/>
	)
}

export default Editor
