import styled from 'styled-components'
import { mediaQuery } from 'shared/styles/functions'

export const Container = styled.section`
	h1 {
		font-family: var(--font-oswald);
		color: var(--color-boxshadow-cinzaescuro);
		font-weight: 300;
	}
	form {
		width: 50%;
		margin: auto;
	}
	.w50 {
		width: 50%;
	}

	.address {
		margin-top: 12px;
	}

	.w60 {
		width: 60%;
	}

	.w40 {
		width: 40%;
	}

	.info-cash {
		margin: 32px 0;
	}

	.info-operator {
		margin: 32px 0;
	}

	.btn-submit {
		width: 200px;
		margin: 40px auto;
	}

	.info-msg {
		font-size: 10px;
		color: var(--color-cinza);
		font-family: var(--font-roboto);
	}

	.rosa {
		color: var(--color-rosa);
	}

	.password-wrapper {
		width: 50%;
	}

	.checkbox {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	${mediaQuery('sm')(`
      form {
        width: 100%;
      }
      
      .password-wrapper{
        width: 100%;
      }
  `)}
`
