import React from 'react'
import Select, { ActionMeta } from 'react-select'

import { SelectBlock, customStyles } from './styles'
import { ISelectOption } from '../../interfaces/options'
import { Maybe, Nullable } from '../../interfaces/common'

interface Props {
	label: string
	name: string
	options: ISelectOption[]
	className?: string
	loading?: boolean
	onChange?: IOnChange
	value: ISelectOption | ISelectOption[] | null
	disabled?: boolean
	placeholder: string
	isClearable?: boolean
	isMulti?: boolean
	noOptionsMessage?: () => string | null
	id?: string
}

export type IOnChange = (
	value: Maybe<Nullable<ISelectOption>> | any,
	actionMeta: ActionMeta<any>
) => void

export function SelectPrimary({
	label,
	name,
	options,
	className,
	loading,
	onChange,
	value,
	disabled,
	placeholder,
	isClearable,
	isMulti = false,
	noOptionsMessage,
	id
}: Props) {
	return (
		<SelectBlock className={className}>
			<Select
				isMulti={isMulti}
				isLoading={loading}
				isClearable={isClearable}
				placeholder={String(placeholder)}
				options={options}
				styles={customStyles}
				onChange={onChange}
				value={value}
				// menuIsOpen={false}
				//    className={className}
				//    classNamePrefix={}
				noOptionsMessage={noOptionsMessage}
				isDisabled={disabled}
				id={id}
				instanceId={id}
			/>
			{value && <label>{label}</label>}
		</SelectBlock>
	)
}

export default SelectPrimary
