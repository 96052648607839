import { pick } from 'ramda'
import { useSelector } from 'react-redux'
import { IStore } from '../interfaces/store'

import { SelectorEqualityFn } from '../interfaces/common'

export function useTypedSelector<K extends keyof IStore>(
	props: Array<K>,
	equalityFn: SelectorEqualityFn<K> = undefined
) {
	// Type Properly
	const getState: any = pick(props)

	return useSelector<IStore, Pick<IStore, K>>(getState, equalityFn)
}
