import styled from 'styled-components'

export const Container = styled.div`
	.table {
		margin-top: 40px;

		.image-voucher {
			width: 80px;
			height: 80px;
			img {
				height: 100%;
				object-fit: cover;
			}
		}

		.edit,
		.remove,
		.confirme,
		.block {
			width: 35px;
			height: 35px;
		}
	}
	.Utilizado {
		background: rgb(255 240 179);
		color: rgb(167 124 21);
		border: 1px solid rgb(167 124 21);
		text-align: center;
		font-size: 10px;
	}

	.Ativo {
		background: #c9e8aa;
		color: #5ea715;
		border: 1px solid #5ea715;
		text-align: center;
		font-size: 10px;
	}

	.Expirado {
		background: #bcb8b8;
		color: var(--color-primary);
		border: 1px solid var(--color-primary);
		text-align: center;
		font-size: 10px;
	}

	.Cancelado {
		background: rgb(255 211 211);
		color: rgb(181 57 26);
		border: 1px solid rgb(181 57 26);
		text-align: center;
		font-size: 10px;
	}

	.BloqueadoPeloOperador {
		background: #efd2e9;
		color: #cd3cad;
		border: 1px solid #cd3cad;
		text-align: center;
		font-size: 10px;
	}

	.BloqueadoPeloAdministrador {
		background: rgb(118 69 153 / 43%);
		color: rgb(116 35 217);
		border: 1px solid rgb(116 35 217);
		text-align: center;
		font-size: 10px;
	}
	.status {
		border-radius: 30px;
		width: auto;
		padding: 8px;
	}
`
export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const TextTitle = styled.p`
	font-size: 14px;
	color: var(--color-primary);
	text-align: center;
	margin-bottom: 40px;
`

export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
