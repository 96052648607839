import React, { useState } from 'react'

import {
	Container,
	DeleteContainer,
	LoadingWrapper,
	TargetDescription,
	TargetName,
	TextDelete
} from './styles'
import Modal from '../Modal'
import { IModalDeleteProps } from './types'
import {
	FUNC_ACTIVE_ENUM,
	FUNC_BLOCK_ENUM,
	FUNC_ENUM,
	FUNC_REDUCE_ENUM,
	MODAL_TYPE,
	MODAL_TYPE_SUCESS,
	TITLE_ENUM
} from './data'
import Loading, { LoadingEnum } from '../Loading'
import Button from '../ButtonPrimary'
import { toasterError, toasterSuccess } from '../../utils/toaster'
import InputPrimary from '../InputPrimary'
import useForm from 'shared/hooks/useForm'

function ModalAction({
	onClose,
	type,
	handleDeleteUserEnd,
	target
}: IModalDeleteProps) {
	const [isLoading, setLoading] = useState(false)
	const motivo = useForm('campoTexto')

	const isActive = !!target
	const title = `${MODAL_TYPE[target?.type || 'BLOCK']} ${TITLE_ENUM[type]}`
	const message = 'Deseja ' + title

	async function confirme() {
		try {
			setLoading(true)

			if (!target) return
			const deleteTarget =
				target?.type === 'BLOCK'
					? FUNC_BLOCK_ENUM[type]
					: target?.type === 'ACTIVE'
					? FUNC_ACTIVE_ENUM[type]
					: target?.type === 'REDUCE'
					? FUNC_REDUCE_ENUM[type]
					: FUNC_ENUM[type]

			await deleteTarget(target.id, motivo.value)
			motivo.clearValue()
			toasterSuccess(
				`${target.name} ${
					target?.type && MODAL_TYPE_SUCESS[target?.type]
				} com sucesso!`
			)
			handleDeleteUserEnd()
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: `Ocorreu um erro ao ${MODAL_TYPE[
							target?.type || 'BLOCK'
					  ].toLowerCase()}`
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Container>
			<Modal
				title={title}
				isActive={isActive}
				onClose={onClose}
				// scrollRef="scroll-ref"
			>
				<DeleteContainer>
					<TextDelete>{message}</TextDelete>

					{target?.name && <TargetName>{target.name} ?</TargetName>}
					{target?.description && (
						<TargetDescription>{target.description}</TargetDescription>
					)}

					{target?.hasReason ? (
						<InputPrimary
							{...motivo}
							name='motivo'
							id={'motivo' + target?.name}
							label={`Motivo ${
								target?.type === 'BLOCK' ? 'do Bloqueio' : 'da Exclusão'
							}`}
						/>
					) : null}

					{isLoading ? (
						<LoadingWrapper>
							<Loading type={LoadingEnum.sentry} />
						</LoadingWrapper>
					) : (
						<Button
							onClick={confirme}
							name={'Confirmar'}
							disabled={false}
							id='confirm'
						/>
					)}
				</DeleteContainer>
			</Modal>
		</Container>
	)
}

export default ModalAction
