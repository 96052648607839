import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select, { ActionMeta, MultiValue } from 'react-select'
import 'react-tooltip/dist/react-tooltip.css'

import * as S from './styles'
import GoBack from 'shared/components/GoBack'
import FooterNotLogged from 'shared/components/FooterNotLogged'
import HeaderNotLogged from 'shared/components/HeaderNotLogged'
import InputPrimary from 'shared/components/InputPrimary'
import useForm from 'shared/hooks/useForm'
import Button from 'shared/components/ButtonPrimary'
import {
	toasterError,
	toasterPromise,
	toasterSuccess
} from 'shared/utils/toaster'
import { cleanDocumentNumber, cleanPhoneNumber } from 'shared/utils/validators'
import InputPassword from 'shared/components/InputPassword'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import SelectPrimary from 'shared/components/SelectPrimary'
import { ISelectOption } from 'shared/interfaces/options'
import {
	fetchCities,
	fetchStates,
	getAddress
} from 'shared/services/cep.service'
import { IEndereco } from 'shared/interfaces/endereco'
import { concatAddress, returnGeoCode } from 'shared/utils/geocode'
import {
	SelectBlock,
	customStyles
} from 'shared/components/SelectPrimary/styles'

import alertIcon from 'assets/images/alert-circle.svg'
import addLine from 'assets/images/add-line.svg'

import { IAssociateOperadorToPartner } from 'shared/interfaces/partner'
import { IOperator } from 'shared/interfaces/user'
import {
	formatBirthDate,
	formatCellphone,
	formatCnpjCpf,
	maskBirthDate
} from 'shared/utils/format'
import { getPartnerByCnpj } from 'shared/services/partner.service'
import {
	createOperator,
	getPartnerByOperatorId,
	updateOperator
} from 'shared/services/operador.service'

interface IRouteState {
	operator: IOperator | null
}

export default function CreateAccountOperator() {
	const route = useLocation()
	const state = route.state as IRouteState
	const operator = state?.operator

	const nome = useForm('campoTexto')
	const cpf = useForm('cpf')
	const dataDeNascimento = useForm('dataDeNascimento')
	const celular = useForm('celular')
	const email = useForm('email')
	const senha = useForm('password')
	const apelido = useForm('campoOpcional')
	const cep = useForm('cep')
	const rua = useForm('campoTexto')
	const numeroDaCasa = useForm('campoOpcional')
	const bairro = useForm('campoTexto')

	const cnpj = useForm('cnpj')
	const razaoSocial = useForm()

	const [cityOptions, setCityOptions] = useState<ISelectOption[]>([])
	const [citySelected, setCitySelected] = useState<ISelectOption | null>(null)
	const [stateOptions, setStateOptions] = useState<ISelectOption[]>([])
	const [stateSelected, setStateSelected] = useState<ISelectOption | null>(null)

	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [disabled, isDisabled] = useState(true)
	const [listOfPartner, setListOfPartner] = useState<
		IAssociateOperadorToPartner[]
	>([])

	const { user } = useTypedSelector(['user'])

	useEffect(() => {
		if (!operator) return
		nome.setValue(operator.Nome)
		cpf.setValue(formatCnpjCpf(operator.Cpf))
		operator.DataDeNascimento &&
			dataDeNascimento.setValue(maskBirthDate(operator.DataDeNascimento))
		operator.Celular && celular.setValue(formatCellphone(operator.Celular))
		email.setValue(operator.Email)
		apelido.setValue(operator.Apelido)
		operator.Endereco?.Cep && cep.setValue(operator.Endereco?.Cep)
		// rua.setValue(participant.Endereco?.logradouro)
		numeroDaCasa.setValue(operator.Endereco?.Numero)
		// bairro.setValue(participant.Endereco?.bairro)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [operator])

	useEffect(fetchUser, [operator])

	useEffect(() => {
		isDisabled(!allFieldsIsValid())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		nome.fieldIsValid,
		cpf.fieldIsValid,
		dataDeNascimento.fieldIsValid,
		celular.fieldIsValid,
		email.fieldIsValid,
		senha.fieldIsValid,
		listOfPartner,
		operator?.Id
	])

	const memoizedFetchStates = useMemo(() => fetchStates, [])

	useEffect(() => {
		const cleanup = initState()
		return cleanup
	}, [memoizedFetchStates])

	useEffect(getData, [cep.fieldIsValid])
	useEffect(initCity, [stateSelected?.value])
	useEffect(() => {
		getNamePartner()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cnpj.fieldIsValid])

	function fetchUser() {
		;(async () => {
			try {
				if (!operator) return
				setLoading(true)
				const response = await getPartnerByOperatorId(operator?.Id || '')
				if (!response) return

				setListOfPartner(
					response.Dados.map((partner) => {
						return {
							Cnpj: formatCnpjCpf(partner.Cnpj),
							Nome: partner.NomeFantasia
						}
					})
				)
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	function allFieldsIsValid() {
		return (
			nome.fieldIsValid &&
			cpf.fieldIsValid &&
			dataDeNascimento.fieldIsValid &&
			celular.fieldIsValid &&
			email.fieldIsValid &&
			listOfPartner.length &&
			(operator?.Id ? true : senha.fieldIsValid)
		)
	}

	function getNamePartner() {
		;(async () => {
			try {
				if (!cnpj.value) return
				const operator = await getPartnerByCnpj(cleanDocumentNumber(cnpj.value))

				razaoSocial.setValue(operator.Dados[0].NomeFantasia)
			} catch (error: any) {
				toasterError(
					error?.response?.data?.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu ao buscar Parceiro'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	async function handleSubmit() {
		try {
			toasterPromise()
			if (!allFieldsIsValid()) {
				return
			}
			setLoading(true)

			let address: IEndereco | null = null

			if (cep.fieldIsValid) {
				const getGeocode = await returnGeoCode(
					concatAddress(rua.value, stateSelected?.value || '')
				)
				address = {
					Bairro: bairro.value,
					Cep: cep.value.replace('-', ''),
					Cidade: citySelected?.value || '',
					Estado: stateSelected?.value || '',
					Logradouro: rua.value,
					Complemento: '',
					Numero: numeroDaCasa.value,
					Longitude: getGeocode.longitude,
					Latitude: getGeocode.latitude
				}
			} else {
				address = null
			}

			if (operator?.Id) {
				await updateOperator(operator.Id, {
					Nome: nome.value,
					Email: email.value,
					Celular: cleanPhoneNumber(celular.value),
					Parceiros: listOfPartner.map((partner) =>
						cleanDocumentNumber(partner.Cnpj)
					),
					Apelido: apelido.value,
					Endereco: address
				})

				toasterSuccess('Cadastro realizado com sucesso!')
				navigate(-1)
				return
			}
			await createOperator({
				Nome: nome.value,
				Email: email.value,
				DataDeNascimento: formatBirthDate(dataDeNascimento.value),
				Senha: senha.value,
				Celular: cleanPhoneNumber(celular.value),
				Cpf: cleanDocumentNumber(cpf.value),
				Parceiros: listOfPartner.map((partner) =>
					cleanDocumentNumber(partner.Cnpj)
				),
				Apelido: apelido.value,
				Endereco: address
			})
			toasterSuccess('Cadastro realizado com sucesso!')
			navigate(-1)
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: operator
					? 'Edição não realizada'
					: 'Cadastro não realizado'
			)
		} finally {
			setLoading(false)
		}
	}

	function getData() {
		;(async () => {
			try {
				if (!cep.fieldIsValid || cep.value < 8) return
				const jsonResult = await getAddress(cep.value.replace('-', ''))

				if (!jsonResult || jsonResult.erro) {
					rua.clearValue()
					bairro.clearValue()
					return
				}

				rua.setValue(jsonResult.logradouro)
				setCitySelected({
					label: jsonResult.localidade,
					value: jsonResult.localidade
				})
				setStateSelected({
					label: jsonResult.uf,
					value: jsonResult.uf
				})
				bairro.setValue(jsonResult.bairro)
			} catch (error) {
				console.log({ error: error })
			}
		})()
	}

	function initState() {
		;(async () => {
			try {
				const result = await fetchStates()
				if (!result?.length) return
				const newstate: ISelectOption[] = result.map((x) => {
					return {
						label: x.sigla,
						value: String(x.id)
					}
				})

				setStateOptions(newstate)
			} catch (error) {}
		})()
	}

	function initCity() {
		;(async () => {
			if (!stateSelected?.value) return

			const result = await fetchCities(stateSelected.value)
			if (!result?.length) return
			const newcities: ISelectOption[] = result.map((x) => {
				return {
					label: x.nome,
					value: String(x.nome)
				}
			})

			setCityOptions(newcities)
		})()
	}

	function handleCity(value: ISelectOption) {
		setCitySelected(value)
	}

	function handleState(value: ISelectOption) {
		setStateSelected(value)
		setCitySelected(null)
	}

	function addPartner() {
		//TODO: buscar operador por cpf
		if (!cnpj.fieldIsValid) {
			toasterError('CNPJ inválido')
			cnpj.clearValue()
			razaoSocial.clearValue()
			return
		}

		const findPartner = listOfPartner.find(
			(operator) =>
				cleanDocumentNumber(operator.Cnpj) === cleanDocumentNumber(cnpj.value)
		)

		if (findPartner) {
			toasterError('CNPJ já cadastrado')
			cnpj.clearValue()
			razaoSocial.clearValue()
			return
		}
		setListOfPartner((arr) => [
			...arr,
			{
				Cnpj: cnpj.value,
				Nome: razaoSocial.value
			}
		])

		cnpj.clearValue()
		razaoSocial.clearValue()
	}
	function renderButtonAdd(index: number) {
		return (
			<div className='btn-plus' onClick={addPartner}>
				<button className='btn-clean' type='button'>
					<img src={addLine} alt='Add line' />
				</button>
			</div>
		)
	}

	function renderSectionPartner(index?: number) {
		return (
			<div className='flex gap'>
				<InputPrimary
					{...cnpj}
					name='cnpjPartner'
					id='cnpjPartner'
					label='CNPJ do Parceiro'
					className='w40'
					erro=''
					Icon={cnpj.erro && alertIcon}
					tooltip={cnpj.erro}
				/>
				<InputPrimary
					{...razaoSocial}
					disabled={true}
					name='razaoSocial'
					id='razaoSocial'
					label='Nome do Parceiro'
					className='w60'
				/>
				{renderButtonAdd(index || 0)}
			</div>
		)
	}

	const mappedOptions = useMemo(() => {
		return listOfPartner.map((item) => {
			return {
				label: `${item.Cnpj} - ${item.Nome}`,
				value: item.Cnpj
			}
		})
	}, [listOfPartner])

	function handleChange(
		selectedOption: MultiValue<ISelectOption>,
		actionMeta: ActionMeta<ISelectOption>
	) {
		if (!selectedOption?.length) {
			setListOfPartner([])
			return
		}
		if (actionMeta.action === 'clear') {
			setListOfPartner([])
			return
		}

		setListOfPartner((options) =>
			options.filter((x) => x.Cnpj !== actionMeta.removedValue?.value)
		)
	}
	return (
		<S.Container>
			{user.NivelDeAcesso ? null : <HeaderNotLogged />}
			<div className='container-middle'>
				<GoBack />

				<form action=''>
					<div className='form-data'>
						<h1>Dados do Operador</h1>

						<div>
							<InputPrimary
								{...nome}
								name='name'
								id='name'
								label='Nome Completo'
							/>

							<InputPrimary {...email} name='email' id='email' label='E-mail' />

							<div className='flex-wrapper gap'>
								<InputPrimary
									{...cpf}
									name='cpf'
									id='cpf'
									label='CPF'
									className='w50'
									inputMode='numeric'
									disabled={operator ? true : false}
								/>

								<InputPrimary
									{...dataDeNascimento}
									name='dataDeNascimento'
									id='dataDeNascimento'
									label='Data De Nascimento'
									className='w50'
									inputMode='numeric'
								/>
							</div>
							<div className='flex-wrapper gap'>
								<InputPrimary
									{...celular}
									name='celular'
									id='celular'
									label='Celular'
									className='w50'
									inputMode='numeric'
								/>

								<InputPrimary
									{...apelido}
									name='apelido'
									id='apelido'
									label='Apelido'
									className='w50'
								/>
							</div>
							<div className='address'>
								<h1>Dados do Endereço (Opcional)</h1>

								<div className='flex-wrapper gap'>
									<InputPrimary
										{...cep}
										name='cep'
										id='cep'
										label='CEP'
										className='w60'
										inputMode='numeric'
									/>
									<InputPrimary
										{...numeroDaCasa}
										name='numeroDaCasa'
										id='numeroDaCasa'
										label='Numero'
										className='w40'
									/>
								</div>

								<InputPrimary {...rua} name='rua' id='rua' label='Endereço' />

								<InputPrimary
									{...bairro}
									name='bairro'
									id='bairro'
									label='Bairro'
								/>

								<div className='flex-wrapper gap'>
									<SelectPrimary
										label='Estado'
										name='state'
										options={stateOptions}
										value={stateSelected}
										onChange={handleState}
										className='select-city w40'
										placeholder='Estado'
										id='state'
									/>

									<SelectPrimary
										label='Cidade'
										name='city'
										options={cityOptions}
										value={citySelected}
										className='select-state w60'
										onChange={handleCity}
										placeholder='Cidade'
										id='city'
									/>
								</div>
							</div>

							<div className='info-operator'>
								<h1>Dados do parceiro</h1>
								<div className='operator-wrapper'>
									{renderSectionPartner()}
									<SelectBlock>
										<Select
											noOptionsMessage={() => null}
											styles={customStyles as any}
											delimiter='14'
											name='Parceiros'
											placeholder='Parceiros'
											isMulti
											value={mappedOptions}
											options={mappedOptions}
											onChange={handleChange}
										/>
										{mappedOptions.length >= 1 && <label>Operadores</label>}
									</SelectBlock>
								</div>
							</div>
							{operator ? null : (
								<div className='password-wrapper'>
									<h1 style={{ marginTop: 24 }}>Agora, cadastre a senha</h1>
									<InputPassword
										{...senha}
										name='senha'
										id='senha'
										label='Senha'
									/>

									<span className='info-msg'>
										A SENHA DEVE CONTER NO MÍNIMO{' '}
										<span className='rosa'> 8 CARACTERES </span>E AO MENOS
										<span className='rosa'>
											{' '}
											UM CARACTERE MINÚSCULO, MAIÚSCULO E NUMÉRICO.
										</span>
									</span>
								</div>
							)}
						</div>
					</div>
				</form>

				<div className='btn-submit'>
					<Button
						name={operator ? 'Editar cadastro' : 'Finalizar cadastro'}
						onClick={handleSubmit}
						disabled={disabled}
						loading={loading}
						type={'submit'}
					/>
				</div>
			</div>
			{user.NivelDeAcesso ? null : <FooterNotLogged />}
		</S.Container>
	)
}
