import { mediaQuery } from 'shared/styles/functions'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.header {
		display: flex;
		gap: 1rem;
		align-items: center;
	}

	#select {
		min-width: 300px;
	}

	.title {
		margin-bottom: 0;
	}

	${mediaQuery('sm')(`
		display: block;	

		.header{
			display: block;	
		}
	`)}
`
