import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from 'shared/components/ButtonPrimary'
import HeaderNotLogged from 'shared/components/HeaderNotLogged'
import GoBack from 'shared/components/GoBack'
import FooterNotLogged from 'shared/components/FooterNotLogged'
import {
	toasterError,
	toasterPromise,
	toasterSuccess
} from 'shared/utils/toaster'
import { Container } from './styles'
import useForm from 'shared/hooks/useForm'
import InputPassword from 'shared/components/InputPassword'
import { RouteEnumsUnauthentication } from 'modules/routes/enums'
import { changePassword } from 'shared/services/auth.service'

function ChangePassword() {
	const password = useForm('password')
	const confirmPassword = useForm('password')
	const { requestId } = useParams<{
		requestId: string
	}>()

	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	async function handleSubmit() {
		if (!requestId || !password.value) return
		try {
			toasterPromise()
			if (!password.fieldIsValid) {
				return
			}
			setLoading(true)

			const response = await changePassword(requestId, password.value)

			if (response.Sucesso) {
				toasterSuccess(response.Mensagem || 'Solicitação enviada por e-mail!')
			}

			navigate(RouteEnumsUnauthentication.LOGIN)
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: 'Ocorreu um erro, tente novamente mais tarde.'
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Container>
			<HeaderNotLogged title='Redefinição de senha' />
			<div className='container-middle'>
				<GoBack />
				<h1 className='title'>Nova senha</h1>
				{/* <h2 className="subtitle">
          Enviaremos um e-mail para redefinição de senha.
        </h2> */}
				<div className='form-data'>
					<InputPassword
						{...password}
						type='text'
						id='password'
						name='password'
						label='Nova senha'
					/>

					<InputPassword
						{...confirmPassword}
						type='text'
						id='confirmPassword'
						name='confirmPassword'
						label='Confirmar senha'
					/>

					<div className='btn-submit'>
						<Button
							loading={loading}
							name='Confirmar'
							disabled={
								!password.fieldIsValid ||
								!confirmPassword.fieldIsValid ||
								password.value !== confirmPassword.value
							}
							onClick={handleSubmit}
						/>
					</div>
				</div>
			</div>
			<FooterNotLogged />
		</Container>
	)
}

export default ChangePassword
