import { ButtonWrapper, Container, LoadingWrapper } from './styles'
import Loading from '../Loading'

interface IButtonPrimaryProps {
	onClick?: () => void
	onSubmit?: (e: any) => void
	name: string
	loading?: boolean
	disabled?: boolean
	background?: string
	shadow?: boolean
	type?: 'button' | 'submit' | 'reset'
	id?: string
}

const Button = (props: IButtonPrimaryProps) => {
	const {
		name,
		disabled = true,
		loading,
		onClick,
		onSubmit,
		background,
		shadow = true,
		type,
		id
	} = props

	function onSubmitDefault(e: any) {
		e.preventDefault()
		onSubmit && onSubmit(e)
	}
	return (
		<ButtonWrapper>
			{loading ? (
				<LoadingWrapper>
					<Loading />
				</LoadingWrapper>
			) : (
				<Container
					disabled={disabled}
					onClick={onClick}
					onSubmit={onSubmitDefault}
					type={type}
					style={{ background: background ? background : '' }}
					className={shadow ? 'shadow' : ''}
					id={id}
				>
					{name}
				</Container>
			)}
		</ButtonWrapper>
	)
}

export default Button
