import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'
import { ICashback } from 'shared/interfaces/cashback'
import { IPaginated } from 'shared/interfaces/paginated'
import { IResponse } from 'shared/interfaces/response'
import { IWallet } from 'shared/interfaces/wallet'
import axiosInstance from 'shared/utils/env'
import { QUERY_KEYS } from 'shared/utils/query'

export interface IRequestPaginated {
	page: number
	limite: number
	search: string
	dateInit: string
	dateFinal: string
}

export async function getCashback(
	payload: IRequestPaginated
): Promise<IResponse<IPaginated<ICashback[]>>> {
	const { limite, page, search, dateFinal, dateInit } = payload
	const response = await axiosInstance.get(
		`/voucher?pagina=${page}
		&limite=${limite}
		&dataInicio=${dateInit}
		&dataFim=${dateFinal}
		&termoDaBusca=${search}`
	)

	return response.data
}

export interface ISaqueCashbackRequest {
	participanteID: string
	ValorASacar: number
	CarteiraId: string
}
export async function sacarCashback(payload: ISaqueCashbackRequest) {
	const { CarteiraId, ValorASacar, participanteID } = payload
	const response = await axiosInstance.patch(
		`/voucher/sacar/participante/${participanteID}`,
		{
			CarteiraId,
			ValorASacar
		}
	)

	return response.data
}

export interface IRequestCashbackPaginated {
	page: number
	limite: number
	search: string
}

export async function getCashbackAvailablePaginatedByParticipant(
	payload: IRequestCashbackPaginated
): Promise<IResponse<IPaginated<ICashback[]>>> {
	const { limite, page, search } = payload
	const response = await axiosInstance.get(
		`/voucher?pagina=${page}
		&limite=${limite}
		&termoDaBusca=${search}`
	)

	return response.data
}
export function useGetCashbackAvailablePaginatedByParticipant({
	limite,
	page,
	search
}: IRequestCashbackPaginated): UseInfiniteQueryResult<
	IResponse<IPaginated<ICashback[]>>
> {
	const enabled = !!(limite && page)

	return useInfiniteQuery(
		[QUERY_KEYS.CASHBACK, { limite, search, page }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { limite, search, page } = params as IRequestPaginated

			return getCashbackAvailablePaginatedByParticipant({
				limite,
				page,
				search
			})
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage: IResponse<IPaginated<ICashback[]>>) => {
				return lastPage.Dados.IndiceDaPagina + 1
			}
		}
	)
}

export async function getSaldoPaginated(
	payload: IRequestCashbackPaginated
): Promise<IResponse<IPaginated<IWallet[]>>> {
	const { limite, page, search } = payload
	const response = await axiosInstance.get(
		`/carteira?pagina=${page}
		&limite=${limite}
		&termoDaBusca=${search}`
	)

	return response.data
}

export function useGetSaldoPaginated({
	limite,
	page,
	search
}: IRequestCashbackPaginated): UseInfiniteQueryResult<
	IResponse<IPaginated<IWallet[]>>
> {
	const enabled = !!(limite && page)

	return useInfiniteQuery(
		[QUERY_KEYS.WALLET, { limite, search, page }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { limite, search, page } = params as IRequestPaginated

			return getSaldoPaginated({
				limite,
				page,
				search
			})
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage: IResponse<IPaginated<IWallet[]>>) => {
				return lastPage.Dados.IndiceDaPagina + 1
			}
		}
	)
}
export async function reduceVoucherByOperator(id: string): Promise<void> {
	const response = await axiosInstance.patch(`/voucher/efetuar-baixa/${id}`)

	return response.data
}

export async function deleteCashback(id: string): Promise<void> {
	const response = await axiosInstance.delete(`/voucher/${id}`)

	return response.data
}
