import styled from 'styled-components'
import { mediaQuery } from 'shared/styles/functions'
import backgroundImg from 'assets/images/background.png'

export const LoginWrapper = styled.main`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	height: 100vh;
	width: 100vw;

	.logo {
		width: 300px;
		margin: auto;
		height: 30%;
		padding-top: 30px;
	}

	.login {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}

	.first-acess {
		background-color: var(--color-branco);
		border-radius: 4px;
		padding: 32px 24px;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: none;
		width: 100%;

		h1 {
			font-family: var(--font-oswald);
			color: var(--color-rosa);
		}
	}

	.recover-password {
		margin-top: 8px;
		color: #979e97;
		font-size: 10px;
		text-transform: uppercase;

		span {
			cursor: pointer;
			color: var(--color-rosa);
		}
	}
	section {
		width: 50%;
		margin: auto;
		height: 60%;
	}

	.form {
		background-color: var(--color-branco);
		border-radius: 4px;
		padding: 24px;
	}

	.btn-submit button {
		margin: auto;
		max-width: 200px;
	}

	footer {
		padding: 24px;
		height: 80px;
		border-top: 1px solid #ccc;
		text-align: center;
		height: 10%;

		a {
			text-transform: uppercase;
			font-size: 10px;
			color: rgb(151, 158, 151);
		}
		a + a {
			margin-left: 10px;
		}
	}

	${mediaQuery('sm')(`
      display: block;
      .login section{
        width: calc(100% - 24px);
      }
  `)}
`

export const ImageBackground = styled.div`
	${mediaQuery('sm')(`
        display: none;
  `)}
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: contain;
	height: 100%;
	background-image: url(${backgroundImg});
`
