import styled from 'styled-components'
import { mediaQuery } from '../../styles/functions'

export const HeaderWrapper = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 32px;
	height: 105px;
	position: fixed;
	top: 0px;
	z-index: 20;
	width: calc(100% - 64px);
	display: flex;
	align-items: center;
	box-shadow: rgb(0 0 0 / 8%) 0px 2px 3px;
	border-bottom: 1px solid #e0e0e0;
	background-color: var(--color-branco);

	h2 {
		font-size: 24px;
		font-weight: 300;
		font-family: var(--font-oswald);
	}

	.img-logo {
		width: 100px;
		background: 0;
		border: 0;
	}

	#nav-icon {
		width: 45px;
		height: 45px;
		position: relative;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
	}

	#nav-icon span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: #7f0bdd;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;
	}

	#nav-icon span:nth-child(1) {
		top: 0px;
		-webkit-transform-origin: left center;
		-moz-transform-origin: left center;
		-o-transform-origin: left center;
		transform-origin: left center;
	}

	#nav-icon span:nth-child(2) {
		top: 18px;
		-webkit-transform-origin: left center;
		-moz-transform-origin: left center;
		-o-transform-origin: left center;
		transform-origin: left center;
	}

	#nav-icon span:nth-child(3) {
		top: 36px;
		-webkit-transform-origin: left center;
		-moz-transform-origin: left center;
		-o-transform-origin: left center;
		transform-origin: left center;
	}

	#nav-icon.open span:nth-child(1) {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
		top: 7px;
		left: 9px;
	}

	#nav-icon.open span:nth-child(2) {
		width: 0%;
		opacity: 0;
	}

	#nav-icon.open span:nth-child(3) {
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
		top: 39px;
		left: 8px;
	}

	${mediaQuery('sm')(`
      padding: 0 24px;
	    width: calc(100% - 48px);
  `)}
`
