import styled from "styled-components";

export const ModalWrapper = styled.section`
  overflow: auto;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: -2;
  pointer-events: none;

  transition: all 0.50s linear;

  h1{
    font-size: 24px;
    font-weight: 300;
    font-family: var(--font-roboto);
    color:  var(--color-primary);
    margin-right: 40px;
  }

  .modal-bg {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;
      button {
        background: 0;
        border: 0;
      }
    }
    background: #f9f9f9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    left: 0;
    margin: 15vh auto 5vh auto;
    max-width: 780px;
    position: absolute;
    right: 0;
    width: fit-content;

    .modal-container {
      padding: 40px 32px;
      width: 100%;

      // > * {
      // 	border-radius: 4px;
      // 	background: #ffffff;
      // 	padding: 32px;
      // 	max-width: 780px;
      // }
    }

    .full {
      padding: 0;
    }
  }

  &.active {
    opacity: 1;
    top: 0;
    z-index: 999;

    // IE
    pointer-events: all;
  }
`;
