export const getVisiblePages = (page: number, total: number) => {
	if (total < 7) {
		return filterPages([1, 2, 3, 4, 5, 6], total)
	} else {
		if (page % 5 >= 0 && page > 4 && page + 2 < total) {
			return [1, page - 1, page, page + 1, total]
		} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
			return [1, total - 3, total - 2, total - 1, total]
		} else {
			return [1, 2, 3, 4, 5, total]
		}
	}
}

const filterPages = (visiblePages: any[], totalPages: number) => {
	return visiblePages.filter((page: number) => page <= totalPages)
}

export const formatPagination = (x: number, idx: number, arr: number[]) => {
	const { length } = arr

	const total = arr[length - 1]

	if (total <= 6) {
		return x
	} else if (length > 5) {
		return idx === 5 ? '...' + x : x
	} else {
		if (idx === 0) return x + '...'
		if (idx === 4 && arr[2] < total - 2) return '...' + x
		return x
	}
}
