import { IProps } from "./types";
import { ModalWrapper } from "./styles";
import X from "assets/images/x.svg";

const Modal = ({
  isActive,
  children,
  isFull,
  onClose,
  title,
}: IProps): JSX.Element => (
  <ModalWrapper className={isActive ? "active" : ""}>
    <div className="modal-bg">
      <div className={`modal-container ${isFull && "full"}`}>
        <header>
          <h1>{title}</h1>
          <button onClick={onClose}>
            <img src={X} alt="Close" />
          </button>
        </header>
        {children ? children : null}
      </div>
    </div>
  </ModalWrapper>
);

export default Modal;
