import styled from "styled-components";

export const Container = styled.button`
  background: 0;
  border: 0;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 40px;

  img {
    width: 20px;
  }

  p{
    text-transform: uppercase;
    font-family: var(--font-roboto);
  }
`;
