import { IPaginated } from 'shared/interfaces/paginated'
import { IEndereco } from 'shared/interfaces/endereco'
import { IParticipant, IUser } from 'shared/interfaces/user'
import axiosInstance from 'shared/utils/env'
import { removeCharacterSpecial } from 'shared/utils/validators'
import { IResponse } from 'shared/interfaces/response'

export interface IResquestCancel {
	UsuarioId: string
	Motivo: string
}

export async function cancelParticipant(
	payload: IResquestCancel
): Promise<void> {
	const response = await axiosInstance.patch(`/participante/cancelar`, {
		...payload
	})

	return response.data
}

export interface IResquestBlock {
	UsuarioId: string
	MotivoBloqueio: string
}
export async function blockParticipant(payload: IResquestBlock): Promise<void> {
	const response = await axiosInstance.patch(`/participante/bloquear`, {
		...payload
	})

	return response.data
}

export async function unblockParticipant(id: string): Promise<void> {
	const response = await axiosInstance.patch(`/participante/desbloquear/${id}`)

	return response.data
}

export interface IRequestResponse {
	Nome: string
	Email: string
	Cpf: string
	Senha: string
	DataDeNascimento: string
	Celular: string
	Endereco: IEndereco | null
	PermiteWhatsapp: boolean
}

export async function createParticipant(payload: IRequestResponse) {
	const response = await axiosInstance.post(`/participante`, {
		...payload
	})

	return response.data
}

export interface IParticipantPaginated {
	Id: string
	Nome: string
	Cpf: string
	DataHoraCadastro: string
	Ativo: boolean
	Email: string
}

export async function getPaginatedParticipant(
	page: number,
	limite: number,
	search: string
): Promise<IResponse<IPaginated<IParticipant[]>>> {
	const searchClean = removeCharacterSpecial(search)
	const response = await axiosInstance.get(
		`/participante?pagina=${page}&limite=${limite}&termoDaBusca=${searchClean}`
	)

	return response.data
}

export interface IRequestUpdate {
	Nome?: string
	DataDeNascimento?: string
	Celular?: string
	Apelido?: string
	Endereco: IEndereco | null
	PermiteWhatsapp: boolean
	Email?: string
}

export async function updateParticipant(
	id: string,
	payload: IRequestUpdate
): Promise<void> {
	const response = await axiosInstance.patch(`/participante/${id}`, {
		...payload
	})

	return response.data
}

export async function getParticipantById(
	id: string
): Promise<IResponse<IUser>> {
	const response = await axiosInstance.get(`/participante/${id}`)

	return response.data
}

export async function getMyWallets(id: string): Promise<IResponse<IUser>> {
	const response = await axiosInstance.get(`/participante/${id}`)

	return response.data
}
