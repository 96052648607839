import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IActivityActionCreators
} from '../../interfaces/action'
import { IActivity } from 'shared/interfaces/activity'

/**
 * Action types & creators
 */
export const { Types, Creators }: IActionsFromCreators<IActivityActionCreators> =
	createActions({
		setActivity: ['activity'],
		resetActivity: []
	})

/**
 * Handlers
 */

export const INITIAL_STATE: IActivity[] = [{
	Id: '',
	RamoDeAtividade: ''
}]

const setActivity = (state = INITIAL_STATE, action: any) => action.activity
const resetActivity = (state = INITIAL_STATE, action: any) => [INITIAL_STATE]

/**
 * Reducer
 */
export default createReducer<IActivity[], Action<IActivityActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_ACTIVITY]: setActivity,
		[Types.RESET_ACTIVITY]: resetActivity
	}
)
