export function getBase64(file: File) {
	return new Promise((resolve) => {
		let reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = function () {
			//console.log(reader.result)
			resolve(reader.result)
		}

		reader.onerror = function (error) {
			console.log('Error: ', error)
		}
		return reader.result as string
	})
}

export function removePrefixBase64(base64: string): string {
	const regex = /^data:image\/(png|jpeg|jpg|gif);base64,/
	return base64.replace(regex, '')
}
