import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { NivelDeAcessoEnum } from 'shared/interfaces/user'
import { adminRoutesData } from './routesAdminData'
import { renderRoute } from './data'

export function RoutesRedirect() {
	const { user } = useTypedSelector(['user'])

	function userHasPermission(accessTypes: NivelDeAcessoEnum[]): boolean {
		if (!accessTypes || accessTypes.length === 0) return true

		if (!user.NivelDeAcesso) return false
		const hasPermission = accessTypes.includes(user.NivelDeAcesso)
		return !!hasPermission
	}

	return (
		<>
			{/* <RouterProvider router={router} /> */}
			<BrowserRouter>
				<Routes>
					{adminRoutesData.map((routes) => {
						const isPublic = !routes.accessTypes.length

						const hasPermition = userHasPermission(routes.accessTypes)

						if (routes.accessTypes.length && hasPermition) {
							return (
								<Route
									element={routes.element}
									path={routes.path}
									key={routes.path}
								/>
							)
						} else if (isPublic && !user.NivelDeAcesso) {
							return (
								<Route
									element={routes.element}
									path={routes.path}
									key={routes.path}
								/>
							)
						}
						return null
					})}

					{/* Redireciona para página de login se não houver usuário */}

					<Route
						path='*'
						element={<Navigate to={renderRoute(user.NivelDeAcesso)} />}
					/>

					{/* Redireciona para a página de dashboard se o usuário tentar acessar uma rota não permitida */}
					{/* {user && !userHasPermission(user.NivelDeAcesso as any) && (
						<Navigate to={renderRoute(user)} />
					)} */}
				</Routes>
			</BrowserRouter>
			{/* </RouterProvider> */}
		</>
	)
}
