export interface IDashboard {
	DataInicio: string
	DataFim: string
	RelatorioAdmin: IReportTotal
	RelatorioParceiros: IReportPartner[]
}
export interface IReportTotal {
	QuantidadeDeVouchersBaixados: number
	QuantidadeDeVouchersGerados: number
	QuantidadeTotalDeLancamentos: number
	TotalDeVendas: number
	TotalDeVouchersBaixados: number
	TotalNovosParceiros: number
}
export interface IReportPartner {
	TotalDeVendas: number
	TotalDeVouchersGerados: number
	TotalDeVouchersBaixados: number
	Cnpj: string
	NomeFantasia: string
}

export const VALUE_INITIAL: IReportTotal = {
	TotalDeVendas: 0,
	TotalDeVouchersBaixados: 0,
	QuantidadeDeVouchersBaixados: 0,
	TotalNovosParceiros: 0,
	QuantidadeDeVouchersGerados: 0,
	QuantidadeTotalDeLancamentos: 0
}
