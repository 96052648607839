import { useState } from 'react'
import { endOfToday, isValid, startOfMonth } from 'date-fns'

import { Container } from './styles'
import DatePicker from 'shared/components/DatePicker'
import { IFromTo } from 'shared/interfaces/common'
import { useGetDashboardOperator } from 'shared/services/dashboard.service'
import {
	FORMAT_BIRTH_BIRTH_DATE,
	formatDateToUS,
	toMoney
} from 'shared/utils/format'
import { cleanDocumentNumber } from 'shared/utils/validators'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

function DashboardOperator() {
	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfMonth(new Date()),
		to: endOfToday()
	})
	const { partner } = useTypedSelector(['partner'])
	const { data } = useGetDashboardOperator({
		DataFim:
			(isValid(selectedDates.to) &&
				formatDateToUS(selectedDates.to as Date, FORMAT_BIRTH_BIRTH_DATE)) ||
			'',
		DataInicio:
			(isValid(selectedDates.from) &&
				formatDateToUS(selectedDates.from as Date, FORMAT_BIRTH_BIRTH_DATE)) ||
			'',
		cnpj: cleanDocumentNumber(partner.Cnpj)
	})

	function handleSetDates(value: IFromTo) {
		setSelectedDates(value)
	}

	function renderTotal(title: string, subtitle: number | string) {
		return (
			<div className='report-total'>
				<h3>{title}</h3>
				<p>{subtitle}</p>
			</div>
		)
	}

	return (
		<Container className='animated-right'>
			<div>
				<h1 className='title'>Relatório</h1>
				<div className='header-search'>
					<DatePicker dateSetter={handleSetDates} value={selectedDates} />
				</div>

				<div className='flex gap'>
					<>
						{renderTotal(
							'Quantidade de vouchers gerados',
							data?.Dados?.QuantidadeDeVouchersGerados || 0
						)}
						{renderTotal(
							'Quantidade de vouchers baixados',
							data?.Dados?.QuantidadeDeVouchersBaixados || 0
						)}
						{renderTotal(
							'Quantidade de lançamentos',
							data?.Dados?.QuantidadeTotalDeLancamentos || 0
						)}
					</>
				</div>

				<div className='flex gap total'>
					<>
						{renderTotal(
							'Total de vendas',
							toMoney(data?.Dados?.TotalDeVendas || 0)
						)}
						{renderTotal(
							'Total de vouchers baixados',
							toMoney(data?.Dados?.TotalDeVouchersBaixados || 0)
						)}
					</>
				</div>
			</div>
		</Container>
	)
}

export default DashboardOperator
