import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { Container } from './styles'
import Table from 'shared/components/Table'
import {
	IParticipantePage,
	ITableColumn,
	ITableRow,
	ITableNavProps
} from './types'
import { tableStyle } from 'shared/styles/functions'
import HeaderPage from 'shared/components/HeaderPage'

import IconEdit from 'assets/images/edit-participant.svg'
import IconTrash from 'assets/images/delete.svg'
import IconLock from 'assets/images/lock.svg'
import IconUnlock from 'assets/images/unlock.svg'

import { RouteEnumsAdmin } from '../routes/enums'
import { formatCnpjCpf, maskBirthDate } from 'shared/utils/format'
import { IModalDeleteTarget } from 'shared/components/ModalAction/types'
import ModalAction from 'shared/components/ModalAction'
import { getPaginatedParticipant } from 'shared/services/participant.service'
import { toasterError } from 'shared/utils/toaster'
import { IParticipant } from 'shared/interfaces/user'
import BadgeStatus from 'shared/components/BadgeStatus'
import { Status } from 'shared/interfaces/status'
import { cleanDocumentNumber } from 'shared/utils/validators'

function Participant() {
	const ITEMS_PER_PAGE = 10
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [participantePage, setParticipantePage] = useState<IParticipantePage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [currentTarget, setCurrentTarget] = useState<IModalDeleteTarget | null>(
		null
	)
	const [target, setTarget] = useState<IModalDeleteTarget | null>(null)

	const [classAnimated, setAnimated] = useState('animated-right')
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleDeleteEnd() {
		setAnimated('')

		setCurrentTarget({
			id: currentTarget?.id || '',
			name: currentTarget?.name || ''
		})

		setTarget(null)
		processTable()
	}

	function openModalDeleteOrBlock(
		id: string,
		name: string,
		type: 'BLOCK' | 'DELETE' | 'ACTIVE',
		hasReason: boolean,
		description?: string
	) {
		setAnimated('')

		setTarget({
			id,
			name,
			hasReason,
			type,
			description
		})
	}

	function handleClose() {
		setAnimated('')

		setTarget(null)
		setCurrentTarget(null)
	}

	useEffect(processTable, [searchDebounce, participantePage.page])

	function processTable() {
		;(async () => {
			try {
				setLoading(true)

				const response = await getPaginatedParticipant(
					participantePage.page,
					ITEMS_PER_PAGE,
					searchDebounce
				)

				if (!response.Dados.Itens.length) {
					setTableRows([])

					return
				}
				const rows = response.Dados.Itens.map((item) => {
					return {
						status: renderStatus(
							item.Status,
							item?.Motivo || '',
							cleanDocumentNumber(item.Cpf)
						),
						nome: item.Nome,
						cpf: formatCnpjCpf(item.Cpf),
						// parceiro: item.Id,
						createdAt: item.DataHoraCadastro
							? maskBirthDate(item.DataHoraCadastro)
							: '-',
						edit: renderEdit(item)
					}
				})

				setTableRows(rows)
				setParticipantePage({
					numberOfPages: response.Dados.NumeroTotalDePaginas,
					page: participantePage.page || 1,
					totalDocs: response.Dados.NumeroTotalDeItens
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Participantes'
				)
			} finally {
				setLoading(false)
			}
		})()
	}
	function goToNewParticipant() {
		navigate(RouteEnumsAdmin.CREATE_PARTICIPANT)
	}

	function goToEditParticipant(participant: IParticipant) {
		navigate(RouteEnumsAdmin.CREATE_PARTICIPANT, {
			state: { participant: participant }
		})
	}

	function renderStatus(status: Status, motivo: string, id: string) {
		return <BadgeStatus status={status} tooltip={motivo} id={id} />
	}

	function returnButton(onClick: () => void, Icon: string, className: string) {
		return (
			<button className='edit' onClick={onClick}>
				<img src={Icon} alt='Edit' />
			</button>
		)
	}

	function renderEdit(participant: IParticipant) {
		return (
			<div className='flex'>
				{returnButton(() => goToEditParticipant(participant), IconEdit, 'edit')}
				{participant.Status === Status.Bloqueado
					? returnButton(
							() =>
								openModalDeleteOrBlock(
									participant.Id || '',
									participant.Nome,
									'ACTIVE',
									false,
									`Motivo do Bloqueio: ${participant.Motivo}`
								),
							IconUnlock,
							'edit'
					  )
					: returnButton(
							() =>
								openModalDeleteOrBlock(
									participant.Id || '',
									participant.Nome,
									'BLOCK',
									true
								),
							IconLock,
							'edit'
					  )}
				{returnButton(
					() =>
						openModalDeleteOrBlock(
							participant.Id || '',
							participant.Nome,
							'DELETE',
							true
						),
					IconTrash,
					'remove'
				)}
			</div>
		)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Status', accessor: 'status', sortType: 'basic' },
		{ Header: 'Nome', accessor: 'nome', sortType: 'basic' },
		{ Header: 'CPF', accessor: 'cpf', sortType: 'basic' },
		// {
		// 	Header: 'Parceiro',
		// 	accessor: 'parceiro',
		// 	sortType: 'basic'
		// },
		{
			Header: 'Criado em',
			accessor: 'createdAt',
			sortType: 'basic'
		},
		{
			Header: 'Ações',
			accessor: 'edit',
			sortType: 'basic'
		}
	]

	function goToPage(pageIndex: number) {
		setParticipantePage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: participantePage.numberOfPages,
		pageIndex: participantePage.page - 1,
		totalDocs: participantePage.totalDocs
	}

	return (
		<Container className={classAnimated}>
			<div>
				<div>
					<HeaderPage
						title='Participantes'
						buttonName='Novo Participante'
						onClick={goToNewParticipant}
						returnSearch={handleSearch}
						placeholder='Pesquisar Participante'
						id='new-participant'
					/>
				</div>

				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableRows}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Participantes para serem exibidas.'
						{...tableStyle}
						padding={[26, 16, 26, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						isLoading={loading}
						className='table-participant'
					/>
				</div>
			</div>

			<ModalAction
				onClose={handleClose}
				target={target}
				type={'PARTICIPANT'}
				handleDeleteUserEnd={handleDeleteEnd}
			/>
		</Container>
	)
}

export default Participant
