import styled from 'styled-components'
import { mediaQuery } from 'shared/styles/functions'

export const Container = styled.div`
	.header-search {
		display: flex;
		align-items: start;
		justify-content: space-between;
		margin: 32px 0;
	}
	.table {
		margin-top: 40px;
	}
	.total {
		margin-top: 1rem;
	}
	.gap {
		justify-content: center;
	}
	.report-total {
		background: white;
		padding: 20px;
		border-radius: 4px;
		width: 20%;

		h3 {
			font-family: 'Regular', sans-serif;
			font-weight: 400;
			color: #666666;
			font-size: 10px;
			text-transform: uppercase;
			font-weight: bold;
		}

		p {
			font-size: 32px;
			font-family: 'Regular', sans-serif;
			/* font-weight: bold; */
			color: var(--color-rosa);
		}
	}

	${mediaQuery('sm')(`
      .flex, .header-search {
        display: block;
      }
      .report-total{
        width: 100%;
        padding: 32px;
        margin-bottom: 12px;
        box-sizing: border-box;
        text-align: center;
      }
  `)}
`

export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
