import { createStore } from 'redux'
import { Persistor, persistReducer, persistStore } from 'redux-persist'
import { Reducers } from './ducks'
import storage from 'redux-persist/lib/storage'

const key = `DIGITAL_CASH`
const persistConfig = {
	key: key,
	storage,
	whitelist: ['user', 'token', 'partner', 'activity']
}

const persistedReducer = persistReducer(persistConfig, Reducers)

export const store = createStore(persistedReducer)
export const persistor: Persistor = persistStore(store)
