import styled from "styled-components";
import { mediaQuery } from "shared/styles/functions";

export const Container = styled.div`
  .form-data {
    width: 50%;
    align-self: center;
  }

  .btn-submit {
    width: 120px;
    margin: auto;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 300;
    font-family: var(--font-roboto);
    margin-bottom: 40px;
    color: var(--color-primary);
  }

  ${mediaQuery("sm")(`
    .form-data {
      width: 100%;
    }
  `)}
`;
