import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FormControlLabel } from '@mui/material'

import * as S from './styles'
import GoBack from 'shared/components/GoBack'
import InputPrimary from 'shared/components/InputPrimary'
import useForm from 'shared/hooks/useForm'
import Button from 'shared/components/ButtonPrimary'
import {
	toasterError,
	toasterPromise,
	toasterSuccess
} from 'shared/utils/toaster'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import SelectPrimary from 'shared/components/SelectPrimary'
import { ISelectOption } from 'shared/interfaces/options'
import {
	fetchCities,
	fetchStates,
	getAddress
} from 'shared/services/cep.service'
import { IEndereco } from 'shared/interfaces/endereco'
import { concatAddress, returnGeoCode } from 'shared/utils/geocode'
import {
	formatCellphone,
	formatCnpjCpf,
	maskBirthDate
} from 'shared/utils/format'
import { RouteEnumsAdmin } from 'modules/routes/enums'
import Modal from 'shared/components/Modal'
import Loading, { LoadingEnum } from 'shared/components/Loading'
import { NivelDeAcessoEnum } from 'shared/interfaces/user'
import {
	getParticipantById,
	updateParticipant
} from 'shared/services/participant.service'
import { cleanPhoneNumber, formatCep } from 'shared/utils/validators'
import { updateOperator } from 'shared/services/operador.service'
import { IOSSwitch } from 'shared/components/Switch'
import { Creators as UserActions } from 'shared/store/ducks/user'

export default function MyProfile() {
	const nome = useForm('campoTexto')
	const cpf = useForm('cpf')
	const dataDeNascimento = useForm('dataDeNascimento')
	const celular = useForm('celular')
	const email = useForm('email')
	const senha = useForm('password')
	const apelido = useForm('campoOpcional')
	const cep = useForm('cep')
	const rua = useForm('campoTexto')
	const numeroDaCasa = useForm('campoOpcional')
	const bairro = useForm('campoTexto')
	const [checkboxWhatsApp, setcheckboxWhatsApp] = useState(false)

	const [cityOptions, setCityOptions] = useState<ISelectOption[]>([])
	const [citySelected, setCitySelected] = useState<ISelectOption | null>(null)
	const [stateOptions, setStateOptions] = useState<ISelectOption[]>([])
	const [stateSelected, setStateSelected] = useState<ISelectOption | null>(null)

	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [disabled, isDisabled] = useState(true)
	const [classAnimated, setAnimated] = useState('animated-right')
	const [modal, setModal] = useState(false)
	const { user } = useTypedSelector(['user'])

	const dispatch = useDispatch()

	function openModal() {
		setAnimated('')
		setModal(true)
	}

	function handleCloseModal() {
		setModal(false)
	}

	useEffect(() => {
		isDisabled(!allFieldsIsValid())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		nome.fieldIsValid,
		cpf.fieldIsValid,
		dataDeNascimento.fieldIsValid,
		celular.fieldIsValid,
		email.fieldIsValid,
		senha.fieldIsValid,
		cep.value,
		stateSelected?.value,
		citySelected?.value,
		rua.value
	])

	const memoizedFetchStates = useMemo(() => fetchStates, [])

	useEffect(() => {
		const cleanup = initState()
		return cleanup
	}, [memoizedFetchStates])

	useEffect(getData, [cep.fieldIsValid, user.Endereco?.Cep])
	useEffect(initCity, [stateSelected?.value])
	useEffect(onPreencheUser, [user])

	function allFieldsIsValid() {
		return (
			nome.fieldIsValid &&
			cpf.fieldIsValid &&
			dataDeNascimento.fieldIsValid &&
			celular.fieldIsValid &&
			email.fieldIsValid
			// &&
			// operator?.Id
			// ? true
			// : senha.fieldIsValid
		)
	}

	async function handleSubmit() {
		try {
			toasterPromise()
			if (!allFieldsIsValid() || !user?.Id) {
				return
			}
			setLoading(true)

			let address: IEndereco | null = null

			if (cep.value) {
				const getGeocode = await returnGeoCode(
					concatAddress(rua.value, stateSelected?.value || '')
				)
				address = {
					Bairro: bairro.value,
					Cep: cep.value.replace('-', ''),
					Cidade: citySelected?.value || '',
					Estado: stateSelected?.value || '',
					Logradouro: rua.value,
					Complemento: '',
					Numero: numeroDaCasa.value,
					Longitude: getGeocode.longitude,
					Latitude: getGeocode.latitude
				}
			}

			const newInfoUser = {
				Endereco: address ? address : user.Endereco,
				PermiteWhatsapp: checkboxWhatsApp,
				Celular: cleanPhoneNumber(celular.value),
				Nome: nome.value ? nome.value : undefined,
				Apelido: apelido.value ? apelido.value : undefined,
				Email: email.value
			}

			if (user.NivelDeAcesso === NivelDeAcessoEnum.Operador) {
				await updateOperator(user.Id || '', newInfoUser)
				const response = await getParticipantById(user.Id)

				dispatch(
					UserActions.setUser({
						...response.Dados,
						NivelDeAcesso: user.NivelDeAcesso
					})
				)
			} else {
				await updateParticipant(user.Id || '', newInfoUser)
				const response = await getParticipantById(user.Id)

				dispatch(
					UserActions.setUser({
						...response.Dados,
						MinhasCarteiras: user.MinhasCarteiras,
						NivelDeAcesso: user.NivelDeAcesso
					})
				)
			}

			toasterSuccess('Cadastro atualizado com sucesso!')
			navigate(-1)
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: 'Cadastro não atualizado'
			)
		} finally {
			setLoading(false)
			handleCloseModal()
		}
	}

	function getData() {
		;(async () => {
			try {
				if (!cep.fieldIsValid || cep.value < 8) return
				const jsonResult = await getAddress(cep.value.replace('-', ''))

				if (!jsonResult || jsonResult.erro) {
					rua.clearValue()
					bairro.clearValue()
					return
				}

				const endereco = user.Endereco !== undefined
				rua.setValue(
					jsonResult.logradouro || (endereco && user?.Endereco?.Logradouro)
				)
				setCitySelected({
					label: jsonResult.localidade ?? (endereco && user?.Endereco?.Cidade),
					value: jsonResult.localidade ?? (endereco && user?.Endereco?.Cidade)
				})
				setStateSelected({
					label: jsonResult.uf ?? (endereco && user?.Endereco?.Estado),
					value: jsonResult.uf ?? (endereco && user?.Endereco?.Estado)
				})
				bairro.setValue(jsonResult.bairro || user.Endereco?.Bairro)
			} catch (error) {
				console.log({ error: error })
			}
		})()
	}

	function onPreencheUser() {
		nome.setValue(user.Nome)
		cpf.setValue(formatCnpjCpf(user.Cpf))
		user.DataDeNascimento &&
			dataDeNascimento.setValue(maskBirthDate(user.DataDeNascimento))
		user.Celular && celular.setValue(formatCellphone(user.Celular))
		email.setValue(user.Email)
		apelido.setValue(user.Apelido)
		cep.setValue(user.Endereco?.Cep)
		numeroDaCasa.setValue(user.Endereco?.Numero)
		setcheckboxWhatsApp(user.PermiteWhatsApp)
	}
	function initState() {
		;(async () => {
			try {
				const result = await fetchStates()
				if (!result?.length) return
				const newstate: ISelectOption[] = result.map((x) => {
					return {
						label: x.sigla,
						value: String(x.id)
					}
				})

				setStateOptions(newstate)
			} catch (error) {}
		})()
	}

	function initCity() {
		;(async () => {
			if (!stateSelected?.value) return

			const result = await fetchCities(stateSelected.value)
			if (!result?.length) return
			const newcities: ISelectOption[] = result.map((x) => {
				return {
					label: x.nome,
					value: String(x.nome)
				}
			})

			setCityOptions(newcities)
		})()
	}

	function handleCity(value: ISelectOption) {
		setCitySelected(value)
	}

	function handleState(value: ISelectOption) {
		setStateSelected(value)
		setCitySelected(null)
	}

	function handleCheckbox() {
		setcheckboxWhatsApp((checked) => !checked)
	}

	function goToChangePassword() {
		navigate(RouteEnumsAdmin.CHANGE_MY_PASSWORD)
	}

	return (
		<S.Container className={classAnimated}>
			<div className='container-middle'>
				<GoBack />

				<form action=''>
					<div className='form-data'>
						<h1>Meus dados</h1>

						<div>
							<InputPrimary
								{...nome}
								name='name'
								id='name'
								label='Nome Completo'
							/>

							<InputPrimary {...email} name='email' id='email' label='E-mail' />

							<div className='flex gap'>
								<InputPrimary
									{...cpf}
									name='cpf'
									id='cpf'
									label='CPF'
									inputMode='numeric'
									className='w50'
									disabled
								/>

								<InputPrimary
									{...dataDeNascimento}
									name='dataDeNascimento'
									id='dataDeNascimento'
									label='Data De Nascimento'
									className='w50'
									inputMode='numeric'
									disabled
								/>
							</div>
							<div className='flex gap'>
								<InputPrimary
									{...celular}
									name='celular'
									id='celular'
									label='Celular'
									className='w50'
									inputMode='numeric'
								/>

								<InputPrimary
									{...apelido}
									name='apelido'
									id='apelido'
									label='Apelido'
									className='w50'
								/>
							</div>
							<div className='address'>
								<h1>Meu Endereço (Opcional)</h1>

								<div className='flex gap'>
									<InputPrimary
										{...cep}
										name='cep'
										id='cep'
										label='CEP'
										className='w60'
										inputMode='numeric'
										erro={cep.value && !cep.fieldIsValid ? cep.erro : ''}
									/>
									<InputPrimary
										{...numeroDaCasa}
										name='numeroDaCasa'
										id='numeroDaCasa'
										label='Numero'
										className='w40'
									/>
								</div>

								<InputPrimary {...rua} name='rua' id='rua' label='Endereço' />

								<InputPrimary
									{...bairro}
									name='bairro'
									id='bairro'
									label='Bairro'
								/>

								<div className='flex gap'>
									<SelectPrimary
										label='Estado'
										name='state'
										options={stateOptions}
										value={stateSelected}
										onChange={handleState}
										className='select-city w40'
										placeholder='Estado'
									/>

									<SelectPrimary
										label='Cidade'
										name='city'
										options={cityOptions}
										value={citySelected}
										className='select-state w60'
										onChange={handleCity}
										placeholder='Cidade'
									/>
								</div>
							</div>

							<div className='password-wrapper'>
								<button type='button' onClick={goToChangePassword}>
									<span className='info-msg'>
										<span className='rosa'> Alterar senha </span>
									</span>
								</button>
							</div>

							<FormControlLabel
								control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
								label='Permiti receber Whatsapp?'
								checked={checkboxWhatsApp}
								onChange={handleCheckbox}
							/>
						</div>
					</div>
				</form>

				<div className='btn-submit'>
					<Button
						name='Editar meus dados'
						onClick={openModal}
						disabled={disabled}
						loading={loading}
					/>
				</div>
			</div>

			<Modal
				title={'Deseja editar seu perfil?'}
				isActive={modal}
				onClose={handleCloseModal}
				// scrollRef="scroll-ref"
			>
				<S.ModalContainer>
					<S.TextTitle>Essa ação é irreversivel</S.TextTitle>

					{false ? (
						<S.LoadingWrapper>
							<Loading type={LoadingEnum.sentry} />
						</S.LoadingWrapper>
					) : (
						<Button
							onClick={handleSubmit}
							name={'Confirmar'}
							disabled={false}
						/>
					)}
				</S.ModalContainer>
			</Modal>
		</S.Container>
	)
}
