import { BadgeWrapper } from './styles'
import { Status } from 'shared/interfaces/status'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
export interface IProps {
	status: Status | string
	id: string
	tooltip?: string
}
export default function BadgeStatus(props: IProps) {
	const { status, tooltip, id } = props

	return (
		<BadgeWrapper status={status.toLowerCase()} id={'tooltip' + id}>
			<span>{status}</span>
			{tooltip && (
				<ReactTooltip
					data-tooltip-id='tooltip'
					content={tooltip}
					anchorSelect={`#tooltip${id}`}
					variant='dark'
				/>
			)}
		</BadgeWrapper>
	)
}
