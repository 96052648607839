import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'
import { IPaginated } from 'shared/interfaces/paginated'
import { ICreatePartner, IPartner } from 'shared/interfaces/partner'
import { IResponse } from 'shared/interfaces/response'
import axiosInstance from 'shared/utils/env'
import { QUERY_KEYS } from 'shared/utils/query'
import { removeCharacterSpecial } from 'shared/utils/validators'

export interface IResquestBlock {
	ParceiroId: string
	MotivoBloqueio: string
}
export async function deletePartner(payload: IResquestBlock): Promise<any> {
	const response = await axiosInstance.patch(`/parceiro/cancelar`, {
		...payload
	})

	return response.data
}

export async function createPartner(payload: ICreatePartner): Promise<any> {
	const response = await axiosInstance.post(`/parceiro/`, { ...payload })

	return response.data
}

export async function updatePartner(
	id: string,
	payload: ICreatePartner
): Promise<any> {
	const response = await axiosInstance.patch(`/parceiro/${id}`, { ...payload })

	return response.data
}

export async function blockPartner(payload: IResquestBlock): Promise<void> {
	const response = await axiosInstance.patch(`/parceiro/bloquear`, {
		...payload
	})

	return response.data
}

export async function getPartnerById(id: string): Promise<IPartner> {
	const response = await axiosInstance.get(`/parceiro/${id}`)

	return response.data
}

export interface IRequestPaginated {
	page: number
	limite: number
	search: string
}
export async function getPaginatedPartner(
	request: IRequestPaginated
): Promise<IResponse<IPaginated<IPartner[]>>> {
	const { limite, page, search } = request
	const searchClean = removeCharacterSpecial(search)

	const response = await axiosInstance.get(
		`/parceiro?pagina=${page}&limite=${limite}&termoDaBusca=${searchClean}`
	)

	return response.data
}

export function useGetPaginatedPartner({
	limite,
	page,
	search
}: IRequestPaginated): UseInfiniteQueryResult<
	IResponse<IPaginated<IPartner[]>>
> {
	const enabled = !!(limite && page)

	return useInfiniteQuery(
		[QUERY_KEYS.PARTNER, { limite, search, page }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { limite, search, page } = params as IRequestPaginated

			return getPaginatedPartner({
				limite,
				page,
				search
			})
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage: IResponse<IPaginated<IPartner[]>>) => {
				return lastPage.Dados.IndiceDaPagina + 1
			},
			retry: false
		}
	)
}

export async function getPartnerByCnpj(
	cnpj: string
): Promise<IResponse<IPartner[]>> {
	const response = await axiosInstance.get(`/parceiro/cnpj/${cnpj}`)

	return response.data
}

export interface IRequestListPartnerGeoCode {
	DistanciaEmKm: number
	Pagina: number
	Limite: number
	Latitude?: number
	Longitude?: number
}

export async function getListPartnerGeoCode(
	payload: IRequestListPartnerGeoCode
): Promise<IResponse<IPaginated<IPartner[]>>> {
	const response = await axiosInstance.post(`/parceiro/distancia`, {
		...payload
	})

	return response.data
}

export function useGetListPartnerGeoCode({
	DistanciaEmKm,
	Limite,
	Pagina,
	Longitude,
	Latitude
}: IRequestListPartnerGeoCode): UseInfiniteQueryResult<
	IResponse<IPaginated<IPartner[]>>
> {
	const enabled = !!(DistanciaEmKm && Limite && Pagina)

	return useInfiniteQuery(
		[
			QUERY_KEYS.PARTNER_GEO,
			{ Limite, Pagina, DistanciaEmKm, Latitude, Longitude }
		],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { DistanciaEmKm, Limite, Pagina, Latitude, Longitude } =
				params as IRequestListPartnerGeoCode

			return getListPartnerGeoCode({
				Limite,
				Pagina,
				DistanciaEmKm,
				Latitude,
				Longitude
			})
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			retry: false
		}
	)
}

export async function unblockPartner(id: string): Promise<void> {
	const response = await axiosInstance.patch(`/parceiro/desbloquear/${id}`)

	return response.data
}