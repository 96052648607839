import { useEffect, useState } from 'react'
import { ISelectOption } from 'shared/interfaces/options'
import { useTypedSelector } from './useTypedSelector'
import { getPaginatedPartner } from 'shared/services/partner.service'
import { toasterError } from 'shared/utils/toaster'
import SelectPrimary from 'shared/components/SelectPrimary'
import { isOperator } from 'shared/utils/isAdmin'

export interface IProps {
	placeholder?: string
	isCnpj?: boolean
}
function useSelectPartners(props: IProps) {
	const { placeholder, isCnpj = false } = props
	const [partnertOptions, setPartnerOptions] = useState<ISelectOption[]>([])
	const [partnertSelected, setPartnerSelected] = useState<ISelectOption | null>(
		null
	)
	const { partner } = useTypedSelector(['partner', 'user'])
	const [loading, setLoading] = useState(false)

	function handleSelect(value: ISelectOption): void {
		setPartnerSelected(value)
	}

	function fetchPartners() {
		;(async () => {
			try {
				if (isOperator() && partner.Id) {
					const mapped = {
						label: partner.NomeFantasia,
						value: isCnpj ? partner.Cnpj : partner.Id
					}

					setPartnerOptions([mapped])
					setPartnerSelected(mapped)
					return
				}
				setLoading(true)

				const response = await getPaginatedPartner({
					page: 1,
					limite: 1000,
					search: ''
				})

				const mappedOptionsPartners: ISelectOption[] = response.Dados.Itens.map(
					(partner) => {
						return {
							label: partner.NomeFantasia,
							value: isCnpj ? partner.Cnpj : partner.Id || ''
						}
					}
				)

				setPartnerOptions(mappedOptionsPartners)
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Parceiros'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	useEffect(fetchPartners, [])

	function renderSelect() {
		return (
			<SelectPrimary
				placeholder={placeholder || ''}
				label={placeholder || ''}
				name='select'
				options={partnertOptions}
				value={partnertSelected || { label: '', value: '' }}
				// className='select-state w60'
				onChange={handleSelect}
				id='select'
			/>
		)
	}

	return {
		renderSelect,
		fetchPartners,
		handleSelect,
		setLoading,
		loading,
		partnertOptions,
		partnertSelected
	}
}

export default useSelectPartners
