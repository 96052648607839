import styled from 'styled-components'
import { mediaQuery } from 'shared/styles/functions'

export const Container = styled.main`
	.btn {
		width: 200px;
		float: right;
	}

	.loading-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`
