import styled from 'styled-components'

export const DayPickerWrapper = styled.div`
	background: white;
	border-radius: 4px;
	width: 311px;


	.rdp {
		position: absolute;
		background: white;
		z-index: 2;
		padding: 16px;
		margin: 0;
	}

	.btn button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		border: 1px solid #eeeeee;
		padding: 16px;
		border-radius: 4px;
		width: 100%;

		img {
			width: 25px;
			height: 25px;
		}
	}
`
