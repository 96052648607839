import { RouteEnumsAdmin, RouteEnumsUnauthentication } from './enums'

import { NivelDeAcessoEnum } from 'shared/interfaces/user'

export const renderRoute = (role: NivelDeAcessoEnum | null) => {
	if (role === NivelDeAcessoEnum.Administrador) {
		return RouteEnumsAdmin.DASHBOARD
	} else if (role === NivelDeAcessoEnum.Operador) {
		return RouteEnumsAdmin.DASHBOARD_PARTNER
	} else if (role === NivelDeAcessoEnum.Participante) {
		return RouteEnumsAdmin.VOUCHER
	}

	return RouteEnumsUnauthentication.LOGIN
}
