import styled from 'styled-components'

export const Container = styled.div`
	.layer-active {
		background: rgba(0, 0, 0, 0.56);
		inset: 0px;
		top: 107px;
		position: fixed;
		z-index: 10;
		transition: all 3s;
		cursor: pointer;
	}

	.name {
		font-weight: 30;
		text-align: center;
		padding: 20px 0;
		font-family: var(--font-oswald);
	}
	.profile-link {
		margin: auto;
		cursor: pointer;
	}
	.inactive {
		/* display: none; */
		transform: translateX(-350px);
		/* transition: all 0.3s  0.1s; */
	}
	nav {
		padding: 24px;
		background: white;
		width: 300px;
		position: fixed;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		z-index: 10;
		top: 107px;
		bottom: 0;
		left: 0;
		overflow-y: auto;
		transition: all 0.3s ease 0.3s;
	}

	.icon {
		width: 25px;
		height: 25px;
		margin-right: 12px;
		fill {
			color: var(--lt-color-gray-600);
		}
	}

	.nav-link {
		padding: 20px 16px;
		display: flex;
		align-items: center;

		&:hover {
			background: #eee;
			border-radius: 4px;

			p {
				color: var(--color-rosa);
				font-weight: bold;
			}

			img {
				filter: invert(0.5) sepia(1) saturate(5) hue-rotate(288deg);
			}
		}

		p {
			color: var(--lt-color-gray-600);
			font-size: 16px;
		}
	}

	&.active {
		transform: translateX(0px);
	}
	.logout {
		color: var(--color-primary);
		font-family: var(--font-oswald);
		padding: 0;
		font-size: 16px;
		text-align: start;
		width: 29px;

		&:hover {
			font-weight: bold;
			border-bottom: 1px solid var(--color-primary);
		}
	}
`
