import { DefaultPagePlaceholderWrapper } from "./styles";
import { IDefaultPlaceholderProps } from "./types";
import placeholderDefault from "assets/images/placeholder-icon2.svg";

function DefaultPagePlaceholder({
  className,
  text = "Nada para mostrar aqui...",
  marginTop = 0,
  icon,
}: IDefaultPlaceholderProps) {
  return (
    <DefaultPagePlaceholderWrapper className={className} style={{ marginTop }}>
      <figure>
        <img
          className="placeholder-image"
          src={icon ? icon : placeholderDefault}
          alt="Sem dados"
        />
      </figure>

      <p className="placeholder-text">{text}</p>
    </DefaultPagePlaceholderWrapper>
  );
}

export default DefaultPagePlaceholder;
