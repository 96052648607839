import {
	blockCupom,
	cancelCupom,
	rescueCupomRequest,
	unblockCupom
} from 'shared/services/cupom.service'
import { blockOperator, deleteOperator, unblockOperator } from '../../services/operador.service'
import {
	blockParticipant,
	cancelParticipant,
	unblockParticipant
} from '../../services/participant.service'
import {
	blockPartner,
	deletePartner,
	unblockPartner
} from '../../services/partner.service'
import { TargetTypesEnum } from './types'
import { deleteCashback } from 'shared/services/cashback.service'

export const TITLE_ENUM: { [key in TargetTypesEnum]: string } = {
	OPERATOR: 'operador',
	PARTICIPANT: 'participante',
	PARTNER: 'parceiro',
	CUPOM: 'cupom',
	CASHBACK: 'cashback'
}

export const MODAL_TYPE: { [key in string]: string } = {
	DELETE: 'Deletar',
	BLOCK: 'Bloquear',
	REDUCE: 'Baixar',
	ACTIVE: 'Ativar'
}

export const MODAL_TYPE_SUCESS: { [key in string]: string } = {
	DELETE: 'Deletado',
	BLOCK: 'Bloqueado',
	REDUCE: 'Baixado',
	ACTIVE: 'Ativado'
}

export const FUNC_ENUM: {
	[key in TargetTypesEnum]: (id: string, motivo?: string) => Promise<any>
} = {
	OPERATOR: (id, motivo) =>
		deleteOperator({ Motivo: motivo || '', UsuarioId: id }),
	PARTICIPANT: (id, motivo) =>
		cancelParticipant({ Motivo: motivo || '', UsuarioId: id }),
	PARTNER: (id, motivo) =>
		deletePartner({ MotivoBloqueio: motivo || '', ParceiroId: id }),
	CUPOM: (id, motivo) => cancelCupom({ Motivo: motivo || '', CupomId: id }),
	CASHBACK: (id, motivo) => blockCupom({ Motivo: motivo || '', Codigo: id })
}

export const FUNC_BLOCK_ENUM: {
	[key in TargetTypesEnum]: (id: string, motivo?: string) => Promise<any>
} = {
	OPERATOR: (id, motivo) =>
		blockOperator({ MotivoBloqueio: motivo || '', UsuarioId: id }),
	PARTICIPANT: (id, motivo) =>
		blockParticipant({ MotivoBloqueio: motivo || '', UsuarioId: id }),
	PARTNER: (id, motivo) =>
		blockPartner({ MotivoBloqueio: motivo || '', ParceiroId: id }),
	CUPOM: (id, motivo) => blockCupom({ Motivo: motivo || '', Codigo: id }),
	CASHBACK: deleteCashback
}

export const FUNC_REDUCE_ENUM: {
	[key in TargetTypesEnum]: (id: string, motivo?: string) => Promise<any>
} = {
	CUPOM: rescueCupomRequest,

	OPERATOR: (id, motivo) =>
		blockOperator({ MotivoBloqueio: motivo || '', UsuarioId: id }),
	PARTICIPANT: (id, motivo) =>
		blockParticipant({ MotivoBloqueio: motivo || '', UsuarioId: id }),
	PARTNER: (id, motivo) =>
		blockPartner({ MotivoBloqueio: motivo || '', ParceiroId: id }),
	CASHBACK: deleteCashback
}

export const FUNC_ACTIVE_ENUM: {
	[key in TargetTypesEnum]: (id: string, motivo?: string) => Promise<any>
} = {
	CUPOM: unblockCupom,
	OPERATOR: unblockOperator,
	PARTICIPANT: unblockParticipant,
	PARTNER: unblockPartner,
	CASHBACK: deleteCashback
}
