import styled from 'styled-components'
import { mediaQuery } from 'shared/styles/functions'

export const Container = styled.div`
	z-index: 1;
	.image-voucher {
		width: 80px;
		height: 80px;
		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.form-data {
		width: 50%;
		align-self: center;
	}

	.btn-submit {
		width: 120px;
		margin: auto;
	}

	.subtitle {
		font-size: 20px;
		font-weight: 300;
		font-family: var(--font-roboto);
		margin-bottom: 40px;
		color: var(--color-primary);
	}

	${mediaQuery('sm')(`
    .form-data {
      width: 100%;
    }
  `)}
`
export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
