import { mediaQuery } from 'shared/styles/functions'
import styled from 'styled-components'

export const Container = styled.section`
	main {
		width: 800px;
		margin: auto;
	}

	.period {
		justify-content: space-between;
		margin-bottom: 8px;
		align-items: center;
	}

	.select {
		width: 80%;
		margin-right: 16px;
	}

	.input-wrapper {
		justify-content: space-between;
	}

	.w50 {
		width: 50%;
	}
	.w20 {
		width: 20%;
		margin-left: 16px;
	}

	.w30 {
		width: 30%;
		margin-left: 16px;
	}
	.description {
		font-size: 12px;
		font-family: var(--font-roboto);
		color: var(--color-boxshadow-cinzaescuro);
		margin-bottom: 8px;
	}
	.file {
		margin: 10px 0;
		padding: 0rem 1rem;
		height: 185px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* font-size: 3rem; */
		background: #fc5c943b;
		/* box-shadow: 0 0 0 3px var(--color-rosa); */
		border: 1px solid var(--color-rosa);
		color: var(--color-rosa);
		border-radius: 0.2rem;
		cursor: pointer;

		img {
			height: 20px;
			width: 20px;
		}
	}

	.info-logo {
		width: 300px;
		height: 200px;
		margin: auto;
		margin-bottom: 32px;
	}

	.img-upload {
		position: relative;
		align-self: center;
		border: 1px solid var(--color-rosa);
		background: var(--color-branco);
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		height: calc(100% - 32px);

		.logo {
			border-radius: 4px;
			height: 100%;
			object-fit: cover;
		}

		button {
			width: 30px;
			height: 30px;
			background: 0;
			border: 0;
			position: absolute;
			bottom: 24px;
			right: 24px;
		}
	}

	.button-submit {
		width: 200px;
		margin: 10px auto;
	}

	input {
		outline: none;
	}
	.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
		box-shadow: 0 0 2px 1px var(--color-rosa);
		border-color: var(--color-instagram);
		color: var(--color-primary);
		transition: 0.3s;
	}

	.MuiInputLabel-root.Mui-focused {
		color: var(--color-rosa);
		background: white;
		padding: 2px;
	}
	.date-selected {
		.MuiInputLabel-root {
			color: var(--color-rosa);
			background: white;
			padding: 2px;
		}
	}

	.error-overdate {
		display: flex;
		align-items: center;
		margin-top: 20px;
		justify-content: center;

		.icon-alert {
			width: 20px;
			margin-right: 8px;
		}
		p {
			color: #d32f2f;
			font-weight: bold;
			font-size: 14px;
		}
	}

	${mediaQuery('sm')(`
		main{
			width: 100%;
		}
		.MuiFormControl-root{
			width: 100%;
		}
		.select {
			width: 100%;
			margin-right: 0;
		}
		.flex{
			display: block;
		}

		.w50, .w20, .w30{
			width: 100%;
			margin-left: 0;
		}
  `)}
`
