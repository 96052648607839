import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { Container } from './styles'
import Table from 'shared/components/Table'
import {
	IParticipantePage,
	ITableColumn,
	ITableRow,
	ITableNavProps
} from './types'
import { tableStyle } from 'shared/styles/functions'
import HeaderPage from 'shared/components/HeaderPage'

import IconEdit from 'assets/images/edit-participant.svg'
import IconTrash from 'assets/images/delete.svg'
import IconLock from 'assets/images/lock.svg'
import IconUnlock from 'assets/images/unlock.svg'

import { RouteEnumsAdmin } from '../routes/enums'
import { formatCnpjCpf, maskBirthDate, toMoney } from 'shared/utils/format'
import { IPartner } from 'shared/interfaces/partner'
import ModalAction from 'shared/components/ModalAction'
import { IModalDeleteTarget } from 'shared/components/ModalAction/types'
import { useGetPaginatedPartner } from 'shared/services/partner.service'
import { IOperator } from 'shared/interfaces/user'
import { INITIAL_STATE } from 'shared/store/ducks/user'
import { Status } from 'shared/interfaces/status'
import BadgeStatus from 'shared/components/BadgeStatus'
import { cleanDocumentNumber } from 'shared/utils/validators'

function Partner() {
	const ITEMS_PER_PAGE = 10
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [partnerPage, setPartnerPage] = useState<IParticipantePage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [currentTarget, setCurrentTarget] = useState<IModalDeleteTarget | null>(
		null
	)

	const [target, setTarget] = useState<IModalDeleteTarget | null>(null)
	const navigate = useNavigate()
	const [classAnimated, setAnimated] = useState('animated-right')

	function handleSearch(value: string): void {
		setSearch(value)
	}

	const { data, isLoading, refetch } = useGetPaginatedPartner({
		limite: ITEMS_PER_PAGE,
		page: partnerPage.page,
		search: searchDebounce
	})

	const itens = useMemo(
		() => data?.pages.map((page) => page.Dados.Itens).flat() || [],
		[data]
	)
	const dados = useMemo(
		() => data?.pages.map((page) => page.Dados).flat() || [],
		[data]
	)

	useEffect(processTable, [itens])

	function processTable() {
		if (!itens?.length) {
			setTableRows([])

			return
		}
		const rows = itens.map((item) => {
			return {
				Status: renderStatus(
					item.Status,
					item?.Motivo || '',
					item.Id || cleanDocumentNumber(item.Cnpj)
				),
				NomeFantasia: item.NomeFantasia,
				Email: item.Cnpj,
				Cnpj: formatCnpjCpf(item.Cnpj),
				CpfOperador: item.OperadoresDoParceiro?.length
					? renderCpf(item.OperadoresDoParceiro)
					: renderCpf([{ ...INITIAL_STATE, Parceiros: [] }]),
				PorcentagemDeCashback: `${item.PorcentagemDeCashback}%`,
				RamoDeAtividade: item.RamoDeAtividade,
				ValorCashbackAutomatico: toMoney(item.ValorCashbackAutomatico),
				createdAt: maskBirthDate(item.DataHoraCadastro ?? '') || '',
				edit: renderEdit(item)
			}
		})

		setTableRows(rows)
		setPartnerPage({
			numberOfPages: dados[0].NumeroTotalDePaginas,
			page: partnerPage.page || 1,
			totalDocs: dados[0].NumeroTotalDeItens
		})
	}

	function renderCpf(profile: IOperator[]): JSX.Element {
		const onlyNames = profile.map(({ Nome }) => Nome)

		return renderArrayWithComma(onlyNames)
	}

	function renderArrayWithComma(array: string[]) {
		return (
			<div className='unit'>
				{array.map((item, index) => (
					<p key={`${index}`}>
						{item}
						{array.length - 1 > index && ','}
					</p>
				))}
			</div>
		)
	}

	function goToNewPartner() {
		navigate(RouteEnumsAdmin.CREATE_PARTNER)
	}

	function goToEditPartner(partner: IPartner) {
		navigate(RouteEnumsAdmin.CREATE_PARTNER, {
			state: { partner: partner }
		})

		// navigate(RouteEnumsAdmin.EDIT_PARTNER, {
		//   state: { partner },
		// });
	}

	function renderStatus(status: Status, motivo: string, id: string) {
		return <BadgeStatus status={status} tooltip={motivo} id={id} />
	}

	function handleDeleteEnd() {
		setCurrentTarget({
			id: currentTarget?.id || '',
			name: currentTarget?.name || ''
		})

		setTarget(null)
		refetch()
	}

	function handleClose() {
		setTarget(null)
		setCurrentTarget(null)
	}

	function openModalDeleteOrBlock(
		id: string,
		name: string,
		type: 'BLOCK' | 'DELETE' | 'ACTIVE',
		hasReason: boolean,
		description?: string
	) {
		setAnimated('')

		setTarget({
			id,
			name,
			type,
			hasReason,
			description
		})
	}

	function returnButton(onClick: () => void, Icon: string, className: string) {
		return (
			<button className='edit' onClick={onClick}>
				<img src={Icon} alt='Edit' />
			</button>
		)
	}

	function renderEdit(partner: IPartner) {
		return (
			<div className='flex'>
				{returnButton(() => goToEditPartner(partner), IconEdit, 'edit')}
				{partner.Status === Status.Bloqueado
					? returnButton(
							() =>
								openModalDeleteOrBlock(
									partner.Id || '',
									partner.NomeFantasia,
									'ACTIVE',
									false,
									`Motivo do Bloqueio: ${partner.Motivo}`
								),
							IconUnlock,
							'edit'
					  )
					: returnButton(
							() =>
								openModalDeleteOrBlock(
									partner.Id || '',
									partner.NomeFantasia,
									'BLOCK',
									true
								),
							IconLock,
							'edit'
					  )}
				{returnButton(
					() =>
						openModalDeleteOrBlock(
							partner.Id || '',
							partner.NomeFantasia,
							'DELETE',
							true,
							'Realmente deseja fazer isso, todos os voucher e cupons serão sacados.'
						),
					IconTrash,
					'remove'
				)}
			</div>
		)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Status', accessor: 'Status', sortType: 'basic' },
		{ Header: 'Nome', accessor: 'NomeFantasia', sortType: 'basic' },
		{ Header: 'CNPJ', accessor: 'Cnpj', sortType: 'basic' },
		{
			Header: 'Operador',
			accessor: 'CpfOperador',
			sortType: 'basic'
		},
		{
			Header: 'Atividade',
			accessor: 'RamoDeAtividade',
			sortType: 'basic'
		},
		{
			Header: '% Cashback',
			accessor: 'PorcentagemDeCashback',
			sortType: 'basic'
		},

		{
			Header: 'Valor Cashback',
			accessor: 'ValorCashbackAutomatico',
			sortType: 'basic'
		},
		// {
		//   Header: "E-mail",
		//   accessor: "Email",
		//   sortType: "basic",
		// },
		// {
		//   Header: "Criado em",
		//   accessor: "createdAt",
		//   sortType: "basic",
		// },
		{
			Header: 'Ações',
			accessor: 'edit',
			sortType: false
		}
	]

	function goToPage(pageIndex: number) {
		setPartnerPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: partnerPage.numberOfPages,
		pageIndex: partnerPage.page - 1,
		totalDocs: partnerPage.totalDocs
	}

	return (
		<Container className={classAnimated}>
			<div>
				<div>
					<HeaderPage
						title='Parceiros'
						buttonName='Novo Parceiro'
						onClick={goToNewPartner}
						returnSearch={handleSearch}
						placeholder='Pesquisar Parceiro'
						id='new-partner'
					/>
				</div>

				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableRows}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Parceiros para serem exibidas.'
						{...tableStyle}
						isLoading={isLoading}
						padding={[26, 16, 26, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						className='table-partner'
					/>
				</div>
			</div>
			<ModalAction
				onClose={handleClose}
				target={target}
				type={'PARTNER'}
				handleDeleteUserEnd={handleDeleteEnd}
			/>
		</Container>
	)
}

export default Partner
