import React from 'react'
import { ImgSkeleto, Skeleto, WrapperSkeleto } from './styles'

function Image({ alt, ...props }: any) {
	const [skeleton, setSkeleton] = React.useState(true)

	function handleLoad({ target }: any) {
		setSkeleton(false)
		target.style.opacity = 1
	}

	return (
		<WrapperSkeleto>
			{skeleton && <Skeleto />}
			<ImgSkeleto onLoad={handleLoad} alt={alt} {...props} />
		</WrapperSkeleto>
	)
}

export default Image
