import { createGlobalStyle } from 'styled-components'
import { animate, mediaQuery, shine } from './shared/styles/functions'

export const GlobalStyle = createGlobalStyle`
  html{
    --color-boxshadow-cinza: #e2e2e2;
    --color-primary: #333;
    --color-secondary: #000;
    --color-cinza: #b9afaf;
    --color-branco:  #fff;
    --color-boxshadow-cinzaescuro: #403b3b;
    --color-instagram:  #f008bd;
    --color-facebook: rgb(7, 79, 212);
    --color-twitter: red;
    --color-youtube: #0a95b8;
    --color-backgroundblack: #282c34;
    --color-rosa : #fc5c94;
    --font-oswald : "Oswald", Helvetica, Sans-Serif;
    --border-color-escuro: border-color: rgb(24, 26, 31);
    --font-roboto: Roboto, Helvetica, Sans-Serif;
    --font-courgette: 'Courgette', cursive;
    
  }

  .rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #f7b1db;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #f7b1db;
  --rdp-background-color-dark: #f7b1db;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid #f008bd;
}
  
  body {
    margin: 0;
    padding: 0;
    background: #fafafa;
    color: var(--color-primary);
    font-family: var(--font-roboto);
    box-sizing: border-box;
    transition: all 0.50s linear;
 
  }
  ::placeholder{
    font-family:  var(--font-oswald) ;
  }
  p{
      color: var(--color-primary);
  }
  input, button{
    display: block;
    box-sizing: border-box;
    /* border: none; */
    outline: none;
  }
  button{
    cursor: pointer;
    border: none;
    background: none;
  }

  input{
    width: 100%;
  }
  a{
    text-decoration: none;
    color:  var(--color-cinza);
    
  }
  p, ul, li, h3, h1, h5{
    margin: 0;
    padding: 0;
  }
  img{
    display: block;
    width: 100%;
  }
  li{
    list-style: none;
  }

 

  .container-middle {
    min-height: calc(100vh - (110px + 144px + 80px));
	  display: flex;
	  flex-direction: column;
    margin: auto;
    max-width: 1200px;
    padding: 40px;
    background: #F9F9F9;
  }

  .mt120{
    margin-top: 120px;
  }
  
  .container-small {
    min-height: calc(100vh - (110px + 144px + 80px));
	  display: flex;
	  flex-direction: column;
    margin: auto;
    max-width: 750px;
    padding: 40px;

   
  }

  .flex{
    display: flex;
  }

  .gap{ 
    gap: 1rem;
  }

  .title {
    font-size: 24px;
    font-weight: 300;
    font-family: var(--font-roboto);
    margin-bottom: 32px;
    color:  var(--color-primary);

  }
  .ql-container{
    height: 400px;
  }

  ${mediaQuery('sm')(`
    .container-small{
      padding: 24px;
    }

    .container-middle{
      padding: 24px;
    }
  `)}
 
 .action-btn-submit {
    margin: auto;
    display: flex;
    justify-content: space-between;
    
    .link {
      text-decoration: underline;
      color: var(--color-primary);
    }
  }
 .animated-left{
		opacity: 0;
		transform: translateX(-30%);
		animation: ${animate} 0.4s forwards;
	}

	.animated-right {
		opacity: 0;
		transform: translateX(20%);
		animation: ${animate} 0.4s forwards;
	}

  .is-loading {
  .image,
  h2,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
  }

  .image {
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h2 {
    height: 30px;
  }

  p {
    height: 70px;
  }

 
}
`
