import { IEndereco } from 'shared/interfaces/endereco'
import { IPaginated } from 'shared/interfaces/paginated'
import { IResponse } from 'shared/interfaces/response'
import { IOperator } from 'shared/interfaces/user'
import axiosInstance from 'shared/utils/env'
import { removeCharacterSpecial } from 'shared/utils/validators'

export interface IResquestCancel {
	UsuarioId: string
	Motivo: string
}
export async function deleteOperator(payload: IResquestCancel): Promise<void> {
	const response = await axiosInstance.patch(`/operador/cancelar`, {
		...payload
	})

	return response.data
}

export interface IResquestBlock {
	UsuarioId: string
	MotivoBloqueio: string
}
export async function blockOperator(payload: IResquestBlock): Promise<any> {
	const response = await axiosInstance.patch(`/operador/bloquear`, {
		...payload
	})

	return response.data
}

export async function unblockOperator(id: string): Promise<void> {
	const response = await axiosInstance.patch(`/operador/desbloquear/${id}`)

	return response.data
}

export async function getOperatorByCPF(
	cpf: string
): Promise<IResponse<IOperator>> {
	const response = await axiosInstance.get(
		`/administrador/buscar-futuro-operador/${cpf}`
	)

	return response.data
}

export async function getPaginatedOperator(
	page: number,
	limite: number,
	search: string
): Promise<IResponse<IPaginated<IOperator[]>>> {
	const searchClean = removeCharacterSpecial(search)

	const response = await axiosInstance.get(
		`/operador?pagina=${page}&limite=${limite}&termoDaBusca=${searchClean}`
	)

	return response.data
}

export async function getPaginatedOperatorByPartnerID(
	partnerId: string
): Promise<IResponse<IPaginated<IOperator[]>>> {
	const response = await axiosInstance.get(`/operador/parceiro/${partnerId}`)

	return response.data
}

export interface IRequestUpdate {
	Nome?: string
	Celular?: string
	Apelido?: string
	Endereco?: IEndereco | null
	Parceiros?: string[]
	Email?: string
	PermiteWhatsApp?: string
}

export interface IRequestCreateOperator {
	Nome: string
	Email: string
	Cpf: string
	Senha: string
	DataDeNascimento: string
	Celular: string
	Endereco: IEndereco | null
	PermiteWhatsApp?: boolean
	Parceiros: string[]
	Apelido?: string
}

export async function createOperator(
	payload: IRequestCreateOperator
): Promise<void> {
	const response = await axiosInstance.post(`/operador/`, {
		...payload
	})

	return response.data
}
export async function updateOperator(
	id: string,
	payload: IRequestUpdate
): Promise<void> {
	const response = await axiosInstance.patch(`/operador/${id}`, {
		...payload
	})

	return response.data
}

export interface IResponseGetPartnerByOperatorId {
	NomeFantasia: string
	Cnpj: string
}

export async function getPartnerByOperatorId(
	id: string
): Promise<IResponse<IResponseGetPartnerByOperatorId[]>> {
	const response = await axiosInstance.get(`/operador/${id}/parceiros`)

	return response.data
}
