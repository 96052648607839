import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import * as S from './styles'
import GoBack from 'shared/components/GoBack'
import FooterNotLogged from 'shared/components/FooterNotLogged'
import HeaderNotLogged from 'shared/components/HeaderNotLogged'
import InputPrimary from 'shared/components/InputPrimary'
import useForm from 'shared/hooks/useForm'
import Button from 'shared/components/ButtonPrimary'
import { toasterError, toasterSuccess } from 'shared/utils/toaster'
import {
	IRequestUpdate,
	createParticipant,
	updateParticipant
} from 'shared/services/participant.service'
import { cleanDocumentNumber, cleanPhoneNumber, formatCep } from 'shared/utils/validators'
import InputPassword from 'shared/components/InputPassword'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { ISelectOption } from 'shared/interfaces/options'
import {
	fetchCities,
	fetchStates,
	getAddress
} from 'shared/services/cep.service'
import SelectPrimary from 'shared/components/SelectPrimary'
import useActionDeleteOrBlock from 'shared/hooks/useActionDeleteOrBlock'
import { IParticipant } from 'shared/interfaces/user'
import {
	formatBirthDate,
	formatCellphone,
	formatCnpjCpf,
	maskBirthDate
} from 'shared/utils/format'
import { concatAddress, returnGeoCode } from 'shared/utils/geocode'
import { IEndereco } from 'shared/interfaces/endereco'
import { FormControlLabel, Radio } from '@mui/material'
import { IOSSwitch } from 'shared/components/Switch'
import { isAdmin } from 'shared/utils/isAdmin'
import { getTermOfAdhesion } from 'shared/services/termOfAdhesion.service'
import Modal from 'shared/components/Modal'
import { pink } from '@mui/material/colors'
import Loading from 'shared/components/Loading'
import Editor from 'shared/components/Editor'

interface IRouteState {
	participant: IParticipant | null
}
export default function CreateAccountParticipant() {
	const route = useLocation()
	const state = route.state as IRouteState
	const participant = state?.participant

	const nome = useForm('campoTexto')
	const cpf = useForm('cpf')
	const dataDeNascimento = useForm('dataDeNascimento')
	const celular = useForm('celular')
	const email = useForm('email')
	const senha = useForm('password')
	const apelido = useForm('campoOpcional')

	const cep = useForm('cep')
	const rua = useForm('campoTexto')
	const numeroDaCasa = useForm('campoTexto')
	const bairro = useForm('campoTexto')

	const [cityOptions, setCityOptions] = useState<ISelectOption[]>([])
	const [citySelected, setCitySelected] = useState<ISelectOption | null>(null)
	const [stateOptions, setStateOptions] = useState<ISelectOption[]>([])
	const [stateSelected, setStateSelected] = useState<ISelectOption | null>(null)
	const [checkboxWhatsApp, setcheckboxWhatsApp] = useState(false)
	const [termOfAdhesion, setTermOfAdhesion] = useState('')
	const [showTerm, setShowTerm] = useState(false)
	const [accetTerm, setAcceitTerm] = useState(false)

	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [disabled, isDisabled] = useState(true)

	const { classAnimated, renderButtonsAction, renderModal } =
		useActionDeleteOrBlock({
			disabled,
			handleSubmit,
			id: participant?.Id || '',
			loading,
			nome: participant?.Nome || '',
			type: 'PARTICIPANT'
		})

	const { user } = useTypedSelector(['user'])

	useEffect(() => {
		if (!participant) return
		nome.setValue(participant.Nome)
		cpf.setValue(formatCnpjCpf(participant.Cpf))
		participant.DataDeNascimento &&
			dataDeNascimento.setValue(maskBirthDate(participant.DataDeNascimento))
		participant.Celular &&
			celular.setValue(formatCellphone(participant.Celular))
		email.setValue(participant.Email)
		apelido.setValue(participant.Apelido)
		participant.Endereco?.Cep && cep.setValue(participant.Endereco?.Cep)
		// rua.setValue(participant.Endereco?.logradouro)
		numeroDaCasa.setValue(participant.Endereco?.Numero)
		// bairro.setValue(participant.Endereco?.bairro)
		setcheckboxWhatsApp(participant.PermiteWhatsApp)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participant])

	useEffect(() => {
		isDisabled(!allFieldsIsValid())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		nome.fieldIsValid,
		cpf.fieldIsValid,
		dataDeNascimento.fieldIsValid,
		celular.fieldIsValid,
		email.fieldIsValid,
		senha.fieldIsValid
		// numeroDaCasa.fieldIsValid
	])

	const memoizedFetchStates = useMemo(() => fetchStates, [])

	useEffect(() => {
		const cleanup = initState()
		return cleanup
	}, [memoizedFetchStates])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getData, [cep.fieldIsValid])
	useEffect(initCity, [stateSelected?.value])
	useEffect(getAdhesionSTerm, [cpf.fieldIsValid])

	function getAdhesionSTerm() {
		;(async () => {
			try {
				setLoading(true)
				const termsOfAdhesion = await getTermOfAdhesion()
				setTermOfAdhesion(JSON.parse(termsOfAdhesion.Dados))
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	function allFieldsIsValid() {
		return (
			nome.fieldIsValid &&
			cpf.fieldIsValid &&
			dataDeNascimento.fieldIsValid &&
			celular.fieldIsValid &&
			email.fieldIsValid &&
			// numeroDaCasa.fieldIsValid &&
			(participant?.Id ? true : senha.fieldIsValid)
		)
	}

	function getData() {
		;(async () => {
			try {
				if (!cep.fieldIsValid) return

				const jsonResult = await getAddress(cep.value.replace('-', ''))

				if (!jsonResult || jsonResult.erro) {
					rua.clearValue()
					bairro.clearValue()
					return
				}

				rua.setValue(jsonResult.logradouro)
				setCitySelected({
					label: jsonResult.localidade,
					value: jsonResult.localidade
				})
				setStateSelected({
					label: jsonResult.uf,
					value: jsonResult.uf
				})
				bairro.setValue(jsonResult.bairro)
			} catch (error) {
				console.log({ error: error })
			}
		})()
	}

	function initState() {
		;(async () => {
			try {
				const result = await fetchStates()
				if (!result?.length) return
				const newstate: ISelectOption[] = result.map((x) => {
					return {
						label: x.sigla,
						value: String(x.id)
					}
				})

				setStateOptions(newstate)
			} catch (error) {}
		})()
	}

	function initCity() {
		;(async () => {
			if (!stateSelected?.value) return

			const result = await fetchCities(stateSelected.value)
			if (!result?.length) return
			const newcities: ISelectOption[] = result.map((x) => {
				return {
					label: x.nome,
					value: String(x.nome)
				}
			})

			setCityOptions(newcities)
		})()
	}

	function handleCity(value: ISelectOption) {
		setCitySelected(value)
	}

	function handleState(value: ISelectOption) {
		setStateSelected(value)
		setCitySelected(null)
	}

	function onClose() {
		setShowTerm(false)
		setAcceitTerm(false)
	}

	async function handleSubmit(e?: any) {
		e.preventDefault()
		try {
			// if (!allFieldsIsValid()) {
			// 	return
			// }

			if (!user.Id && !accetTerm) {
				setShowTerm((show) => !show)
				return
			}

			setLoading(true)
			let address: IEndereco | null = null

			if (cep.value) {
				const getGeocode = await returnGeoCode(
					concatAddress(rua.value, stateSelected?.value || '')
				)
				address = {
					Bairro: bairro.value,
					Cep: cep.value.replace('-', ''),
					Cidade: citySelected?.value || '',
					Estado: stateSelected?.value || '',
					Logradouro: rua.value,
					Complemento: '',
					Numero: numeroDaCasa.value,
					Longitude: getGeocode.longitude,
					Latitude: getGeocode.latitude,
				}
			}

			if (participant?.Id) {
				const payload: IRequestUpdate = {
					Nome: nome.value,
					DataDeNascimento: formatBirthDate(dataDeNascimento.value),
					Celular: cleanPhoneNumber(celular.value),
					Apelido: apelido.value,
					Endereco: address,
					PermiteWhatsapp: checkboxWhatsApp,
					Email: email.value
				}
				await updateParticipant(participant?.Id, payload)
				toasterSuccess('Cadastro editado com sucesso!')
			} else {
				await createParticipant({
					Nome: nome.value || '',
					Email: email.value,
					DataDeNascimento: formatBirthDate(dataDeNascimento.value),
					Senha: senha.value,
					Celular: cleanPhoneNumber(celular.value),
					Cpf: cleanDocumentNumber(cpf.value),
					Endereco: address,
					PermiteWhatsapp: checkboxWhatsApp
				})
				toasterSuccess('Cadastro realizado com sucesso!')
			}

			navigate(-1)
		} catch (error: any) {
			toasterError(
				error?.response.data.Mensagem
					? error?.response.data.Mensagem
					: participant?.Id
					? 'Edição não realizado'
					: 'Cadastro não realizado'
			)
		} finally {
			setLoading(false)
		}
	}

	function handleCheckbox() {
		setcheckboxWhatsApp((checked) => !checked)
	}

	return (
		<S.Container className={classAnimated}>
			{user.NivelDeAcesso ? null : <HeaderNotLogged />}
			<div className='container-middle'>
				<GoBack />

				<form onSubmit={handleSubmit}>
					<div className='form-data'>
						<h1>Dados do participante</h1>

						<div>
							<InputPrimary
								{...nome}
								name='name'
								id='name'
								label='Nome Completo'
							/>

							<InputPrimary {...email} name='email' id='email' label='E-mail' />

							<div className='flex gap'>
								<InputPrimary
									{...cpf}
									name='cpf'
									id='cpf'
									label='CPF'
									className='w50'
								/>

								<InputPrimary
									{...dataDeNascimento}
									name='dataDeNascimento'
									id='dataDeNascimento'
									label='Data De Nascimento'
									className='w50'
								/>
							</div>
							<div className='flex gap'>
								<InputPrimary
									{...celular}
									name='celular'
									id='celular'
									label='Celular'
									className='w50'
								/>

								<InputPrimary
									{...apelido}
									name='apelido'
									id='apelido'
									label='Apelido'
									className='w50'
								/>
							</div>
							<div className='address'>
								<h1>Dados do Endereço (Opcional)</h1>

								<div className='flex gap'>
									<InputPrimary
										{...cep}
										name='cep'
										id='cep'
										label='CEP'
										className='w60'
									/>
									<InputPrimary
										{...numeroDaCasa}
										name='numeroDaCasa'
										id='numeroDaCasa'
										label='Numero'
										className='w40'
									/>
								</div>

								<InputPrimary {...rua} name='rua' id='rua' label='Endereço' />

								<InputPrimary
									{...bairro}
									name='bairro'
									id='bairro'
									label='Bairro'
								/>

								<div className='flex gap'>
									<SelectPrimary
										label='Estado'
										name='state'
										options={stateOptions}
										value={stateSelected}
										onChange={handleState}
										className='select-city w40'
										placeholder='Estado'
										id='state'
									/>

									<SelectPrimary
										label='Cidade'
										name='city'
										options={cityOptions}
										value={citySelected}
										className='select-state w60'
										onChange={handleCity}
										placeholder='Cidade'
										id='city'
									/>
								</div>
							</div>
							<FormControlLabel
								control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
								label='Permiti receber Whatsapp?'
								checked={checkboxWhatsApp}
								onChange={handleCheckbox}
							/>
							{user && participant?.Id ? null : (
								<div className='password-wrapper'>
									<h1 style={{ marginTop: 24 }}>Agora, cadastre a senha</h1>
									<InputPassword
										{...senha}
										name='senha'
										id='senha'
										label='Senha'
									/>

									<span className='info-msg'>
										A SENHA DEVE CONTER NO MÍNIMO{' '}
										<span className='rosa'> 8 CARACTERES </span>E AO MENOS
										<span className='rosa'>
											{' '}
											UM CARACTERE MINÚSCULO, MAIÚSCULO E NUMÉRICO.
										</span>
									</span>
								</div>
							)}
						</div>
					</div>
					{participant ? (
						renderButtonsAction()
					) : (
						<div className='btn-submit'>
							<Button
								name='Finalizar cadastro'
								onSubmit={(e) => handleSubmit(e)}
								disabled={disabled}
								loading={loading}
								type={'submit'}
							/>
						</div>
					)}
				</form>
			</div>
			{user.NivelDeAcesso ? null : <FooterNotLogged />}

			{isAdmin() && renderModal()}

			{showTerm ? (
				<Modal onClose={onClose} title='Termo de Adesão' isActive={true}>
					<div style={{ width: 400 }}>
						{loading ? (
							<div className='checkbox'>
								<Loading />
							</div>
						) : (
							<Editor
								returnText={(text) => setTermOfAdhesion(text)}
								textInit={termOfAdhesion}
								readOnly={true}
							/>
						)}

						<div className='checkbox'>
							<FormControlLabel
								value={accetTerm}
								control={
									<Radio
										sx={{
											color: pink[100],
											'&.Mui-checked': {
												color: pink[100]
											},
											'& .MuiSvgIcon-root': {
												fontSize: 20
											},
											'& .MuiFormControlLabel-root, .MuiFormControlLabel-label':
												{
													fontSize: 12
												}
										}}
									/>
								}
								label={'Aceitar'}
								className='checkbox-radio'
								onChange={() => setAcceitTerm((value) => !value)}
							/>

							<Button
								name='Aceitar e Fechar'
								onClick={handleSubmit}
								disabled={!accetTerm}
								loading={loading}
								type='submit'
								id='teste'
							/>
						</div>
					</div>
				</Modal>
			) : null}
		</S.Container>
	)
}
