import { store } from 'shared/store'

import { Creators as TokenActions } from '../store/ducks/token'
import { Creators as UserActions } from '../store/ducks/user'
import { Creators as PartnerActions } from '../store/ducks/partner'

export function logout() {
	store.dispatch(TokenActions.resetToken())
	store.dispatch(UserActions.resetUser())
	store.dispatch(PartnerActions.resetPartner())
}
