import { toast } from "react-toastify";

export function toasterSuccess(message: string) {
  toast.success(message);
}

export function toasterError(message: string) {
  toast.error(message);
}

export function toasterPromise() {
  const functionThatReturnPromise = () =>
    new Promise((resolve) => setTimeout(resolve, 3000));
  toast.promise(functionThatReturnPromise, {
    pending: "Aguarde...",
  });
}
