import styled from "styled-components";
import { mediaQuery } from "../../styles/functions";

export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  height: 144px;
  border-top: 1px solid #e0e0e0;
  background: #fff;

  button {
    background: #fff;
    border: none;
    color: #979e97;
    padding: 10px 20px;
    font-size: 10px;
    font-family: "Roboto";
    border-radius: 4px;
    width: 100px;
    cursor: pointer;

    &:hover {
      background: #e0e0e0;
    }
  }

  .img-logo {
    width: 100px;
  }

  ${mediaQuery("sm")(`
        padding: 0 24px;
    `)}
`;
