import styled from "styled-components";
import { skeleton } from "../../styles/functions";

export const WrapperSkeleto = styled.div`
  display: grid;
`;

export const ImgSkeleto = styled.img`
  display: block;
  max-width: 100%;
  grid-area: 1/1;
  opacity: 0;
  transition: 0.2s;
`;

export const Skeleto = styled.div`
  grid-area: 1/1;
  height: 100%;
  background-image: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
  background-color: #eee;
  background-size: 200%;
  animation: ${skeleton} 1.5s infinite linear;
`;
