import styled from 'styled-components'

export const BadgeWrapper = styled.div<{ status: string }>`
	border-radius: 30px;
	width: 50px;
	padding: 8px;
	text-align: center;
	font-size: 10px;
	${({ status }) =>
		status === 'ativo'
			? `background: #c9e8aa;color: #5ea715;border: 1px solid #5ea715;`
			: status === 'bloqueado'
			? `background: #bcb8b8;color: var(--color-primary);border: 1px solid var(--color-primary);`
			: status === 'cancelado'
			? 'background:rgb(255, 211, 211);color: #e74c3c;border: 1px solid #e74c3c;'
			: 'background: #bcb8b8; color: var(--color-primary);border: 1px solid var(--color-primary)'}
`
