import { mediaQuery } from 'shared/styles/functions'
import styled from 'styled-components'

export const Container = styled.div`
	.tickets {
		margin-top: 40px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}

	.subtitle {
		font-size: 18px;
		font-weight: 300;
		font-family: var(--font-roboto);
		margin: 32px 0;
		color: var(--color-primary);
	}

	.image-modal img {
		width: 200px;
		height: 200px;
		margin: auto;
	}

	.button {
		width: 200px;
		margin: 30px auto;
		justify-content: center;
	}
	.header {
		display: flex;
		justify-content: space-between;
		margin: 16px 0;
		font-family: var(--font-oswald);
		font-size: 18px;
		align-items: center;
	}

	.header-value {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.value-total {
		font-family: var(--font-roboto);
		color: #3d3838;
		margin-top: 8px;
		font-size: 14px;
	}
	.description {
		font-family: var(--font-roboto);
		font-size: 14px;
		margin-bottom: 16px;
	}

	.id-generator {
		text-align: center;
		margin-top: 40px;
		font-weight: bold;
		color: var(--color-rosa);
	}

	.loading-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	${mediaQuery('sm')(`
		.tickets {
			grid-template-columns: 1fr;
		}
	`)}
`
