import styled from 'styled-components'
import { mediaQuery } from 'shared/styles/functions'

export const Container = styled.div`
	height: 100vh;

	cursor: pointer;
	.subtitle {
		font-size: 18px;
		font-weight: 300;
		font-family: var(--font-roboto);
		margin: 32px 0;
		color: var(--color-primary);
	}

	.divisor {
		border-top: 1px solid #e9e9e9;
		margin-top: 40px;
	}
	.box-cash {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}

	.image-modal {
		width: 200px;
		height: 200px;
		margin: auto;
		padding-bottom: 32px;

		img {
			height: 100%;
		}
	}

	.text {
		margin: 16px 0;
		font-family: var(--font-roboto);
		color: #3d3838;
		font-size: 14px;
	}
	strong {
		margin-top: 30px;
	}

	.section {
		width: 300px;
		margin: 40px auto;
	}

	.button {
		text-align: center;
	}

	.pagination {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		.button {
			border: none;
			background: none;
			cursor: pointer;
			padding: 16px;

			color: var(--color-primary);

			&[disabled] {
				color: var(--color-cinza);
			}
		}

		.page-numbers {
			display: flex;
			align-items: center;
			.page-number {
				padding: 8px 10px;
				border-radius: 4px;
				color: var(--color-primary);

				cursor: pointer;
				&.active {
					background-color: var(--color-primary);
					color: white;
				}
			}
		}
	}

	.code {
		color: var(--color-rosa);
		text-align: center;
	}
	${mediaQuery('sm')(`
      .box-cash {
        display: block;
      }
      
  `)}
`

export const LoadingWrapper = styled.div`
	display: flex;
	margin: 50px;
	flex-direction: column;
	align-items: center;
`
