import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'shared/components/ButtonPrimary'
import ModalAction from 'shared/components/ModalAction'
import {
	IModalDeleteTarget,
	TargetTypesEnum
} from 'shared/components/ModalAction/types'

export interface IProps {
	id: string
	nome: string
	handleSubmit: () => void
	type: TargetTypesEnum
	disabled: boolean
	loading: boolean
}
export default function useActionDeleteOrBlock(props: IProps) {
	const { disabled, handleSubmit, id, loading, nome, type } = props
	const navigate = useNavigate()

	const [currentTarget, setCurrentTarget] = useState<IModalDeleteTarget | null>(
		null
	)
	const [target, setTarget] = useState<IModalDeleteTarget | null>(null)
	const [classAnimated, setAnimated] = useState('animated-right')

	function openModalDeleteOrBlock(
		id: string,
		name: string,
		type: 'BLOCK' | 'DELETE'
	) {
		setAnimated('')

		setTarget({
			id,
			name,
			type,
			hasReason: true
		})
	}

	function handleDeleteEnd() {
		setAnimated('')
		setCurrentTarget({
			id,
			name: nome,
		})

		setTarget(null)
		navigate(-1)
	}

	function handleClose() {
		setAnimated('')

		setTarget(null)
		setCurrentTarget(null)
	}

	function renderModal() {
		return (
			<ModalAction
				onClose={handleClose}
				target={target}
				type={type}
				handleDeleteUserEnd={handleDeleteEnd}
			/>
		)
	}
	function renderButtonsAction() {
		return (
			<div className='action-btn-submit'>
				<button
					className='link block'
					onClick={() => openModalDeleteOrBlock(id, nome, 'BLOCK')}
					type='button'
				>
					Bloquear cadastro
				</button>
				<button
					className='link remove'
					onClick={() => {
						openModalDeleteOrBlock(id || '', nome, 'DELETE')
					}}
					type='button'
				>
					Excluir cadastro
				</button>

				<Button
					name='Editar cadastro'
					type='submit'
					onClick={handleSubmit}
					disabled={disabled}
					loading={loading}
				/>
			</div>
		)
	}

	return {
		renderButtonsAction,
		renderModal,
		classAnimated,
		currentTarget
	}
}
