import { ChangeEvent, FocusEvent } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Container, ErrorMessage, LoadingWrapper } from './styles'
import Loading, { LoadingEnum } from '../Loading'
import 'react-tooltip/dist/react-tooltip.css'

export interface InputProps {
	name?: string
	label?: string
	type?: string
	id: string
	value?: any
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void
	erro?: string
	placeholder?: string
	ref?: any
	loading?: boolean
	className?: string
	disabled?: boolean
	Icon?: string
	maxLenght?: number
	tooltip?: string
	inputMode?:
		| 'search'
		| 'text'
		| 'email'
		| 'tel'
		| 'url'
		| 'numeric'
		| 'none'
		| 'decimal'
		| undefined
}

function InputPrimary({
	name,
	label,
	type,
	onChange,
	onBlur,
	value,
	erro,
	id,
	loading,
	className,
	disabled,
	Icon,
	maxLenght,
	tooltip,
	inputMode,
	...rest
}: InputProps) {
	return (
		<div className={className}>
			<Container>
				<input
					id={id}
					name={name}
					type={type}
					disabled={disabled}
					value={value}
					onChange={onChange}
					style={Icon || loading ? { paddingRight: 35 } : undefined}
					onBlur={onBlur}
					maxLength={maxLenght}
					// autoComplete='off'
					inputMode={inputMode}
					{...rest}
				/>
				<label htmlFor={id}>{label}</label>
				{loading ? (
					<LoadingWrapper>
						<Loading type={LoadingEnum.spinner} size={10} />
					</LoadingWrapper>
				) : null}
				{Icon && !loading ? (
					<>
						<img
							src={Icon}
							alt='Icone'
							className='icon'
							id={id + 'tooltip'}
							data-tooltip-variant='error'
						/>
					</>
				) : null}
			</Container>
			{erro && <ErrorMessage>{erro}</ErrorMessage>}
			{tooltip && (
				<ReactTooltip
					data-tooltip-id='my-tooltip'
					content={tooltip}
					anchorSelect={`#${id + 'tooltip'}`}
					variant='error'
				/>
			)}
		</div>
	)
}

export default InputPrimary
