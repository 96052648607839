export async function returnGeoCode(address: string) {
	const url = `https://nominatim.openstreetmap.org/search?q=${address}&format=json`

	const response = await fetch(url)
	const data = await response.json()

	if (data.length) {
		const lat = data[0].lat
		const lng = data[0].lon
		console.log(`Latitude: ${lat}, Longitude: ${lng}`)
		return { latitude: lat, longitude: lng }
	} else {
		console.log(
			`Não foi possível encontrar as coordenadas para o endereço ${address}`
		)
		return { latitude: 0, longitude: 0 }
	}
}

export function concatAddress(address: string, region: string) {
	return address
		.concat('-' + region)
		.toLowerCase()
		.replaceAll(' ', '-')
}
