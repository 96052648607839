import {
	UseInfiniteQueryResult,
	UseQueryResult,
	useInfiniteQuery,
	useQuery
} from '@tanstack/react-query'
import { IDashboard, IReportTotal } from 'shared/interfaces/dashboard'
import { IResponse } from 'shared/interfaces/response'
import axiosInstance from 'shared/utils/env'
import { QUERY_KEYS } from 'shared/utils/query'

export interface IRequestDashboard {
	DataInicio: string
	DataFim: string
	cnpj?: string
}

export async function getDashboard(
	payload: IRequestDashboard
): Promise<IResponse<IDashboard>> {
	const { DataFim, DataInicio } = payload
	const response = await axiosInstance.get(
		`/Dashboard?dataInicio=${DataInicio}&dataFim=${DataFim}`
	)

	return response.data
}

export function useGetDashboard({
	DataFim,
	DataInicio
}: IRequestDashboard): UseInfiniteQueryResult<IResponse<IDashboard>> {
	const enabled = !!(DataFim && DataInicio)

	return useInfiniteQuery(
		[QUERY_KEYS.DASHBOARD, { DataFim, DataInicio }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { DataFim, DataInicio } = params as IRequestDashboard

			return getDashboard({
				DataFim,
				DataInicio
			})
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			retry: false
		}
	)
}

export async function getDashboardOperator(
	payload: IRequestDashboard
): Promise<IResponse<IReportTotal>> {
	const { DataFim, DataInicio, cnpj } = payload
	const response = await axiosInstance.get(
		`/Dashboard/${cnpj}?dataInicio=${DataInicio}&dataFim=${DataFim}`
	)

	return response.data
}

export function useGetDashboardOperator({
	DataFim,
	DataInicio,
	cnpj
}: IRequestDashboard): UseQueryResult<IResponse<IReportTotal>> {
	const enabled = !!(DataFim && DataInicio)

	return useQuery(
		[QUERY_KEYS.DASHBOARD, { DataFim, DataInicio, cnpj }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { DataFim, DataInicio, cnpj } = params as IRequestDashboard

			return getDashboardOperator({
				DataFim,
				DataInicio,
				cnpj
			})
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			retry: false
		}
	)
}
