import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { Container } from './styles'
import Table from 'shared/components/Table'
import { ITableColumn, ITableRow, ITableNavProps, ICupomPage } from './types'
import { tableStyle } from 'shared/styles/functions'
import HeaderPage from 'shared/components/HeaderPage'
import IconEdit from 'assets/images/edit-participant.svg'
import IconGroups from 'assets/images/groups.svg'
import IconTrash from 'assets/images/delete.svg'
import IconBlocked from 'assets/images/block.svg'
import { RouteEnumsAdmin } from '../routes/enums'
import ModalAction from 'shared/components/ModalAction'
import { IModalDeleteTarget } from 'shared/components/ModalAction/types'
import {
	IResponsePaginated,
	getCupomPaginatedByAdmin,
	getCupomPaginatedByOperator
} from 'shared/services/cupom.service'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { toasterError } from 'shared/utils/toaster'
import { formatDate } from 'shared/utils/date'
import { isAdmin, isOperator } from 'shared/utils/isAdmin'
import BadgeStatus from 'shared/components/BadgeStatus'

function Cupons() {
	const ITEMS_PER_PAGE = 10
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [cuponsPage, setCuponsPage] = useState<ICupomPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [currentTarget, setCurrentTarget] = useState<IModalDeleteTarget | null>(
		null
	)
	const [target, setTarget] = useState<IModalDeleteTarget | null>(null)
	const navigate = useNavigate()
	const [classAnimated, setAnimated] = useState('animated-right')

	const { user, partner } = useTypedSelector(['user', 'partner'])
	const [loading, setLoading] = useState(false)

	function handleSearch(value: string): void {
		setSearch(value)
	}

	useEffect(processTable, [searchDebounce, cuponsPage.page])

	function processTable() {
		;(async () => {
			try {
				setLoading(true)

				const fetchCupons = isAdmin()
					? getCupomPaginatedByAdmin
					: getCupomPaginatedByOperator

				const params = {
					limite: ITEMS_PER_PAGE,
					page: cuponsPage.page,
					search: searchDebounce
				}

				const response = isAdmin()
					? await fetchCupons(params, '')
					: await fetchCupons(params, partner?.Id || '')

				const data = response.Dados.Itens

				const rows = data.map((item) => {
					return {
						image: renderImage(item.Imagem),
						icon: renderIcon(item.Id || ''),
						status: renderStatus(
							item.Status,
							item?.Motivo || '',
							item?.Id || item.ParceiroId
						),
						titulo: item.Titulo,
						parceiro: item.Parceiro || '-',
						vencimento: formatDate(new Date(item.DataDeValidade)),
						qtdParticipantes: String(item.QtdParticipantes || '-'),
						edit: renderEdit(item)
					}
				})

				setTableRows(rows)
				setCuponsPage({
					numberOfPages: response.Dados.NumeroTotalDePaginas,
					page: cuponsPage.page,
					totalDocs: response.Dados.NumeroTotalDeItens
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Cupons'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function navigationToDetails(id: string) {
		navigate(RouteEnumsAdmin.CUPOM_PARTICIPANTS_DETAILS, {
			state: { id: id }
		})
	}

	function goToNewCupom() {
		navigate(RouteEnumsAdmin.CREATE_CUPOM)
	}

	function handleDeleteEnd() {
		setCurrentTarget({
			id: currentTarget?.id || '',
			name: currentTarget?.name || ''
		})

		setTarget(null)
		processTable()
	}

	function handleClose() {
		setTarget(null)
		setCurrentTarget(null)
	}

	function renderStatus(status: string, motivo: string, id: string) {
		return <BadgeStatus status={status} tooltip={motivo} id={id} />
	}

	function renderIcon(id: string) {
		return (
			<button className='image-icon' onClick={() => navigationToDetails(id)}>
				<img src={IconGroups} alt={'Icon Group'} />
			</button>
		)
	}

	function renderImage(image: string) {
		return (
			<div className='image-voucher'>
				<img src={image} alt={'Cupom'} />
			</div>
		)
	}

	function openModalDeleteOrBlock(
		id: string,
		name: string,
		type: 'BLOCK' | 'DELETE'
	) {
		setAnimated('')

		setTarget({
			id,
			name,
			type,
			hasReason: true
		})


	}

	function goToEditCupom(cupom: IResponsePaginated) {
		navigate(RouteEnumsAdmin.CREATE_CUPOM, {
			state: { cupom: cupom }
		})

		// navigate(RouteEnumsAdmin.EDIT_PARTNER, {
		//   state: { partner },
		// });
	}

	function renderEdit(cupom: IResponsePaginated) {
		return (
			<div className='flex'>
				<button className='edit' onClick={() => goToEditCupom(cupom)}>
					<img src={IconEdit} alt='Edit' />
				</button>

				<button
					className='remove'
					onClick={() =>
						openModalDeleteOrBlock(cupom.Id || '', cupom.Descricao, 'DELETE')
					}
				>
					<img src={IconTrash} alt='Deletar Parceiro' />
				</button>
			</div>
		)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Imagem', accessor: 'image' },
		{ Header: 'Participantes', accessor: 'icon' },
		{ Header: 'Status', accessor: 'status' },
		{ Header: 'Titulo', accessor: 'titulo', sortType: 'basic' },
		isOperator()
			? { Header: '', accessor: 'parceiro' }
			: { Header: 'Parceiro', accessor: 'parceiro', sortType: 'basic' },
		{
			Header: 'Vencimento',
			accessor: 'vencimento',
			sortType: 'basic'
		},
		{
			Header: 'Qtd Participantes',
			accessor: 'qtdParticipantes'
		},
		{
			Header: 'Ações',
			accessor: 'edit',
			sortType: 'basic'
		}
	]

	function goToPage(pageIndex: number) {
		setCuponsPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: cuponsPage.numberOfPages,
		pageIndex: cuponsPage.page - 1,
		totalDocs: cuponsPage.totalDocs
	}

	return (
		<Container className={classAnimated}>
			<div>
				<div>
					<HeaderPage
						title='Cupons'
						buttonName='Novo Cupom'
						onClick={goToNewCupom}
						returnSearch={handleSearch}
						placeholder='Pesquisar Cupom'
					/>
				</div>

				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableRows}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Cupom para serem exibidas.'
						{...tableStyle}
						padding={[16, 16, 16, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						isLoading={loading}
					/>
				</div>
			</div>
			<ModalAction
				onClose={handleClose}
				target={target}
				type={'CUPOM'}
				handleDeleteUserEnd={handleDeleteEnd}
			/>
		</Container>
	)
}

export default Cupons
