import axios from "axios";

const getAddress = async (cep: string): Promise<IViaCepReturn | null> => {
  const url = `https://viacep.com.br/ws/${cep}/json`;

  const options = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const res = await axios.get(url, options);

  if (!res) return null;

  const address: IViaCepReturn = res.data;

  return address;
};

export interface IViaCepReturn {
  bairro: string;
  cep: string;
  complemento: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  uf: string;
  erro: boolean
}

export interface ICitiesResponse {
  nome: string;
}

function fetchCities(stateId: string): Promise<ICitiesResponse[] | undefined> {
  let url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`;

  const init = async () => {
    try {
      const res = await axios.get(url);

      return res.data;
    } catch (err) {
      console.error("Erro ao listar as cidades deste estado.");
      return;
    }
  };

  return init();
}

export interface IRegionResponse {
  id: number;
  sigla: string;
}

function fetchStates(): Promise<IRegionResponse[] | undefined> {
  let url = "https://servicodados.ibge.gov.br/api/v1/localidades/estados/";

  const init = async () => {
    try {
      const res = await axios.get(url);

      return res.data;
    } catch (err) {
      console.error("Erro ao listar os estados do Brasil.");
    }
  };
  return init();
}

export { getAddress, fetchCities, fetchStates };
