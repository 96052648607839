import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { startOfMonth } from 'date-fns'

import { Container } from './styles'
import Table from 'shared/components/Table'
import { ITableColumn, ITableRow, ITableNavProps, ICupomPage } from './types'
import { tableStyle } from 'shared/styles/functions'
import HeaderPage from 'shared/components/HeaderPage'
import {
	FORMAT_BIRTH_BIRTH_DATE,
	formatCnpjCpf,
	formatDateToUS,
	toMoney
} from 'shared/utils/format'
import { getCashback } from 'shared/services/releases.service'
import { formatDate } from 'shared/utils/date'
import { toasterError } from 'shared/utils/toaster'
import ModalLaunchCashback from 'shared/components/ModalLaunchCashback'
import { ISelectOption } from 'shared/interfaces/options'
import { IFromTo } from 'shared/interfaces/common'

function Releases() {
	const ITEMS_PER_PAGE = 10

	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [releasesPage, setReleasesPage] = useState<ICupomPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfMonth(new Date()),
		to: new Date()
	})

	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [classAnimated, setAnimated] = useState('animated-right')
	const [loading, setLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalEdit, setModalEdit] = useState<ISelectOption | null>(null)

	function toggleModal() {
		setAnimated('')
		setShowModal((show) => !show)
		setModalEdit(null)
		processTable()
	}

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleSetDates(value: IFromTo) {
		setSelectedDates(value)
	}

	useEffect(processTable, [
		searchDebounce,
		releasesPage.page,
		selectedDates.from,
		selectedDates.to
	])

	useEffect(() => {
		if (!modalEdit?.label) return

		setAnimated('')
		setShowModal(true)
	}, [modalEdit?.label])

	function processTable() {
		;(async () => {
			try {
				setLoading(true)

				const params = {
					limite: ITEMS_PER_PAGE,
					page: releasesPage.page,
					search: searchDebounce,
					dateInit: selectedDates.from
						? formatDateToUS(selectedDates.from, FORMAT_BIRTH_BIRTH_DATE)
						: '',
					dateFinal: selectedDates.to
						? formatDateToUS(selectedDates.to, FORMAT_BIRTH_BIRTH_DATE)
						: ''
				}

				const response = await getCashback(params)

				const data = response.Dados.Itens

				const rows = data.map((item) => {
					return {
						preco: toMoney(item.Valor),
						parceiro: item.RazaoSocial,
						participant: item.NomeDoParticipante,
						cpf: formatCnpjCpf(item.Cpf),
						dataLancamento: formatDate(new Date(item.DataHoraCadastro))
					}
				})

				setTableRows(rows)
				setReleasesPage({
					numberOfPages: response.Dados.NumeroTotalDePaginas || 1,
					page: releasesPage.page || 1,
					totalDocs: response.Dados.NumeroTotalDeItens
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Lançamentos'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Parceiro', accessor: 'parceiro', sortType: 'basic' },
		{ Header: 'Participante', accessor: 'participant', sortType: 'basic' },
		{ Header: 'CPF Participante', accessor: 'cpf', sortType: 'basic' },
		{
			Header: 'Preço',
			accessor: 'preco',
			sortType: 'basic'
		},
		{
			Header: 'Data de Lançamento',
			accessor: 'dataLancamento',
			sortType: 'basic'
		}
	]

	function goToPage(pageIndex: number) {
		setReleasesPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: releasesPage.numberOfPages,
		pageIndex: releasesPage.page - 1,
		totalDocs: releasesPage.totalDocs
	}

	return (
		<Container className={classAnimated}>
			<div>
				<div>
					<HeaderPage
						title='Lançamentos'
						placeholder='Pesquisar'
						buttonName='Novo lançamento'
						onClick={toggleModal}
						returnSearch={handleSearch}
						id='realeases'
						handleSetDates={handleSetDates}
						selectedDates={selectedDates}
					/>
				</div>

				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableRows}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Lançamento para serem exibidas.'
						{...tableStyle}
						padding={[16, 16, 16, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						isLoading={loading}
					/>
				</div>
			</div>

			<ModalLaunchCashback
				onClose={toggleModal}
				showModal={showModal}
				cpfParticipant={modalEdit?.label || undefined}
				value={Number(modalEdit?.value) || undefined}
			/>
		</Container>
	)
}

export default Releases
