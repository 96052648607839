export const APP_TEXT = {
	CASHBACK_TITLE_FIRST_MODAL_RESCUE: 'RESGATE DE VOUCHER CASHBACK',
	CASHBACK_TITLE_MODAL_RESCUE:
		'Temos uma notícia empolgante para você! Chegou a hora de desfrutar dos benefícios do nosso programa de recompensas. É com grande prazer que informamos que o seu voucher cashback está pronto para ser resgatado.',
	CASHBACK_SUBTITLE_MODAL_RESCUE:
		'Aqui no Club Digital Cash valorizamos a sua lealdade e queremos retribuir o seu apoio de uma maneira especial. Agora, você pode resgatar seu voucher cashback e utilizar imediatamente no estabelecimento parceiro, tornando sua próxima compra ainda mais gratificante.',
	CASHBACK_THIRD_TITLE_MODAL_RESCUE: 'Resgate agora!!!',
	CASHBACK_TITLE_FIRST_MODAL: 'VOUCHER CASHBACK GERADO',
	CASHBACK_TITLE_MODAL:
		'Temos uma ótima notícia para você! É com grande satisfação que informamos que o seu voucher cashback foi gerado com sucesso e está pronto para ser utilizado em nosso parceiro.',
	CASHBACK_SUBTITLE_MODAL_MODAL:
		'Aqui no Club Digital Cash, priorizamos a gratidão pelos nossos clientes fiéis e nosso programa de recompensas é a maneira de retribuir seu apoio contínuo de uma forma especial.',
	CASHBACK_THIRD_TITLE_MODAL:
		'Não perca tempo, vá no estabelecimento parceiro e comece a desfrutar do seu voucher Cashback hoje mesmo! '
}
