import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IPartnerActionCreators
} from '../../interfaces/action'
import { IPartner } from 'shared/interfaces/partner'
import { Status } from 'shared/interfaces/status'

/**
 * Action types & creators
 */
export const { Types, Creators }: IActionsFromCreators<IPartnerActionCreators> =
	createActions({
		setPartner: ['partner'],
		resetPartner: []
	})

/**
 * Handlers
 */

export const INITIAL_STATE: IPartner = {
	Id: '',
	LogoMarca: '',
	NomeFantasia: '',
	RazaoSocial: '',
	Endereco: {
		Bairro: '',
		Cep: '',
		Cidade: '',
		Estado: '',
		Logradouro: '',
		Complemento: ''
	},
	Telefone: '',
	Celular: '',
	Email: '',
	Cnpj: '',
	RamoDeAtividade: '',
	CpfOperador: '',
	PorcentagemDeCashback: 0,
	ValorCashbackAutomatico: 0,
	DataHoraCadastro: '',
	Ativo: false,
	QuantidadeDeDiasVencimento: 0,
	QuantidadeMaximaDeCupons: 0,
	Status: Status.Ativo
}

const set = (state = INITIAL_STATE, action: any) => action.partner
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<IPartner, Action<IPartnerActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_PARTNER]: set,
		[Types.RESET_PARTNER]: reset
	}
)
