import axiosInstance, { URI } from '../utils/env'
import { IUser, NivelDeAcessoEnum } from '../interfaces/user'
import { IResponse } from 'shared/interfaces/response'
import { IPartner } from 'shared/interfaces/partner'
import { IActivity } from 'shared/interfaces/activity'

export interface IRequestSignIn {
	Cpf: string
	Senha: string
}

export interface IResponseSignIn {
	NivelDeAcesso: NivelDeAcessoEnum
	Token: string
	Usuario: IUser
	ParceirosQueEuTrabalho?: IPartner[]
}

export async function signIn(
	props: IRequestSignIn
): Promise<IResponse<IResponseSignIn>> {
	const response = await axiosInstance.post(`${URI}/auth/login`, props, {
		headers: {
			'Access-Control-Allow-Origin': '*'
		}
	})

	return response.data
}

export async function recoverPassword(cpf: string): Promise<IResponse<void>> {
	const response = await axiosInstance.post(
		`/conta/solicitar-troca-senha/${cpf}`
	)

	return response.data
}

export async function requestNewPasswordPassword(
	payload: IRequestChangePassword
): Promise<void> {
	const response = await axiosInstance.post(
		`/conta/troca-senha/${payload.userId}`,
		{
			SenhaAtual: payload.SenhaAtual,
			NovaSenha: payload.NovaSenha
		}
	)

	return response.data
}

export async function changePassword(
	requestId: string,
	password: string
): Promise<IResponse<void>> {
	const response = await axiosInstance.post(
		`/conta/definir-nova-senha/${requestId}`,
		{
			NovaSenha: password
		}
	)

	return response.data
}

export interface IGetInfoCompany {
	NiveDeAcesso: NivelDeAcessoEnum
	Parceiros: IPartner[]
}

export interface IResponseToken {
	Token: string
}
export async function updateToken(): Promise<IResponse<IResponseToken>> {
	const response = await axiosInstance.post(`/auth/token-participante`)

	return response.data
}

export interface IResponseActivity {
	RamosDeatividades: IActivity[]
}

export async function getAllActivity(): Promise<IResponseActivity> {
	const response = await axiosInstance.get(`/suporte`)

	return response.data
}

export interface IRequestChangePassword {
	SenhaAtual: string
	NovaSenha: string
	userId: string
}

export async function changeMyPassword(
	payload: IRequestChangePassword
): Promise<void> {
	const response = await axiosInstance.post(
		`/conta/troca-senha/${payload.userId}`,
		{
			SenhaAtual: payload.SenhaAtual,
			NovaSenha: payload.NovaSenha
		}
	)

	return response.data
}
