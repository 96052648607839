import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Container, LoadingWrapper } from './styles'
import Placeholder from 'assets/images/no-ticket.png'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import CashBackCard from 'shared/components/CashBackCard'
import Modal from 'shared/components/Modal'
import { Button } from '@mui/material'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { currencyStringToNumber, decimalFormater } from 'shared/utils/format'
import { formatDate } from 'shared/utils/date'
import { Creators as UserActions } from 'shared/store/ducks/user'
import useForm from 'shared/hooks/useForm'
import { getMyWallets } from 'shared/services/participant.service'
import {
	getCashbackAvailablePaginatedByParticipant,
	sacarCashback,
	useGetCashbackAvailablePaginatedByParticipant,
	useGetSaldoPaginated
} from 'shared/services/cashback.service'
import { toasterError, toasterSuccess } from 'shared/utils/toaster'
import Loading from 'shared/components/Loading'
import {
	CashGenerateTypeEnum,
	CashTypeEnum,
	ICashback,
	StatusCupomEnum
} from 'shared/interfaces/cashback'
import InputPrimary from 'shared/components/InputPrimary'
import { formatPagination, getVisiblePages } from 'shared/utils/pagination'
import { IWallet } from 'shared/interfaces/wallet'
import { APP_TEXT } from 'shared/utils/app_text'

export interface ICash {
	id: string
	carteiraID: string
	img: string
	valor: number
	parceiro: string
	vencimento?: string
	porcentagem?: number
	isRescue?: boolean
}

export interface IVoucherPage {
	page: number
	numberOfPages: number
	totalDocs: number
	hasNextPage: boolean
	hasPreviousPage: boolean
}

function Voucher() {
	const ITEMS_PER_PAGE = 9
	const [showModal, setShowModal] = useState(false)
	const [cashbackDetail, setCashbackdetail] = useState<ICashback | undefined>()
	const [classAnimated, setClassAnimated] = useState('animated-right')
	const [voucherAvailable, setVoucherAvailable] = useState<ICashback[]>([])
	const [voucherAResgatar, setVoucherAResgatar] = useState<IWallet[]>([])
	const [disabled, setDisabled] = useState(true)
	const [loading, setLoading] = useState(false)
	const value = useForm('valorMonetarioCustomValidator', undefined)
	const [isRescue, setIsRescue] = useState(false)

	const [myVouchersPage, setMyVouchersPage] = useState<IVoucherPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
		hasNextPage: false,
		hasPreviousPage: false
	})

	const [vouchersPage, setVouchersPage] = useState<IVoucherPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
		hasNextPage: false,
		hasPreviousPage: false
	})

	const {
		data: cashAvailableData,
		isLoading: cashAvailableLoading,
		refetch: cashRefetch
	} = useGetCashbackAvailablePaginatedByParticipant({
		limite: ITEMS_PER_PAGE,
		page: vouchersPage.page,
		search: ''
	})

	const {
		data: walletData,
		isLoading: loadingMyCashBack,
		refetch: walletRefetch
	} = useGetSaldoPaginated({
		limite: ITEMS_PER_PAGE,
		page: myVouchersPage.page,
		search: ''
	})

	const itens = useMemo(
		() =>
			cashAvailableData?.pages
				.map((page) => page.Dados.Itens)
				.flat()
				.filter(Boolean) || [],
		[cashAvailableData]
	)
	const dados = useMemo(
		() => cashAvailableData?.pages.map((page) => page.Dados).flat() || [],
		[cashAvailableData]
	)

	const walletItens = useMemo(
		() =>
			walletData?.pages
				.map((page) => page.Dados.Itens)
				.flat()
				.filter(Boolean) || [],
		[walletData]
	)
	const walletDados = useMemo(
		() => walletData?.pages.map((page) => page.Dados).flat() || [],
		[walletData]
	)

	const { user } = useTypedSelector(['user'])

	const dispatch = useDispatch()

	function openModal(cashback: ICashback, isRescue = false) {
		setIsRescue(isRescue)
		setShowModal((show) => !show)
		setClassAnimated('')
		setCashbackdetail(cashback)
	}

	useEffect(processTableVoucherAvailable, [cashAvailableData])
	useEffect(processTableMyVoucher, [walletData])

	function processTableVoucherAvailable() {
		if (!itens.length) {
			setVoucherAvailable([])
			return
		}
		setVoucherAvailable(itens)
		setVouchersPage({
			numberOfPages: dados.length ? dados[0].NumeroTotalDePaginas : 1,
			page: vouchersPage.page || 1,
			totalDocs: dados.length ? dados[0].NumeroTotalDeItens : itens.length,
			hasNextPage: dados.length ? dados[0].TemProximaPagina : false,
			hasPreviousPage: dados.length ? dados[0].TemPaginaAnterior : false
		})
	}

	function processTableMyVoucher() {
		if (!walletItens.length) {
			setVoucherAResgatar([])
			return
		}
		setVoucherAResgatar(walletItens)
		setMyVouchersPage({
			numberOfPages: walletDados.length
				? walletDados[0].NumeroTotalDePaginas
				: 1,
			page: myVouchersPage.page || 1,
			totalDocs: walletDados.length
				? walletDados[0].NumeroTotalDeItens
				: itens.length,
			hasNextPage: walletDados.length ? walletDados[0].TemProximaPagina : false,
			hasPreviousPage: walletDados.length
				? walletDados[0].TemPaginaAnterior
				: false
		})
	}

	function onClose() {
		value.clearValue()
		setCashbackdetail(undefined)
		setShowModal((show) => !show)
		setIsRescue(false)
	}

	function validador(event: string) {
		if (!cashbackDetail?.Valor) return false

		const number = currencyStringToNumber(event)
		if (number < 0.5 || number > cashbackDetail.Valor) {
			return false
		}

		const isValid = value.fieldIsValid

		return isValid
	}

	function valueInitial() {
		if (!cashbackDetail?.Valor) return
		const number = Math.floor(cashbackDetail.Valor * 100) / 100
		const mask = 'R$ ' + decimalFormater(number)
		value.setValue(mask)
	}
	useEffect(valueInitial, [cashbackDetail?.Valor])

	useEffect(() => {
		const isValid = !validador(value.value)
		setDisabled(isValid)
	}, [value.value])

	function resgateVoucher() {
		;(async () => {
			try {
				setLoading(true)
				if (!cashbackDetail?.CarteiraId || !user?.Id) return
				await sacarCashback({
					CarteiraId: cashbackDetail.CarteiraId,
					participanteID: user.Id,
					ValorASacar: currencyStringToNumber(value.value)
				})
				const getWallet = await getMyWallets(user.Id)
				dispatch(
					UserActions.setUser({
						...user,
						MinhasCarteiras: getWallet.Dados.MinhasCarteiras
					})
				)
				toasterSuccess('Voucher resgatado com sucesso!')

				onClose()
				cashRefetch()
				walletRefetch()
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao resgatar voucher'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	const loadMoreVouchers = () => {
		setVouchersPage({
			...vouchersPage,
			page: vouchersPage.page + 1
		})
	}

	const loadMoreMyVouchers = () => {
		setMyVouchersPage({
			...myVouchersPage,
			page: myVouchersPage.page + 1
		})
	}

	const loadPreviousPageMyVouchers = () => {
		setMyVouchersPage({
			...myVouchersPage,
			page: myVouchersPage.page - 1
		})
	}

	const loadPreviousPageVouchers = () => {
		setVouchersPage({
			...vouchersPage,
			page: vouchersPage.page - 1
		})
	}
	function returnPagination(
		nextPage: () => void,
		previosPage: () => void,
		pageConfig: IVoucherPage,
		setState: Dispatch<SetStateAction<IVoucherPage>>
	) {
		return (
			<div className='pagination'>
				<div className='page-numbers'>
					<button
						className='button'
						onClick={previosPage}
						disabled={!pageConfig.hasPreviousPage}
					>
						{'Anterior'}
					</button>

					{getVisiblePages(pageConfig.page + 1, pageConfig.numberOfPages).map(
						(x, index, arr) => {
							return (
								<span
									key={index}
									className={`page-number ${
										x === pageConfig.page ? 'active' : ''
									}`}
									onClick={() => {
										setState({
											...pageConfig,
											page: x - 1
										})
									}}
								>
									{formatPagination(x, index, arr)}
								</span>
							)
						}
					)}

					<button
						className='button'
						onClick={nextPage}
						disabled={!pageConfig.hasNextPage}
					>
						{'Proximo'}
					</button>
				</div>
			</div>
		)
	}

	return (
		<Container className={classAnimated}>
			<h1 className='title'>Voucher Cashback</h1>

			<section>
				<h2 className='subtitle'>
					Economize ainda mais: confira seus vouchers de cashback disponíveis!
				</h2>
				{loadingMyCashBack ? (
					<LoadingWrapper>
						<Loading />
					</LoadingWrapper>
				) : !!voucherAvailable.length ? (
					<div className={'box-cash'}>
						{voucherAvailable.map((cashback, index) => (
							<CashBackCard
								key={index}
								img={cashback.LogoMarca}
								overdue={formatDate(new Date(cashback.Validade), "dd 'de' MMM")}
								partner={cashback.NomeFantasia}
								value={'R$ ' + decimalFormater(cashback.Valor)}
								rescue={false}
								onClickModal={() => openModal(cashback)}
							/>
						))}
					</div>
				) : (
					<DefaultPagePlaceholder
						text='Não há cashback disponivel'
						icon={Placeholder}
					/>
				)}
				{!!voucherAvailable.length &&
					returnPagination(
						loadMoreVouchers,
						loadPreviousPageVouchers,
						vouchersPage,
						setVouchersPage
					)}
			</section>

			<div className='divisor'></div>
			<section>
				<h2 className='subtitle'>Confira os Cash em saldo</h2>
				{cashAvailableLoading ? (
					<LoadingWrapper>
						<Loading />
					</LoadingWrapper>
				) : voucherAResgatar.length > 0 ? (
					<div className={'box-cash'}>
						{voucherAResgatar.map((cashback, index) => (
							<CashBackCard
								key={index}
								img={cashback?.LogoParceiro}
								partner={cashback.NomeFantasia}
								value={'R$ ' + decimalFormater(cashback.Saldo)}
								rescue={cashback.Saldo >= 0.5}
								onClickModal={() =>
									openModal(
										{
											Valor: cashback.Saldo,
											Imagem: cashback.LogoParceiro,
											Cnpj: cashback.Cnpj,
											CarteiraId: cashback.Id,
											LogoMarca: cashback.LogoParceiro,
											RazaoSocial: cashback.NomeFantasia,
											Status: '',
											ComoFoiGerado: CashGenerateTypeEnum.GeradoAutomaticamente,
											Cpf: cashback.Cpf,
											Validade: '',
											DataHoraAlteracao: '',
											DataHoraCadastro: '',
											DataHoraResgate: '',
											Id: '',
											IdDoUsuarioQueAlterou: '',
											JaFoiUtilizado: false,
											NomeDoOperador: '',
											NomeDoParticipante: '',
											NomeFantasia: cashback.NomeFantasia,
											ParceiroId: '',
											ParticipanteId: '',
											StatusCupom: StatusCupomEnum.Ativo,
											TipoVoucher: CashTypeEnum.Cashback,
											UsuarioIdQueLancou: '',
											Codigo: '	'
										},
										true
									)
								}
							/>
						))}
					</div>
				) : (
					<DefaultPagePlaceholder
						text='Não há cashback em saldo'
						icon={Placeholder}
					/>
				)}
				{voucherAResgatar.length > 0 &&
					returnPagination(
						loadMoreMyVouchers,
						loadPreviousPageMyVouchers,
						myVouchersPage,
						setMyVouchersPage
					)}
			</section>

			{cashbackDetail && (
				<Modal onClose={onClose} isActive={showModal}>
					<section>
						<figure className='image-modal'>
							<img
								src={cashbackDetail?.LogoMarca}
								alt={cashbackDetail?.NomeFantasia}
							/>
						</figure>
						<strong className='text'>
							{isRescue
								? APP_TEXT.CASHBACK_TITLE_FIRST_MODAL_RESCUE
								: APP_TEXT.CASHBACK_TITLE_FIRST_MODAL}
						</strong>

						<span>
							<p className='text'>
								{isRescue
									? APP_TEXT.CASHBACK_TITLE_MODAL_RESCUE
									: APP_TEXT.CASHBACK_TITLE_MODAL}
							</p>
							<p className='text'>
								{isRescue
									? APP_TEXT.CASHBACK_SUBTITLE_MODAL_RESCUE
									: APP_TEXT.CASHBACK_SUBTITLE_MODAL_MODAL}
							</p>
							<p className='text'>
								{isRescue
									? APP_TEXT.CASHBACK_THIRD_TITLE_MODAL_RESCUE
									: APP_TEXT.CASHBACK_THIRD_TITLE_MODAL}
							</p>
						</span>

						<div className='section'>
							<CashBackCard
								img={cashbackDetail?.LogoMarca}
								partner={cashbackDetail.NomeFantasia}
								value={'R$ ' + decimalFormater(cashbackDetail.Valor)}
								rescue={false}
							/>
							{isRescue ? (
								<InputPrimary
									{...value}
									erro={
										disabled
											? 'Valor mínimo R$ 0,50 e valor máximo do voucher'
											: ''
									}
									name='valorCashback'
									id='valorCashback'
									label='Valor a Sacar'
									inputMode='numeric'
								/>
							) : null}
						</div>

						{!isRescue && <h2 className='code'>{cashbackDetail?.Codigo}</h2>}
						{isRescue && (
							<div className='button'>
								{loading ? (
									<Loading />
								) : (
									<Button
										size='medium'
										variant='contained'
										color='success'
										onClick={resgateVoucher}
										disabled={disabled}
									>
										Resgatar
									</Button>
								)}
							</div>
						)}
					</section>
				</Modal>
			)}
		</Container>
	)
}

export default Voucher
