import { ChangeEvent, useEffect, useState } from 'react'
import { isFuture, isValid } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'
import { useLocation, useNavigate } from 'react-router-dom'

import Trash from 'assets/images/trash.svg'
import Upload from 'assets/images/upload.svg'
import AlertIcon from 'assets/images/alert-circle-red.svg'
import percent from 'assets/images/percent.svg'

import * as S from './styles'
import GoBack from 'shared/components/GoBack'
import useForm from 'shared/hooks/useForm'
import InputPrimary from 'shared/components/InputPrimary'
import Button from 'shared/components/ButtonPrimary'
import Ticket from 'shared/components/Ticket'
import useSelectPartners from 'shared/hooks/useSelectPartners'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { toasterError } from 'shared/utils/toaster'
import {
	ICreateCupomRequest,
	ICupomType,
	IResponsePaginated,
	createCupom,
	editCupom
} from 'shared/services/cupom.service'
import {
	FORMAT_BIRTH_BIRTH_DATE,
	currencyStringToNumber,
	formatDateToUS
} from 'shared/utils/format'
import { getBase64, removePrefixBase64 } from 'shared/utils/imageToBase64'
import { isAdmin } from 'shared/utils/isAdmin'
import { IMAGE_SIZE } from 'shared/utils/validators'

interface IViewImg {
	raw: any
	preview: string
}

enum ICuponType {
	MONETARIO = 'MONETARIO',
	PORCENTAGEM = 'PORCENTAGEM'
}

interface IRouteProps {
	cupom?: IResponsePaginated
}

function CreateCupon() {
	const route = useLocation() || null
	const state = route.state as IRouteProps

	const { partner } = useTypedSelector(['partner'])
	const navigate = useNavigate()

	const title = useForm('campoTexto')
	const description = useForm('campoTexto')
	const numberOfCupons = useForm('number')

	const [overDate, setOverDate] = useState<Date | null>(null)
	const [img, setImg] = useState<IViewImg | null>(null)
	const [disabled, isDisabled] = useState(true)
	const [selectedValue, setSelectedValue] = useState('PORCENTAGEM')
	const value = useForm(
		selectedValue === ICuponType.PORCENTAGEM
			? 'porcentagemInteiro'
			: 'valorMonetario'
	)
	const [preview, setPreview] = useState(false)

	const { renderSelect, partnertSelected, loading, setLoading, handleSelect } =
		useSelectPartners({
			placeholder: 'Selecione o parceiro',
			isCnpj: true
		})

	useEffect(returnCupom, [state?.cupom])

	function returnCupom() {
		if (!state?.cupom) return

		const cupom = state.cupom

		title.setValue(cupom.Titulo)
		description.setValue(cupom.Descricao)
		numberOfCupons.setValue(cupom.QuantidadeDisponivel)
		setOverDate(new Date(cupom.DataDeValidade))

		setSelectedValue(ICuponType.PORCENTAGEM)
		value.setValue(cupom.PorcentagemDeDesconto.toString())
		handleSelect({ label: cupom.Parceiro, value: cupom.ParceiroId })
		setImg({ preview: cupom.Imagem, raw: null })
	}

	function handleSetDates(value: any) {
		setOverDate(value)
	}

	useEffect(() => {
		isDisabled(!allFieldsIsValid())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		title.fieldIsValid,
		description.fieldIsValid,
		numberOfCupons.fieldIsValid,
		value.fieldIsValid,
		selectedValue,
		img?.preview,
		partnertSelected,
		overDate
	])

	function returnPartnerCNPJ() {
		return isAdmin() ? partnertSelected?.value : partner.Cnpj
	}

	function allFieldsIsValid() {
		const partnerCNPJ = returnPartnerCNPJ()

		return (
			title.fieldIsValid &&
			description.fieldIsValid &&
			numberOfCupons.fieldIsValid &&
			value.fieldIsValid &&
			!!selectedValue &&
			!!img?.preview &&
			!!partnerCNPJ &&
			overDate &&
			isFuture(overDate)
		)
	}

	function handleImgChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedFile = event.target.files?.[0] || null

		if (!selectedFile) return
		if (selectedFile.size > IMAGE_SIZE) {
			toasterError('Tamanho máximo: 700KB')
			event.target.value = ''

			return
		}

		setImg({
			raw: selectedFile,
			preview: URL.createObjectURL(selectedFile)
		})
	}
	function handleDeleteImg() {
		setImg(null)
	}

	function onPreview() {
		setPreview((show) => !show)
	}

	function showError() {
		const show = !state?.cupom ? false : overDate && !isFuture(overDate)

		return show
	}

	function handleSubmit() {
		const partnerCNPJ = returnPartnerCNPJ()

		const isValidDate = overDate && isValid(overDate) ? true : false

		if (!partnerCNPJ || !isValidDate) return
		;(async () => {
			try {
				setLoading(true)
				const cupom = state?.cupom
				const image = img?.raw && ((await getBase64(img?.raw)) as string)

				const splitDataImage = image && removePrefixBase64(image)

				const isPercentage = selectedValue === ICuponType.PORCENTAGEM

				const valueTotal = currencyStringToNumber(value.value)

				const payload: ICreateCupomRequest = {
					DataDeValidade: formatDateToUS(
						overDate ? overDate : new Date(),
						FORMAT_BIRTH_BIRTH_DATE
					),
					Descricao: description.value,
					ImagemBase64: splitDataImage ? splitDataImage : cupom?.Imagem,
					Cnpj: partnerCNPJ,
					QuantidadeDisponivel: Number(numberOfCupons.value),
					TipoDesconto: isPercentage
						? ICupomType.Percentual
						: ICupomType.ValorFixo,
					PorcentagemDeDesconto: valueTotal,
					Titulo: title.value
				}
				if (cupom) {
					await editCupom(payload, cupom?.Id || '')
					navigate(-1)
					return
				}
				await createCupom(payload)
				navigate(-1)
			} catch (error: any) {
				toasterError(
					error?.response?.data?.Mensagem
						? error?.response.data.Mensagem
						: state?.cupom
						? 'Erro ao editar cupom'
						: 'Erro ao cadastrar cupom'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	return (
		<S.Container className='animated-right'>
			<GoBack />
			<main>
				<div className='info-logo'>
					{!img?.preview ? (
						<>
							<label htmlFor='img' className='file'>
								Capa
								<img src={Upload} alt='upload foto' />
							</label>

							<input
								style={{ display: 'none' }}
								type='file'
								name='img'
								id='img'
								onChange={handleImgChange}
								accept='image/png, image/jpeg, image/jpg'
							/>
						</>
					) : (
						<div className='img-upload'>
							<img src={img.preview} alt='Logo' className='logo' />
							<button onClick={handleDeleteImg} type='button'>
								<img src={Trash} alt='Logo excluir foto' />
							</button>
						</div>
					)}
				</div>

				<div className='flex period'>
					{isAdmin() ? <div className='select'>{renderSelect()}</div> : <div />}

					<div>
						<DatePicker
							label='Vencimento em:'
							value={overDate}
							onChange={handleSetDates}
							format='dd/MM/yyyy'
							minDate={new Date()}
							className={`${
								overDate && isFuture(overDate) ? 'date-selected' : ''
							}`}
						/>
					</div>
				</div>

				<div className='flex input-wrapper'>
					<InputPrimary
						{...title}
						name='title'
						id='title'
						label='Titulo'
						className='w50'
					/>

					<InputPrimary
						{...numberOfCupons}
						name='numberOfCupons'
						id='numberOfCupons'
						label='Quantidade'
						className='w20'
					/>

					<InputPrimary
						{...value}
						name='value'
						id='value'
						label='Porcentagem de desconto'
						className='w30'
						Icon={percent}
						disabled={state?.cupom ? true : false}
					/>
				</div>
				<InputPrimary
					{...description}
					name='description'
					id='description'
					label='Descrição'
				/>

				{!showError() ? (
					<div className='button-submit'>
						{!preview ? (
							<Button
								name='Visualizar Preview'
								onClick={onPreview}
								disabled={disabled}
								loading={loading}
							/>
						) : (
							<Button
								name={state?.cupom ? 'Editar Cupom' : 'Cadastrar Cupom'}
								onClick={handleSubmit}
								disabled={disabled}
								loading={loading}
							/>
						)}
					</div>
				) : (
					<span className='error-overdate'>
						<img
							src={AlertIcon}
							alt='Alert date expired'
							className='icon-alert'
						/>

						<p>Cupom vencido: edição não permitida.</p>
					</span>
				)}

				{preview && (
					<Ticket
						image={img?.preview || ''}
						title={title.value}
						qtd={numberOfCupons.value}
						value={
							selectedValue === ICuponType.PORCENTAGEM &&
							numberOfCupons.value > 0
								? `${value.value}% OFF`
								: value.value
						}
						description={description.value}
						name={partnertSelected?.label || ''}
						percent={
							selectedValue === ICuponType.PORCENTAGEM && value.value > 0
								? value.value
								: undefined
						}
						date={overDate ?? new Date()}
						openModal={() => console.log}
						height={200}
					/>
				)}
			</main>
		</S.Container>
	)
}

export default CreateCupon
