import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { Container } from './styles'
import Table from 'shared/components/Table'
import { ITableColumn, ITableRow, ITableNavProps, IOperatorPage } from './types'
import { tableStyle } from 'shared/styles/functions'
import HeaderPage from 'shared/components/HeaderPage'

import IconEdit from 'assets/images/edit-participant.svg'
import IconTrash from 'assets/images/delete.svg'
import IconLock from 'assets/images/lock.svg'
import IconUnlock from 'assets/images/unlock.svg'

import { RouteEnumsAdmin } from '../routes/enums'
import { formatCnpjCpf } from 'shared/utils/format'
import { IOperator } from 'shared/interfaces/user'
import ModalAction from 'shared/components/ModalAction'
import { IModalDeleteTarget } from 'shared/components/ModalAction/types'
import { toasterError } from 'shared/utils/toaster'
import { getPaginatedOperator } from 'shared/services/operador.service'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { formatDate } from 'shared/utils/date'
import { isOperator } from 'shared/utils/isAdmin'
import BadgeStatus from 'shared/components/BadgeStatus'
import { Status } from 'shared/interfaces/status'
import { cleanDocumentNumber } from 'shared/utils/validators'

function Operator() {
	const ITEMS_PER_PAGE = 10
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [operatorPage, setOperatorPage] = useState<IOperatorPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [currentTarget, setCurrentTarget] = useState<IModalDeleteTarget | null>(
		null
	)
	const [target, setTarget] = useState<IModalDeleteTarget | null>(null)
	const navigate = useNavigate()
	const [classAnimated, setAnimated] = useState('animated-right')
	const [loading, setLoading] = useState(false)
	const { user, partner } = useTypedSelector(['user', 'partner'])

	function handleSearch(value: string): void {
		setSearch(value)
	}

	useEffect(processTable, [searchDebounce, operatorPage.page])

	function processTable() {
		;(async () => {
			try {
				setLoading(true)

				let response = await getPaginatedOperator(
					operatorPage.page,
					ITEMS_PER_PAGE,
					searchDebounce
				)

				if (!response?.Dados?.Itens.length) {
					setTableRows([])
					return
				}
				const rows = response.Dados.Itens.map((item) => {
					return {
						status: renderStatus(
							item.Status,
							item?.Motivo || '',
							item.Id || cleanDocumentNumber(item.Cpf)
						),
						nome: item.Nome,
						cpf: formatCnpjCpf(item.Cpf),
						createdAt: item.DataHoraCadastro
							? formatDate(new Date(item.DataHoraCadastro))
							: '-',
						edit: renderEdit(item)
					}
				})

				setTableRows(rows)
				setOperatorPage({
					numberOfPages: response.Dados.NumeroTotalDePaginas,
					page: operatorPage.page || 1,
					totalDocs: response.Dados.NumeroTotalDeItens
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Operadores'
				)
			} finally {
				setLoading(false)
			}
		})()
	}
	function goToNewOperator() {
		navigate(RouteEnumsAdmin.CREATE_OPERATOR)
	}

	function goToEditOperator(operator: IOperator) {
		navigate(RouteEnumsAdmin.EDIT_OPERATOR, {
			state: { operator: operator }
		})
	}

	function handleDeleteEnd() {
		setCurrentTarget({
			id: currentTarget?.id || '',
			name: currentTarget?.name || ''
		})

		setTarget(null)
		processTable()
	}

	function handleClose() {
		setTarget(null)
		setCurrentTarget(null)
	}

	function renderStatus(status: Status, motivo: string, id: string) {
		return <BadgeStatus status={status} tooltip={motivo} id={id} />
	}

	function openModalDeleteOrBlock(
		id: string,
		name: string,
		type: 'BLOCK' | 'DELETE' | 'ACTIVE',
		hasReason: boolean,
		description?: string
	) {
		setAnimated('')

		setTarget({
			id,
			name,
			type,
			hasReason,
			description
		})
	}

	function returnButton(onClick: () => void, Icon: string, className: string) {
		return (
			<button className='edit' onClick={onClick}>
				<img src={Icon} alt='Edit' />
			</button>
		)
	}
	function renderEdit(operator: IOperator) {
		if (isOperator()) return <div></div>
		return (
			<div className='flex'>
				{returnButton(() => goToEditOperator(operator), IconEdit, 'edit')}
				{operator.Status === Status.Bloqueado
					? returnButton(
							() =>
								openModalDeleteOrBlock(
									operator.Id || '',
									operator.Nome,
									'ACTIVE',
									false,
									`Motivo do Bloqueio: ${operator.Motivo}`
								),
							IconUnlock,
							'edit'
					  )
					: returnButton(
							() =>
								openModalDeleteOrBlock(
									operator.Id || '',
									operator.Nome,
									'BLOCK',
									true
								),
							IconLock,
							'edit'
					  )}
				{returnButton(
					() =>
						openModalDeleteOrBlock(
							operator.Id || '',
							operator.Nome,
							'DELETE',
							true
						),
					IconTrash,
					'remove'
				)}
			</div>
		)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Status', accessor: 'status', sortType: 'basic' },
		{ Header: 'Nome', accessor: 'nome', sortType: 'basic' },
		{ Header: 'CPF', accessor: 'cpf', sortType: 'basic' },
		{
			Header: 'Criado em',
			accessor: 'createdAt',
			sortType: 'basic'
		},
		isOperator()
			? {
					Header: '',
					accessor: 'edit'
			  }
			: {
					Header: 'Ações',
					accessor: 'edit',
					sortType: 'basic'
			  }
	]

	function goToPage(pageIndex: number) {
		setOperatorPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: operatorPage.numberOfPages,
		pageIndex: operatorPage.page - 1,
		totalDocs: operatorPage.totalDocs
	}

	return (
		<Container className={classAnimated}>
			<div>
				<div>
					<HeaderPage
						title='Operadores'
						buttonName={isOperator() ? undefined : 'Novo Operador'}
						onClick={goToNewOperator}
						returnSearch={handleSearch}
						placeholder='Pesquisar Operador'
						id='new-operator'
					/>
				</div>

				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableRows}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Operador para serem exibidas.'
						{...tableStyle}
						padding={[26, 16, 26, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						isLoading={loading}
						className='table-operator'
					/>
				</div>
			</div>
			<ModalAction
				onClose={handleClose}
				target={target}
				type={'OPERATOR'}
				handleDeleteUserEnd={handleDeleteEnd}
			/>
		</Container>
	)
}

export default Operator
