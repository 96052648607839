import { Suspense, lazy, useEffect, useMemo, useState } from 'react'

import { Container, LoadingWrapper } from './styles'
import { useGetListPartnerGeoCode } from 'shared/services/partner.service'
import Loading, { LoadingEnum } from 'shared/components/Loading'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { IPartnerPage, ITableColumn, ITableNavProps, ITableRow } from './types'
import Table from 'shared/components/Table'
import { formatCnpjCpf } from 'shared/utils/format'
import { tableStyle } from 'shared/styles/functions'
import { formatCep } from 'shared/utils/validators'
// const MapaLazy = lazy(() => import('shared/components/Map'))

function RedesCredenciadas() {
	const { user } = useTypedSelector(['user'])

	const ITEMS_PER_PAGE = 10
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [partnerPage, setPartnerPage] = useState<IPartnerPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const { data, isLoading } = useGetListPartnerGeoCode({
		Limite: ITEMS_PER_PAGE,
		Pagina: partnerPage.page,
		DistanciaEmKm: 60,
		Latitude:
			(user.Endereco?.Latitude || 0) !== 0
				? user.Endereco?.Latitude
				: undefined,
		Longitude:
			(user.Endereco?.Longitude || 0) !== 0
				? user.Endereco?.Longitude
				: undefined
	})

	const partners = useMemo(
		() => data?.pages.flatMap((x) => x.Dados.Itens),
		[data]
	)

	useEffect(processTable, [partners])

	function processTable() {
		if (!partners?.length) {
			setTableRows([])

			return
		}
		const rows = partners.map((item) => {
			const { Bairro, Cep, Cidade, Estado, Logradouro, Numero } = item.Endereco
			const logradouro = `${Logradouro} , ${Numero} - ${Bairro} - ${formatCep(
				Cep
			)} - ${Cidade}/${Estado}`
			return {
				image: renderImage(item.LogoMarca),
				NomeFantasia: item.NomeFantasia,
				Email: item.Cnpj,
				Cnpj: formatCnpjCpf(item.Cnpj),
				RamoDeAtividade: item.RamoDeAtividade,
				logradouro
			}
		})

		setTableRows(rows)
		setPartnerPage({
			numberOfPages:
				data?.pages[0].Dados.NumeroTotalDePaginas || partnerPage.page,
			page: partnerPage.page || 1,
			totalDocs: data?.pages[0].Dados.NumeroTotalDeItens || partnerPage.page
		})
	}

	// const getAllGeocode = useMemo(() => {
	// 	if (!partners?.length) return []
	// 	return partners
	// 		.filter((x) => x?.Endereco?.Latitude && x?.Endereco?.Longitude)
	// 		.map((x) => {
	// 			return {
	// 				lat: x.Endereco.Latitude || 0,
	// 				lng: x.Endereco.Longitude || 0,
	// 				partnerName: x.RazaoSocial,
	// 				logo: x.LogoMarca
	// 			}
	// 		})
	// }, [partners])

	function renderImage(image: string) {
		return (
			<div className='image-voucher'>
				<img src={image} alt={'Cupom'} />
			</div>
		)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Logo', accessor: 'image' },
		{ Header: 'Nome', accessor: 'NomeFantasia', sortType: 'basic' },
		
		{
			Header: 'Logradouro',
			accessor: 'logradouro',
			sortType: 'basic'
		},
		{
			Header: 'Atividade',
			accessor: 'RamoDeAtividade',
			sortType: 'basic'
		},

		// {
		//   Header: "Criado em",
		//   accessor: "createdAt",
		//   sortType: "basic",
		// },
	]

	function goToPage(pageIndex: number) {
		setPartnerPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: partnerPage.numberOfPages,
		pageIndex: partnerPage.page - 1,
		totalDocs: partnerPage.totalDocs
	}

	function returnLoading() {
		return (
			<LoadingWrapper>
				<Loading type={LoadingEnum.dots} />
			</LoadingWrapper>
		)
	}
	return (
		<Container>
			<h1 className='title'>Parceiros Credenciados</h1>

			{isLoading ? (
				returnLoading()
			) : (
				// <Suspense fallback={returnLoading()}>
				// 	<MapaLazy markers={getAllGeocode} />
				// </Suspense>
				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableRows}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Parceiros para serem exibidas.'
						{...tableStyle}
						isLoading={isLoading}
						padding={[26, 16, 26, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						className='table-partner'
					/>
				</div>
			)}
		</Container>
	)
}

export default RedesCredenciadas
