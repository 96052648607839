export function compararObjetos(objeto1: any, objeto2: any): any {
	const objetoAlterado: any = {}

	for (const propriedade in objeto1) {
		if (objeto1.hasOwnProperty(propriedade)) {
			if (objeto1[propriedade] !== objeto2[propriedade]) {
				objetoAlterado[propriedade] = objeto2[propriedade]
			}
		}
	}

	return objetoAlterado
}
