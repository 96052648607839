import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { Container } from './styles'
import Table from 'shared/components/Table'
import { ITableColumn, ITableRow, ITableNavProps, ICupomPage } from './types'
import { tableStyle } from 'shared/styles/functions'
import HeaderPage from 'shared/components/HeaderPage'

import IconUpload from 'assets/images/upload-green.svg'
import IconLock from 'assets/images/lock.svg'
import IconUnlock from 'assets/images/unlock.svg'

import ModalAction from 'shared/components/ModalAction'
import { IModalDeleteTarget } from 'shared/components/ModalAction/types'
import {
	IResponseParticipants,
	getParticipantsOfTheCupom
} from 'shared/services/cupom.service'
import { toasterError } from 'shared/utils/toaster'
import { formatDate } from 'shared/utils/date'
import { formatCnpjCpf } from 'shared/utils/format'
import GoBack from 'shared/components/GoBack'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
interface IRouteState {
	id: string
}

function CupomParticipantsDetails() {
	const ITEMS_PER_PAGE = 10
	const route = useLocation()
	const state = route.state as IRouteState

	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [cuponsPage, setCuponsPage] = useState<ICupomPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [currentTarget, setCurrentTarget] = useState<IModalDeleteTarget | null>(
		null
	)
	const [target, setTarget] = useState<IModalDeleteTarget | null>(null)
	const [classAnimated, setAnimated] = useState('animated-right')

	const [loading, setLoading] = useState(false)

	function handleSearch(value: string): void {
		setSearch(value)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(processTable, [searchDebounce, cuponsPage.page])

	function processTable() {
		;(async () => {
			try {
				if (!state.id) return
				setLoading(true)

				const response = await getParticipantsOfTheCupom(state.id, {
					limite: ITEMS_PER_PAGE,
					page: cuponsPage.page,
					search: searchDebounce
				})

				const data = response.Dados.Itens

				const rows = data.map((item) => {
					return {
						codigo: renderImage(item.Codigo),
						status: renderStatus(item.Status, item.Motivo),
						titulo: item.Participante,
						parceiro: formatCnpjCpf(item.Cnpj) || '-',
						vencimento: formatDate(new Date(item.DataDeValidade)),
						cpf: formatCnpjCpf(item.Cpf),
						rescue: item.DataHoraResgate
							? formatDate(new Date(item.DataHoraResgate))
							: '-',
						edit: renderEdit(item)
					}
				})

				setTableRows(rows)
				setCuponsPage({
					numberOfPages: response.Dados.NumeroTotalDePaginas || 1,
					page: cuponsPage.page,
					totalDocs: response.Dados.NumeroTotalDeItens
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Cupons'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function handleDeleteEnd() {
		setCurrentTarget({
			id: currentTarget?.id || '',
			name: currentTarget?.name || ''
		})

		processTable()
		setTarget(null)
	}

	function handleClose() {
		setTarget(null)
		setCurrentTarget(null)
	}

	function renderStatus(status: string, reason?: string) {
		return (
			<div className={'status ' + status} id={`tooltip${status}`}>
				<span>{status}</span>
				{reason && (
					<ReactTooltip
						data-tooltip-id='tooltip'
						content={reason}
						anchorSelect={`#tooltip${status}`}
						variant='dark'
					/>
				)}
			</div>
		)
	}

	function renderImage(code: string) {
		return <p>{code}</p>
	}

	function openModalDeleteOrBlock(
		id: string,
		name: string,
		type: 'BLOCK' | 'DELETE' | 'ACTIVE' | 'REDUCE',
		description?: string
	) {
		setAnimated('')

		setTarget({
			id,
			name,
			type,
			hasReason: type === 'DELETE' || type === 'BLOCK' ? true : false,
			description
		})
	}

	function renderEdit(cupom: IResponseParticipants) {
		const isActive = cupom.Status.toLocaleLowerCase() === 'ativo'
		const isBlocked = cupom.Status.toLocaleLowerCase().includes('bloqueado')

		return (
			<div className='flex'>
				<button
					className='edit'
					onClick={() =>
						openModalDeleteOrBlock(
							cupom.Codigo || '',
							cupom.Participante,
							'REDUCE'
						)
					}
				>
					<img src={IconUpload} alt='dar baixa' />
				</button>

				{(isActive || isBlocked) && (
					<button
						className='block'
						onClick={() => {
							if (isActive) {
								openModalDeleteOrBlock(
									cupom.Codigo || '',
									cupom.Participante,
									'BLOCK'
								)
							} else {
								openModalDeleteOrBlock(
									cupom.Id || '',
									cupom.Participante,
									'ACTIVE'
									// `Motivo do Bloqueio: ${cupom.Motivo}`
								)
							}
						}}
					>
						<img src={isActive ? IconLock : IconUnlock} alt='Block' />
					</button>
				)}
			</div>
		)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Código', accessor: 'codigo' },
		{ Header: 'Status', accessor: 'status' },
		{ Header: 'Nome', accessor: 'titulo', sortType: 'basic' },
		{
			Header: 'Cpf',
			accessor: 'cpf',
			sortType: 'basic'
		},
		{
			Header: 'Vencimento',
			accessor: 'vencimento',
			sortType: 'basic'
		},
		{
			Header: 'Data Resgate',
			accessor: 'rescue',
			sortType: 'basic'
		},
		{
			Header: 'Ações',
			accessor: 'edit',
			sortType: 'basic'
		}
	]

	function goToPage(pageIndex: number) {
		setCuponsPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: cuponsPage.numberOfPages,
		pageIndex: cuponsPage.page - 1,
		totalDocs: cuponsPage.totalDocs
	}

	return (
		<Container className={classAnimated}>
			<GoBack />
			<div>
				<div>
					<HeaderPage
						title='Participantes'
						returnSearch={handleSearch}
						placeholder='Pesquisar Cupom'
					/>
				</div>

				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableRows}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Cupom para serem exibidas.'
						{...tableStyle}
						padding={[16, 16, 16, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						isLoading={loading}
					/>
				</div>
			</div>
			<ModalAction
				onClose={handleClose}
				target={target}
				type={'CUPOM'}
				handleDeleteUserEnd={handleDeleteEnd}
			/>
		</Container>
	)
}

export default CupomParticipantsDetails
