import React from 'react'
import { DiscountBadgeWrapper } from './styles'

export interface IProps {
	background: string
	color: string
	percetage: string
}
export default function DiscountBadge(props: IProps) {
	const { background, color, percetage } = props
	return (
		<DiscountBadgeWrapper background={background} color={color}>
			<div className='outer-jagged-parts'>
				<div className='inner-jagged-parts'>
					<div className='main'>
						<span className='large-text'>{percetage}</span>
					</div>
				</div>
			</div>
		</DiscountBadgeWrapper>
	)
}
