import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select, { ActionMeta, MultiValue } from 'react-select'

import * as S from './styles'
import GoBack from 'shared/components/GoBack'
import InputPrimary from 'shared/components/InputPrimary'
import useForm from 'shared/hooks/useForm'
import Upload from 'assets/images/upload.svg'
import Trash from 'assets/images/trash.svg'
import { ISelectOption } from 'shared/interfaces/options'
import {
	fetchCities,
	fetchStates,
	getAddress
} from 'shared/services/cep.service'
import SelectPrimary from 'shared/components/SelectPrimary'
import Button from 'shared/components/ButtonPrimary'
import { ICreateOperatorOfPartner, IPartner } from 'shared/interfaces/partner'
import {
	currencyStringToNumber,
	formatCellphone,
	formatCnpjCpf,
	toMoney
} from 'shared/utils/format'
import { createPartner, updatePartner } from 'shared/services/partner.service'
import { toasterError } from 'shared/utils/toaster'
import addLine from 'assets/images/add-line.svg'
import alertIcon from 'assets/images/alert-circle.svg'
import percent from 'assets/images/percent.svg'
import {
	SelectBlock,
	customStyles
} from 'shared/components/SelectPrimary/styles'
import {
	IMAGE_SIZE,
	cleanDocumentNumber,
	cleanPhoneNumber,
	formatCep
} from 'shared/utils/validators'
import useActionDeleteOrBlock from 'shared/hooks/useActionDeleteOrBlock'
import { concatAddress, returnGeoCode } from 'shared/utils/geocode'
import { getOperatorByCPF } from 'shared/services/operador.service'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { getBase64, removePrefixBase64 } from 'shared/utils/imageToBase64'
import { compararObjetos } from 'shared/utils/compareString'
interface IViewImg {
	raw: any
	preview: string
}

interface IRouteProps {
	partner?: IPartner
}

export default function CreateAccountPartner() {
	const route = useLocation() || null
	const state = route.state as IRouteProps

	const { activity } = useTypedSelector(['activity'])

	const nome = useForm('campoTexto')
	const razaoSocial = useForm('campoTexto')
	const rua = useForm('campoTexto')
	const numeroDaCasa = useForm('campoTexto')
	const bairro = useForm('campoTexto')
	const cep = useForm('cep')
	const telefone = useForm('telefone')
	const celular = useForm('celular')
	const email = useForm('email')
	const cnpj = useForm('cnpj')
	const valorCashback = useForm('valorMonetario')
	const porcentagem = useForm('porcentagemInteiro', 3)
	const quantidadeDeDiasParaVencimento = useForm('number')
	const quantidadeMaximaDeCupons = useForm('valueQuantity')
	const cpfOperador = useForm('cpf')
	const nomeDoOperador = useForm('campoOpcional')
	const [arrayOfOperador, setArrayOfOperador] = useState<
		ICreateOperatorOfPartner[]
	>([])

	const [img, setImg] = React.useState<IViewImg | null>(null)
	const [editImage, setImageEdit] = useState<string | null>(null)
	const [partner, setPartner] = useState<IPartner | null>(null)

	const [cityOptions, setCityOptions] = useState<ISelectOption[]>([])
	const [citySelected, setCitySelected] = useState<ISelectOption | null>(null)
	const [stateOptions, setStateOptions] = useState<ISelectOption[]>([])
	const [stateSelected, setStateSelected] = useState<ISelectOption | null>(null)

	const [activityOptions, setActivityOptions] = useState<ISelectOption[]>([])
	const [activitySelected, setActivitySelected] =
		useState<ISelectOption | null>(null)

	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [disabled, isDisabled] = useState(true)

	const { classAnimated, currentTarget, renderButtonsAction, renderModal } =
		useActionDeleteOrBlock({
			disabled,
			handleSubmit,
			id: state?.partner?.Id || '',
			loading,
			// nome: state?.nome || '',
			nome: '',
			type: 'PARTNER'
		})

	useEffect(initState, [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getData, [cep.fieldIsValid])
	useEffect(initCity, [stateSelected])
	useEffect(mappedActivity, [activity])
	// useEffect(() => {
	// 	quantidadeMaximaDeCupons.
	// }, [quantidadeMaximaDeCupons.value])

	useEffect(() => {
		if (!state?.partner) return

		returnPartner()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.partner])

	useEffect(() => {
		isDisabled(!allFieldsIsValid())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		nome.fieldIsValid,
		cnpj.fieldIsValid,
		razaoSocial.fieldIsValid,
		celular.fieldIsValid,
		rua.fieldIsValid,
		bairro.fieldIsValid,
		cep.fieldIsValid,
		email.fieldIsValid,
		activitySelected?.value,
		valorCashback.fieldIsValid,
		porcentagem.fieldIsValid,
		img?.preview,
		arrayOfOperador,
		numeroDaCasa.fieldIsValid,
		editImage
	])

	useEffect(() => {
		getNameOperator()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cpfOperador.fieldIsValid])

	function returnPartner() {
		if (!state?.partner) return

		setPartner(state?.partner)
		nome.setValue(state?.partner?.NomeFantasia)
		cnpj.setValue(formatCnpjCpf(state?.partner.Cnpj))
		razaoSocial.setValue(state?.partner.RazaoSocial)
		celular.setValue(formatCellphone(state?.partner.Celular))
		telefone.setValue(formatCellphone(state?.partner.Telefone))
		email.setValue(state?.partner.Email)
		setActivitySelected({
			label: state?.partner.RamoDeAtividade,
			value: state?.partner.RamoDeAtividade
		})
		valorCashback.setValue(toMoney(state?.partner.ValorCashbackAutomatico))
		porcentagem.setValue(state.partner.PorcentagemDeCashback || '0')

		rua.setValue(state?.partner.Endereco.Logradouro)
		cep.setValue(formatCep(state?.partner.Endereco.Cep))
		bairro.setValue(state?.partner.Endereco.Bairro)
		quantidadeDeDiasParaVencimento.setValue(
			state.partner.QuantidadeDeDiasVencimento
		)
		quantidadeMaximaDeCupons.setValue(
			state.partner.QuantidadeMaximaDeCupons || 5
		)
		numeroDaCasa.setValue(state.partner.Endereco.Numero)
		if (state?.partner?.RamoDeAtividade) {
			setActivitySelected(() => {
				return {
					label: state?.partner?.RamoDeAtividade || '',
					value: state?.partner?.RamoDeAtividade || ''
				}
			})
		}

		state?.partner.OperadoresDoParceiro &&
			setArrayOfOperador(
				state?.partner.OperadoresDoParceiro.map((operador) => {
					return {
						Cpf: formatCnpjCpf(operador.Cpf),
						Nome: operador.Nome
					}
				})
			)

		setImageEdit(state?.partner?.LogoMarca)
	}

	function getNameOperator() {
		;(async () => {
			try {
				if (!cpfOperador.value) return
				const operator = await getOperatorByCPF(
					cleanDocumentNumber(cpfOperador.value)
				)

				nomeDoOperador.setValue(operator.Dados.Nome)
			} catch (error: any) {
				toasterError(
					error?.response?.data?.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu ao buscar Operador'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function allFieldsIsValid() {
		return (
			nome.fieldIsValid &&
			cnpj.fieldIsValid &&
			razaoSocial.fieldIsValid &&
			celular.fieldIsValid &&
			rua.fieldIsValid &&
			bairro.fieldIsValid &&
			cep.fieldIsValid &&
			email.fieldIsValid &&
			activitySelected?.value &&
			valorCashback.fieldIsValid &&
			porcentagem.fieldIsValid &&
			arrayOfOperador.length > 0 &&
			(img?.raw || editImage) &&
			numeroDaCasa.fieldIsValid
		)
	}

	function handleImgChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedFile = event.target.files?.[0] || null

		if (!selectedFile) return

		if (selectedFile.size > IMAGE_SIZE) {
			toasterError('Tamanho máximo: 700KB')
			event.target.value = ''

			return
		}

		setImg({
			raw: selectedFile,
			preview: URL.createObjectURL(selectedFile)
		})
	}

	function getData() {
		;(async () => {
			try {
				if (!cep.fieldIsValid) return
				const jsonResult = await getAddress(cep.value.replace('-', ''))

				if (!jsonResult || jsonResult.erro) {
					rua.clearValue()
					bairro.clearValue()
					return
				}

				rua.setValue(jsonResult.logradouro)

				setCitySelected({
					label: jsonResult.localidade,
					value: jsonResult.localidade
				})
				setStateSelected({
					label: jsonResult.uf,
					value: jsonResult.uf
				})
				bairro.setValue(jsonResult.bairro)
			} catch (error) {
				console.log({ error: error })
			}
		})()
	}

	function initState() {
		;(async () => {
			quantidadeMaximaDeCupons.setValue(5)
			const result = await fetchStates()
			if (!result?.length) return
			const newstate: ISelectOption[] = result.map((x) => {
				return {
					label: x.sigla,
					value: String(x.id)
				}
			})

			setStateOptions(newstate)
		})()
	}

	function addNewOperator() {
		if (!cpfOperador.fieldIsValid) {
			toasterError('CPF inválido')
			return
		}

		const findOperator = arrayOfOperador.find(
			(operator) =>
				cleanDocumentNumber(operator.Cpf) ===
				cleanDocumentNumber(cpfOperador.value)
		)

		if (findOperator) return

		setArrayOfOperador((arr) => [
			...arr,
			{
				Cpf: cpfOperador.value,
				Nome: nomeDoOperador.value
			}
		])

		cpfOperador.clearValue()
		nomeDoOperador.clearValue()
		// cpfOperador.setValue('')
		// nomeDoOperador.setValue('')
	}

	function renderButtonAdd(index: number) {
		return (
			<div className='btn-plus' onClick={addNewOperator}>
				<button className='btn-clean' type='button'>
					<img src={addLine} alt='Add line' />
				</button>
			</div>
		)
	}

	function initCity() {
		;(async () => {
			if (!stateSelected?.value) return

			const result = await fetchCities(stateSelected.value)
			if (!result?.length) return
			const newcities: ISelectOption[] = result.map((x) => {
				return {
					label: x.nome,
					value: String(x.nome)
				}
			})

			setCityOptions(newcities)
		})()
	}

	function mappedActivity() {
		setActivityOptions(
			activity.map((option) => {
				return {
					label: option.RamoDeAtividade,
					value: String(option.Id)
				}
			})
		)
	}

	function handleCity(value: ISelectOption) {
		setCitySelected(value)
	}

	function handleActivity(value: ISelectOption) {
		setActivitySelected(value)
	}

	function handleState(value: ISelectOption) {
		setStateSelected(value)
		setCitySelected(null)
	}

	function renderSectionOperator(cpf?: string, name?: string, index?: number) {
		return (
			<div className='flex gap'>
				<InputPrimary
					{...(!cpf ? { ...cpfOperador } : { value: cpf })}
					name='cpfOperador'
					id='cpfOperador'
					label='CPF do Operador'
					className='w40'
					erro=''
					Icon={cpfOperador.erro && alertIcon}
					tooltip={cpfOperador.erro}
					inputMode='numeric'
				/>
				<InputPrimary
					{...(!name ? { ...nomeDoOperador } : { value: name })}
					disabled={true}
					name='nomeDoOperador'
					id='nomeDoOperador'
					label='Nome do Operador'
					className='w60'
				/>
				{renderButtonAdd(index || 0)}
			</div>
		)
	}
	function handleSubmit(event?: any) {
		event && event.preventDefault()
		;(async () => {
			// if (!mostrarTermoDeAdesao && !aceiteTermo) {
			// 	setMostrarTermoDeAdesao(true)
			// 	return
			// }
			// if (!allFieldsIsValid() || !aceiteTermo) {
			// 	return
			// }

			try {
				setLoading(true)
				const removePrefix = cep.value.replace('-', '')
				let getGeocode = {
					latitude: state?.partner?.Endereco?.Latitude || 0,
					longitude: state?.partner?.Endereco?.Longitude || 0
				}

				if (removePrefix !== state?.partner?.Endereco.Cep.replace('-', '')) {
					getGeocode = await returnGeoCode(
						concatAddress(rua.value, stateSelected?.value || '')
					)
				}

				const image = img?.raw ? ((await getBase64(img?.raw)) as string) : ''

				if (!image && !state?.partner?.LogoMarca) return

				const splitDataImage = image
					? removePrefixBase64(image)
					: state?.partner?.LogoMarca || ''
				const endereco = {
					Bairro: bairro.value,
					Cep: cep.value.replace('-', ''),
					Cidade: citySelected?.value || '',
					Complemento: '',
					Estado: stateSelected?.label || '',
					Logradouro: rua.value,
					Numero: numeroDaCasa.value,
					Latitude: Number(getGeocode.latitude),
					Longitude: Number(getGeocode.longitude)
				}

				const arrayCpf = arrayOfOperador.map((operator) =>
					cleanDocumentNumber(operator.Cpf)
				)

				const newPartner = {
					Celular: cleanPhoneNumber(celular.value),
					Cnpj: cleanDocumentNumber(cnpj.value),
					CpfOperadores: arrayCpf,
					Email: email.value,
					Endereco: endereco,
					LogoMarca: splitDataImage,
					// logoMarca: img?.raw,
					NomeFantasia: nome.value,
					PorcentagemDeCashback: Number(porcentagem.value),
					QuantidadeDeDiasVencimento: quantidadeDeDiasParaVencimento.value || 5,
					RamoDeAtividade: activitySelected?.label || '',
					RazaoSocial: razaoSocial.value,
					Telefone: cleanPhoneNumber(telefone.value),
					ValorCashbackAutomatico: currencyStringToNumber(valorCashback.value),
					QuantidadeMaximaDeCupons: parseInt(quantidadeMaximaDeCupons.value)
				}

				if (state?.partner?.Id) {
					const compare = compararObjetos(state?.partner, newPartner)
					await updatePartner(state.partner.Id, {
						...compare,
						CpfOperadores: arrayCpf
					})
					navigate(-1)
					return
				}
				await createPartner(newPartner)
				navigate(-1)
			} catch (error: any) {
				toasterError(
					error?.response?.data?.Mensagem
						? error?.response.data.Mensagem
						: partner?.Id
						? 'Erro ao editar Parceiro'
						: 'Erro ao realizar cadastro do Parceiro'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function handleDeleteImg() {
		setImg(null)
		setImageEdit(null)
	}

	const mappedOptions = useMemo(() => {
		return arrayOfOperador.map((item) => {
			return {
				label: `${item.Cpf} - ${item.Nome}`,
				value: item.Cpf
			}
		})
	}, [arrayOfOperador])

	function handleChange(
		selectedOption: MultiValue<ISelectOption>,
		actionMeta: ActionMeta<ISelectOption>
	) {
		if (!selectedOption?.length) {
			setArrayOfOperador([])
			return
		}
		if (actionMeta.action === 'clear') {
			setArrayOfOperador([])
			return
		}

		setArrayOfOperador((options) =>
			options.filter((x) => x.Cpf !== actionMeta.removedValue?.value)
		)
	}

	return (
		<S.Container className={classAnimated}>
			<div>
				<GoBack />

				<form onSubmit={handleSubmit}>
					<div className='info-logo'>
						{!img?.preview && !editImage ? (
							<>
								<label
									htmlFor={
										!state?.partner?.Id ? 'upload-create' : 'upload-edit'
									}
									className='file'
								>
									Upload
									<img src={Upload} alt='upload image' />
								</label>

								<input
									style={{ display: 'none' }}
									type='file'
									name='img'
									id={!state?.partner?.Id ? 'upload-create' : 'upload-edit'}
									onChange={handleImgChange}
									accept='image/png, image/jpeg, image/jpg'
								/>
							</>
						) : (
							<div className='img-upload'>
								<img
									src={
										img?.preview ? img?.preview : editImage ? editImage : Upload
									}
									alt='Logo'
									className='logo'
								/>
								<button onClick={handleDeleteImg} type='button'>
									<img src={Trash} alt='Logo excluir foto' />
								</button>
							</div>
						)}
					</div>

					<div>
						<div className='form-data'>
							<div>
								<h1>Dados do Parceiro</h1>
								<InputPrimary
									{...razaoSocial}
									name='razaoSocial'
									id='razaoSocial'
									label='Razão Social ●'
								/>
								<InputPrimary
									{...cnpj}
									name='cnpj'
									id='cnpj'
									label='CNPJ ●'
									inputMode='numeric'
								/>

								<InputPrimary
									{...email}
									name='email'
									id='email'
									label='E-mail ●'
								/>

								<div className='flex gap'>
									<InputPrimary
										{...telefone}
										name='telefone'
										id='telefone'
										label='Telefone'
										className='w50'
										inputMode='numeric'
									/>

									<InputPrimary
										{...celular}
										name='celular'
										id='celular'
										label='Celular ●'
										className='w50'
										inputMode='numeric'
									/>
								</div>

								{/* <InputPrimary
								{...ramoDeAtividade}
								name='ramoDeAtividade'
								id='ramoDeAtividade'
								label='Ramo de atividade'
							/> */}

								<SelectPrimary
									label='Ramo de atividade ●'
									name='ramoDeAtividade'
									options={activityOptions}
									value={activitySelected}
									// className='select-state w60'
									onChange={handleActivity}
									placeholder='Ramo de atividade'
									id='ramoDeAtividade'
								/>
							</div>

							<div>
								<h1>Dados do Endereço</h1>
								<InputPrimary
									{...nome}
									name='name'
									id='name'
									label='Nome Fantasia ●'
								/>
								<div className='flex gap'>
									<InputPrimary
										{...cep}
										name='cep'
										id='cep'
										label='CEP ●'
										className='w60'
										inputMode='numeric'
									/>
									<InputPrimary
										{...numeroDaCasa}
										name='numeroDaCasa'
										id='numeroDaCasa'
										label='Numero ●'
										className='w40'
									/>
								</div>

								<InputPrimary {...rua} name='rua' id='rua' label='Endereço ●' />

								<InputPrimary
									{...bairro}
									name='bairro'
									id='bairro'
									label='Bairro ●'
								/>

								<div className='flex gap'>
									<SelectPrimary
										label='Estado'
										name='state'
										options={stateOptions}
										value={stateSelected}
										onChange={handleState}
										className='select-city w40'
										placeholder='Estado ●'
										id='state'
									/>

									<SelectPrimary
										label='Cidade'
										name='city'
										options={cityOptions}
										value={citySelected}
										className='select-state w60'
										onChange={handleCity}
										placeholder='Cidade ●'
										id='city'
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='info-cash'>
						<div>
							<h1>Informação Cashback</h1>
							<div className='flex gap '>
								<InputPrimary
									{...valorCashback}
									name='valorCashback'
									id='valorCashback'
									label='Valor Cashback ●'
									className='w50'
									inputMode='numeric'
								/>
								<InputPrimary
									{...porcentagem}
									name='porcentagem'
									id='porcentagem'
									label='Porcentagem Cashback ●'
									className='w50'
									inputMode='numeric'
									Icon={percent}
								/>
							</div>
							<div className='flex gap '>
								<InputPrimary
									{...quantidadeDeDiasParaVencimento}
									name='quantidadeDeDiasParaVencimento'
									id='quantidadeDeDiasParaVencimento'
									label='Dias para Vencimento ●'
									className='w50'
									inputMode='numeric'
								/>
								<InputPrimary
									{...quantidadeMaximaDeCupons}
									name='QuantidadeMaximaDeCupons'
									id='QuantidadeMaximaDeCupons'
									label='Quantidade Maxima De Cupons ●'
									className='w50'
									inputMode='numeric'
								/>
							</div>
						</div>
					</div>

					<div className='info-operator'>
						<h1>Dados do operador</h1>
						<div className='operator-wrapper'>
							{renderSectionOperator()}
							<SelectBlock>
								<Select
									noOptionsMessage={() => null}
									styles={customStyles as any}
									delimiter='10'
									name='Operadores'
									placeholder='Operadores ●'
									isMulti
									value={mappedOptions}
									options={mappedOptions}
									onChange={handleChange}
								/>
								{mappedOptions.length >= 1 && <label>Operadores</label>}
							</SelectBlock>
						</div>
						{state?.partner?.Id ? (
							renderButtonsAction()
						) : (
							<div className='btn-submit'>
								<Button
									type='submit'
									name='Finalizar cadastro'
									onSubmit={(e) => handleSubmit(e)}
									disabled={disabled}
									loading={loading}
								/>
							</div>
						)}
					</div>
				</form>
			</div>

			{renderModal()}
		</S.Container>
	)
}
