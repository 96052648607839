import React, { useState, ChangeEvent } from 'react'
import {
	formatCellphone,
	formatCnpjCpf,
	formatPostalCode,
	formatRG,
	maskDate,
	maskDecimal,
	maskMoney,
	maskPercentage,
	returnOnlyNumber,
	toNumber,
	toPercentageToNumber,
	validatorDate
} from '../utils/format'
import {
	checkCNPJ,
	checkCPF,
	checkEmail,
	checkPassword,
	checkPhone,
	checkPostalCode,
	checkRG,
	checkTel,
	onlyNumber
} from '../utils/validators'

const defaultFunction = (value: string) => value

interface ValidatorBase {
	message: string
	mask: (valor: string) => string
	maxLenght?: number
	validator?: (value: string) => boolean
}

interface ValidatorType {
	[id: string]: ValidatorBase
}

const validators: ValidatorType = {
	numeroResidencia: {
		message: 'Campo deve conter mais de 1 caracteres',
		mask: defaultFunction
	},
	estado: {
		message: 'Campo deve conter mais de 1 caracteres',
		mask: defaultFunction
	},

	campoTexto: {
		message: 'Campo deve conter mais de 3 caracteres',
		mask: defaultFunction
	},
	campoOpcional: {
		message: '',
		mask: defaultFunction
	},
	login: {
		message: 'Campo deve conter mais de 3 caracteres',
		mask: defaultFunction
	},

	password: {
		message:
			'Senha deve conter no mínimo 6 caracteres.',
		mask: defaultFunction,
		validator: checkPassword,
		maxLenght: 30
	},
	rg: {
		message: 'Preencha um RG válido',
		validator: checkRG,
		mask: formatRG,
		maxLenght: 12
	},
	email: {
		validator: checkEmail,
		message: 'Preencha um email válido',
		mask: defaultFunction
	},
	number: {
		message: 'Utilize apenas numeros',
		mask: defaultFunction,
		validator: onlyNumber
	},
	cep: {
		message: 'Deve ser informado Cep válido',
		mask: formatPostalCode,
		validator: checkPostalCode,
		maxLenght: 9
	},
	cpf: {
		mask: formatCnpjCpf,
		message: 'Preencha um CPF válido',
		// validator: (value) => checkPostal(value),
		validator: checkCPF,
		maxLenght: 14
	},
	celular: {
		mask: formatCellphone,
		message: 'Preencha um celular válido',
		validator: checkPhone,
		maxLenght: 15
	},
	telefone: {
		mask: formatCellphone,
		message: 'Preencha um telefone válido',
		validator: checkTel,
		maxLenght: 14
	},
	cnpj: {
		mask: formatCnpjCpf,
		message: 'Preencha um CNPJ válido',
		validator: checkCNPJ,
		maxLenght: 18
	},
	dataDeNascimento: {
		mask: maskDate,
		message: 'Data inválida',
		maxLenght: 10,
		validator: validatorDate
	},
	valorMonetario: {
		mask: maskMoney,
		message: 'Apenas número',
		maxLenght: 10,
		validator: toNumber
	},
	valorMonetarioCustomValidator: {
		mask: maskMoney,
		message: 'Apenas número',
		maxLenght: 10
	},
	valorDecimal: {
		mask: maskDecimal,
		message: 'Apenas número',
		maxLenght: 10,
		validator: toNumber
	},
	valueQuantity: {
		mask: returnOnlyNumber,
		message: 'Apenas número',
		maxLenght: 3,
		validator: onlyNumber
	},
	porcentagemInteiro: {
		mask: maskPercentage,
		message: 'Número entre 1% e 100%',
		maxLenght: 3,
		validator: toPercentageToNumber
	}
}

type typeInput =
	| 'nome'
	| 'email'
	| 'number'
	| 'login'
	| 'password'
	| 'cep'
	| 'campoTexto'
	| 'numeroResidencia'
	| 'estado'
	| 'rg'
	| 'cpf'
	| 'celular'
	| 'cnpj'
	| 'telefone'
	| 'campoOpcional'
	| 'dataDeNascimento'
	| 'valorMonetario'
	| 'valorDecimal'
	| 'valueQuantity'
	| 'porcentagemInteiro'
	| 'valorMonetarioCustomValidator'
	| undefined

interface Response {
	value: any
	setValue: any
	erro: string | undefined
	isValid: () => boolean
	onBlur: () => boolean
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	maxLength?: number
	fieldIsValid: boolean
	clearValue: () => void
}

const useForm = (
	type: typeInput = undefined,
	maxLength?: number,
	validator?: (value: string) => boolean
): Response => {
	const [value, setValue] = useState<string | number | Date | undefined>('')
	const [erro, setError] = useState<string | undefined>()
	const [fieldIsValid, setFieldIsValid] = useState(false)

	function isValid(value: any): boolean {
		// Most not apply validation
		if (type === undefined || type === 'campoOpcional') {
			setFieldIsValid(true)
			setError(undefined)
			return true
		}

		if (typeof value === 'string' && value.length === 0) {
			setError('Preencha um valor')
			setFieldIsValid(false)
			return false
		}

		if (validators[type]?.validator) {
			const validator = validators[type]?.validator?.(value)
			setError(validator ? undefined : validators[type].message)
			setFieldIsValid(Boolean(validator))

			return Boolean(validator)
		}

		if (validator) {
			const valueIsValid = validator(value)
			setError(valueIsValid ? undefined : validators[type].message)
			setFieldIsValid(Boolean(valueIsValid))

			return Boolean(valueIsValid)
		}
		// if (
		//   validationExist(type) &&
		//   didNotPassTheRegex(type, cleanDocumentNumber(value))
		// ) {
		//   setError(validators[type].message);

		//   return false;
		// }

		setError(undefined)
		setFieldIsValid(Boolean(true))
		return true
	}

	function onChange({ target }: ChangeEvent<HTMLInputElement>): void {
		if (type) {
			const mask = validators[type].mask(target.value)
			setValue(mask)
			return
		}

		if (erro) isValid(target.value)
	}

	function PreenchidosAutomaticamente(x: any) {
		setValue(x)
		isValid(x)
	}

	function clearValue() {
		setError(undefined)
		setValue('')
		setFieldIsValid(false)
	}

	React.useEffect(() => {
		if (!value) return
		isValid(value)
	}, [value])

	return {
		value,
		setValue: PreenchidosAutomaticamente,
		erro,
		onChange,
		isValid: () => isValid(value),
		onBlur: () => isValid(value),
		maxLength: maxLength ? maxLength : type && validators[type].maxLenght,
		fieldIsValid,
		clearValue: clearValue
	}
}

export default useForm
