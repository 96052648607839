import { Link, useNavigate } from 'react-router-dom'
import { Container } from './styles'
import { adminRoutesData } from '../../../modules/routes/routesAdminData'
import {
	RouteEnumsAdmin,
	RouteEnumsUnauthentication
} from '../../../modules/routes/enums'
import { logout } from 'shared/utils/logout'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { NivelDeAcessoEnum } from 'shared/interfaces/user'
import { isAdmin } from 'shared/utils/isAdmin'

export interface IViewProps {
	isOpen: boolean
	handleOpen: () => void
}

function MenuNav(props: IViewProps) {
	const { handleOpen, isOpen } = props
	const navigate = useNavigate()
	const { user } = useTypedSelector(['user'])
	const routesData = adminRoutesData.filter(
		(route) =>
			route.displayOnNavBar &&
			route.accessTypes.includes(user.NivelDeAcesso as NivelDeAcessoEnum)
	)

	function prepareLogout() {
		logout()
		navigate(RouteEnumsUnauthentication.LOGIN)
	}

	function goToMyProfile() {
		navigate(RouteEnumsAdmin.MY_PROFILE)
	}
	return (
		<Container>
			<div className={isOpen ? 'layer-active' : ''} onClick={handleOpen} />
			<nav className={isOpen ? 'active' : 'inactive'}>
				<div className='profile'>
					<div className='info'>
						<h1 className='name'>Olá, {user.Nome}</h1>
						{isAdmin() ? null : (
							<button onClick={goToMyProfile} className='profile-link'>
								Meu Perfil
							</button>
						)}
					</div>
				</div>
				<ul>
					{routesData.map((route) => (
						<li key={route.path}>
							<Link
								className='nav-link'
								to={route.path ?? ''}
								onClick={handleOpen}
							>
								{route.icon ? (
									<img
										src={route.icon}
										alt='Icone menu'
										className='icon'
										color='blue'
									/>
								) : null}
								<p className='nav-text'>{route.name}</p>
							</Link>
						</li>
					))}
				</ul>

				<button className='logout' onClick={prepareLogout}>
					SAIR
				</button>
			</nav>
		</Container>
	)
}

export default MenuNav
