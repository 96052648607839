import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './shared/store'
import { Provider } from 'react-redux'
import { GlobalStyle } from './global'
import { RoutesRedirect } from './modules/routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from '@mui/x-date-pickers/locales'
import 'date-fns/locale/pt-BR'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

function App() {
	const queryClient = new QueryClient()
	return (
		<div className='App'>
			<GlobalStyle />
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				localeText={
					ptBR.components.MuiLocalizationProvider.defaultProps.localeText
				}
			>
				<Provider store={store}>
					<PersistGate persistor={persistor}>
						<QueryClientProvider client={queryClient}>
							<RoutesRedirect />
						</QueryClientProvider>
					</PersistGate>
				</Provider>
			</LocalizationProvider>
			<ToastContainer limit={5} />
		</div>
	)
}

export default App
