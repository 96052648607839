import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IUserActionCreators
} from '../../interfaces/action'
import { IUser, ICarteiras } from '../../interfaces/user'
import { Status } from 'shared/interfaces/status'

/**
 * Action types & creators
 */
export const { Types, Creators }: IActionsFromCreators<IUserActionCreators> =
	createActions({
		setUser: ['user'],
		resetUser: [],
		updateWallet: ['wallet']
	})

/**
 * Handlers
 */

export const INITIAL_STATE: IUser = {
	Id: '',
	Nome: '',
	DataDeNascimento: '',
	Cpf: '',
	Parceiro: '',
	DataHoraCadastro: '',
	Email: '',
	Celular: '',
	Apelido: '',
	Ativo: false,
	NivelDeAcesso: null,
	Endereco: null,
	PermiteWhatsApp: false,
	MinhasCarteiras: [],
	Status: Status.Ativo
}

const set = (state = INITIAL_STATE, action: any) => action.user
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE
export const updateWallet = (
	state = INITIAL_STATE,
	action: any
): ICarteiras[] => action.MinhasCarteiras

/**
 * Reducer
 */
export default createReducer<IUser, Action<IUserActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_USER]: set,
		[Types.RESET_USER]: reset,
		[Types.UPDATE_WALLET]: updateWallet
	}
)
