import styled from "styled-components";

export const Container = styled.div`
  padding: 0.5rem 0;
  position: relative;

  label {
    position: absolute;
    font-size: 14px;
    left: 1rem;
    color: #979e97;
    transition: all 0.22s;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 20px);
  }
  .icon {
    position: absolute;
    width: 22px;
    height: 22px;
    right: 14px;
    top: 24px;
  }
  input {
    border: 1px solid var(--color-cinza);
    display: block;
    font-size: 1rem;
    padding: 16px;
    border-radius: 0.4rem;
    background-color: var(--color-branco);
    transition: 0.3s;
    outline: none;
    color: var(--color-primary);

    &[disabled] {
      background: #eee;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &[disabled]:hover {
      box-shadow: 0 0 0px 0px var(--color-rosa);
      border: 1px solid white;
      color: 0;
    }

    &:hover,
    &:focus {
      outline: none;
      background: white;
      box-shadow: 0 0 2px 1px var(--color-rosa);
      border-color: var(--color-instagram);
      color: var(--color-primary);
      transition: 0.3s;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 40px white inset !important;
    }
    &:focus + label,
    &:hover + label,
    &:not([value=""]) + label {
      top: 0px;
      background: white;
      padding: 2px;
      font-size: 12px;
      transform: translateY(0);
      left: 6px;
      color: var(--color-rosa);
      width: auto;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: #f31;
  font-size: 12px;
  margin-bottom: 8px;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  right: 14px;
  top: 26px;
`;
