import Logo from "assets/images/logo.png";
import { FooterWrapper } from "./styles";

export default function FooterNotLogged() {
  return (
    <FooterWrapper>
      <div className="img-logo">
        <img src={Logo} alt="Grupo Petrópolis" />
      </div>
      <button>CONTATO</button>
    </FooterWrapper>
  );
}
