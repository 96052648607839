import styled from 'styled-components'

export const CashBackCardWrapper = styled.div`
	display: flex;
	margin-bottom: 8px;
	background: var(--color-branco);
	border-radius: 4px;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	position: relative;

	img {
		border-radius: 4px;
		object-fit: cover;
		max-height: 100%;
	}

	.discount-badge {
		position: absolute;
		top: -14px;
		right: -14px;
	}
	.date-overdue {
		text-align: center;
		margin-right: 21px;

		.title-overdue {
			font-size: 10px;
		}

		.date {
			font-family: var(--font-oswald);
			font-weight: bold;
		}
	}

	.rescue {
		background: red;
		color: white;
		border-radius: 8px;
		padding: 2px 8px;
		font-family: var(--font-roboto);
		font-size: 12px;
		background: #0071e3;
		text-transform: uppercase;
        font-weight: bold;
	}
`

export const InfoPartner = styled.div`
	display: flex;
	align-items: center;

	figure {
		margin: 0;
		height: 60px;
		width: 60px;
	}

	.voucher-title {
		margin-left: 16px;

		p {
			font-size: 12px;
		}
		.price {
			color: #5ea715;
			font-weight: bold;
			font-size: 14px;
			font-family: var(--font-oswald);
		}
	}
`
