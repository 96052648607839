import { useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { pt } from "date-fns/locale";

import iconCalendar from "assets/images/calendar.svg";

import { IFromTo, IProps } from "./types";
import { DayPickerWrapper } from "./styles";

function DatePicker({ dateSetter, value }: IProps) {
  const [range, setRange] = useState<DateRange | undefined>();
  const { from, to } = value;
  const [show, setShow] = useState(false);

  function parseToDisplay(date: Date) {
    if (!date) return "DD/MM/YY";

    return format(date, "dd/MM/yy", { locale: pt });
  }

  function toggleShowDates() {
    setShow((show) => !show);
  }

  function rangeDates(dates: DateRange | undefined) {
    if (!dates) return;
    setRange(dates);
    dateSetter(dates as IFromTo);
  }

  return (
    <DayPickerWrapper>
      <div className="date-picker-primary">
        <div className="btn">
          <button onClick={toggleShowDates}>
            <p>
              {value
                ? `${parseToDisplay(from)} até ${parseToDisplay(to)}`
                : "Período"}
            </p>
            <img src={iconCalendar} alt="Icon Calendar" />
          </button>
        </div>

        {show ? (
          <DayPicker
            // defaultMonth={new Date()}
            styles={{
              caption: { color: "#fc5c94" },
            }}
            showOutsideDays={false}
            //   onDayPickerShow={() => setShowDates(true)}
            //   onDayPickerHide={() => setShowDates(false)}
            mode="range"
            selected={range}
            onSelect={rangeDates}
            locale={pt}
          />
        ) : null}
      </div>
    </DayPickerWrapper>
  );
}

export default DatePicker;
