import styled from 'styled-components'

export const TicketWrapper = styled.div`
	display: flex;
	// background: rgb(157 157 157);
	// padding: 2px;
	border-radius: 5px;
	cursor: pointer;
	// box-shadow: rgba(0, 0, 0, 0.3) 0px -7px 38px, rgba(0, 0, 0, 0.22) 3px 5px 12px;
`
export const TicketContainer = styled.div`
	display: flex;
	background: white;
	gap: 1rem;
	width: 100%;
	// height: 200px;
	justify-content: space-between;
	border-radius: 6px;

	.image {
		/* height: 250px; */
		width: 40%;
		opacity: 0.85;

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.title {
		font-family: var(--font-oswald);
		color: var(--color-rosa);
		margin: 0;
		font-size: 18px;
		text-align: center;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.ticket-info {
		width: 40%;
		text-align: center;

		.qtd-tickets {
			color: #4a437e;
			font-family: var(--font-courgette);
			margin-bottom: 8px;
		}

		.date {
			font-family: var(--font-oswald);
			display: flex;
			justify-content: space-between;
			border-top: 1px solid rgb(181 174 174);
			border-bottom: 1px solid rgb(181 174 174);
			margin-top: 8px;
			justify-content: center;

			span:nth-child(2) {
				font-weight: bold;
				color: var(--color-rosa);
			}
		}

		.price {
			font-family: var(--font-oswald);
			color: rgb(216, 53, 101);
			font-size: 18px;
		}

		.show-name {
			h1,
			h2 {
				font-family: var(--font-roboto);
			}

			h2 {
				color: #3d3838;
				font-weight: 400;
				font-size: 10px;
				text-transform: uppercase;
				margin: 8px 0;
			}

			.description {
				font-family: var(--font-roboto);
				font-size: 12px;
				display: block;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 16px 0;
			}
		}
	}
	.right {
		width: 20%;
		border-left: 2px dashed #404040;
		padding: 16px;
		position: relative;
		text-align: center;
		font-family: var(--font-oswald);

		h1 {
			font-size: 50px;
			margin-top: 16px;
		}
		h2 {
			margin: 0px;
		}

		.discount {
			background: rgb(216, 53, 101);
			/* width: 66px; */
			padding: 4px 6px;
			position: absolute;
			right: 0;
			top: 0;
			border-bottom-left-radius: 6px;
			border-top-right-radius: 6px;

			p {
				color: white;
			}
		}

		.info-date {
			// margin-top: calc(50% - 32px);
		}
	}

	.right::after {
		content: '';
		height: 15px;
		width: 19px;
		background-color: rgb(250, 250, 250);
		position: absolute;
		border-top-left-radius: 50%;
		border-top-right-radius: 50%;
		// box-shadow: rgba(0, 0, 0, 0.22) 0px 15px 12px;

		bottom: 0px;
		left: -9px;
	}

	.right::before {
		content: '';
		height: 15px;
		width: 19px;
		background-color: rgb(250, 250, 250);
		position: absolute;
		top: 0px;
		left: -9px;
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
		// box-shadow: rgba(0, 0, 0, 0.22) -2px 0px 12px;
	}
`
