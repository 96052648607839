import { useEffect, useState } from 'react'
import { endOfToday, isValid, startOfMonth } from 'date-fns'

import { Container } from './styles'
import DatePicker from 'shared/components/DatePicker'
import { IFromTo } from 'shared/interfaces/common'
import Table from 'shared/components/Table'
import {
	IDashboardPage,
	ITableColumn,
	ITableRow,
	ITableNavProps
} from './types'
import { tableStyle } from 'shared/styles/functions'
import InputPrimary from 'shared/components/InputPrimary'
import useForm from 'shared/hooks/useForm'
import Icon from 'assets/images/search.svg'
import { useGetDashboard } from 'shared/services/dashboard.service'
import {
	FORMAT_BIRTH_BIRTH_DATE,
	formatCnpjCpf,
	formatDateToUS,
	toMoney
} from 'shared/utils/format'
import { IReportTotal, VALUE_INITIAL } from 'shared/interfaces/dashboard'
import { cleanDocumentNumber } from 'shared/utils/validators'

function Dashboard() {
	const ITEMS_PER_PAGE = 10
	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfMonth(new Date()),
		to: endOfToday()
	})

	const { data, isLoading } = useGetDashboard({
		DataFim:
			(isValid(selectedDates.to) &&
				formatDateToUS(selectedDates.to as Date, FORMAT_BIRTH_BIRTH_DATE)) ||
			'',
		DataInicio:
			(isValid(selectedDates.from) &&
				formatDateToUS(selectedDates.from as Date, FORMAT_BIRTH_BIRTH_DATE)) ||
			''
	})
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [tableFiltered, setTableFiltered] = useState<ITableRow[]>([])
	const [dashPage, setDashPage] = useState<IDashboardPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const [total, setTotal] = useState<IReportTotal>(VALUE_INITIAL)
	const search = useForm('campoOpcional')

	function processDashboard() {
		const rows = data?.pages.flatMap((item) => item.Dados)

		if (!rows?.length) return
		const reportTotal = rows[0].RelatorioAdmin
		const reportPartners = rows[0].RelatorioParceiros

		const mapped = reportPartners.map((partners) => {
			return {
				...partners,
				Cnpj: formatCnpjCpf(partners.Cnpj),
				TotalDeVendas: toMoney(partners.TotalDeVendas),
				TotalDeVouchersBaixados: toMoney(partners.TotalDeVouchersBaixados)
			}
		})
		setTotal(reportTotal)
		setTableRows(mapped)
		setDashPage({
			numberOfPages: rows.length / ITEMS_PER_PAGE || 1,
			page: 1,
			totalDocs: rows.length
		})
	}

	useEffect(processDashboard, [data])
	useEffect(() => {
		if (!search.value) {
			setTableFiltered(tableRows)
			return
		}
		const filterBySearch = tableRows.filter((rows) => {
			const cleanCnpj = cleanDocumentNumber(rows.Cnpj)
			return (
				cleanCnpj.includes(cleanDocumentNumber(search.value)) ||
				rows.NomeFantasia.toLowerCase().includes(search.value.toLowerCase())
			)
		})
		setTableFiltered(filterBySearch)
	}, [search.value, tableRows])

	const tableColumns: ITableColumn[] = [
		{ Header: 'Cnpj', accessor: 'Cnpj', sortType: 'basic' },
		{
			Header: 'Nome Fantasia',
			accessor: 'NomeFantasia',
			sortType: 'basic'
		},
		{
			Header: 'Total De Vendas',
			accessor: 'TotalDeVendas',
			sortType: 'basic'
		},
		{
			Header: 'Quantidade De Vouchers Gerados',
			accessor: 'QuantidadeDeVouchersGerados',
			sortType: 'basic'
		},
		{
			Header: 'Total De Vouchers Baixados',
			accessor: 'TotalDeVouchersBaixados',
			sortType: 'basic'
		}
	]

	function handleSetDates(value: IFromTo) {
		setSelectedDates(value)
	}

	function renderTotal(title: string, subtitle: number | string) {
		return (
			<div className='report-total'>
				<h3>{title}</h3>
				<p>{subtitle}</p>
			</div>
		)
	}

	function goToPage(pageIndex: number) {
		setDashPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: dashPage.numberOfPages,
		pageIndex: dashPage.page - 1,
		totalDocs: dashPage.totalDocs
	}

	return (
		<Container className='animated-right'>
			<div>
				<h1 className='title'>Relatório</h1>
				<div className='header-search'>
					<DatePicker dateSetter={handleSetDates} value={selectedDates} />
					<InputPrimary
						{...search}
						id='search'
						placeholder='Pesquisar parceiro'
						Icon={Icon}
					/>
				</div>

				<div className='flex gap'>
					<>
						{renderTotal(
							'Quantidade de vouchers gerados',
							total.QuantidadeDeVouchersGerados
						)}
						{renderTotal(
							'Quantidade de vouchers baixados',
							total.QuantidadeDeVouchersBaixados
						)}
						{renderTotal(
							'Quantidade de lançamentos',
							total.QuantidadeTotalDeLancamentos
						)}
					</>
				</div>

				<div className='flex gap total'>
					<>
						{renderTotal('Total de vendas', toMoney(total.TotalDeVendas))}
						{renderTotal(
							'Total de vouchers baixados',
							toMoney(total.TotalDeVouchersBaixados)
						)}
						{renderTotal(
							'Quantidade de novos parceiros',
							total.TotalNovosParceiros
						)}
					</>
				</div>

				<div className='table'>
					<Table
						columns={tableColumns as any}
						data={tableFiltered}
						navProps={navProps}
						pageSize={ITEMS_PER_PAGE}
						noItensText='No momento, não há Dados para serem exibidas.'
						{...tableStyle}
						padding={[26, 16, 26, 16]}
						round={false}
						dashedWhenDisabled={false}
						tertiaryColor=''
						isLoading={isLoading}
						showPaginate={false}
					/>
				</div>
			</div>
		</Container>
	)
}

export default Dashboard
