import { keyframes } from "styled-components";

enum Size {
  xs,
  sm,
  md,
  lg,
}
export type SizeEnum = keyof typeof Size;

export const grid = {
  cols: 12,
  gutter: 16,
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 576,
};

export function mediaQuery(size: SizeEnum) {
  return (style: string) => {
    if (size === "lg") {
      return `@media screen and (max-width: ${grid.lg}px) {
				${style}
			}`;
    } else if (size === "md") {
      return `@media screen and (max-width: ${grid.md}px) {
				${style}
			}`;
    } else if (size === "sm") {
      return `@media screen and (max-width: ${grid.sm}px) {
				${style}
			}`;
    } else if (size === "xs") {
      return `@media screen and (max-width: ${grid.xs}px) {
				${style}
			}`;
    }
  };
}

export const tableStyle = {
  primaryColor: "var(--color-primary)",
  secondaryColor: "var(--color-primary)",
  backgroundColor: "transparent",
  fontSize: 12,
  fontFamily: "Regular",
  padding: [8, 8, 8, 8],
};

export const fadeIn = keyframes`
	from{
		opacity: 0.7;
	}
	to{
		opacity: 1;
	}
`;

export const animate = keyframes`
  to{
    opacity: 1;
    transform: initial;
  }
`;

export const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`;

export const skeleton = keyframes`
  from{
      background-position: 0;
  }
  to{
      background-position: -200%;
  }
`;
