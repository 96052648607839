import React, { useState } from "react";
import InputPrimary, { InputProps } from "../InputPrimary";
import Eye from "assets/images/eye.svg";
import EyeOff from "assets/images/eye-off.svg";
import { PasswordWrapper } from "./styles";

// import { Container } from './styles';

function InputPassword(props: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  function handleShowPassword() {
    setShowPassword((show) => !show);
  }
  return (
    <PasswordWrapper>
      <InputPrimary {...props} type={showPassword ? "text" : "password"}/>
      <button onClick={handleShowPassword} type="button">
        <img src={!showPassword ? Eye : EyeOff} alt="Ocultar / Mostrar senha" />
      </button>
    </PasswordWrapper>
  );
}

export default InputPassword;
