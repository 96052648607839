import { ICupom } from 'shared/interfaces/cupom'
import { IPaginated } from 'shared/interfaces/paginated'
import { IResponse } from 'shared/interfaces/response'
import axiosInstance from 'shared/utils/env'

export enum ICupomType {
	Percentual = 'Percentual',
	ValorFixo = 'ValorFixo'
}
export interface ICreateCupomRequest {
	Cnpj: string
	PorcentagemDeDesconto: number
	TipoDesconto: ICupomType
	Descricao: string
	QuantidadeDisponivel: number
	DataDeValidade: string
	ImagemBase64: string
	Titulo: string
}

export async function createCupom(payload: ICreateCupomRequest): Promise<void> {
	const response = await axiosInstance.post(`/cupom`, { ...payload })

	return response.data
}

export async function editCupom(
	payload: ICreateCupomRequest,
	cupomID: string
): Promise<void> {
	const response = await axiosInstance.patch(`/cupom/${cupomID}`, {
		...payload
	})

	return response.data
}

export interface IRequestPaginated {
	page: number
	limite: number
	search: string
}

export interface IResponsePaginated extends Omit<ICupom, 'Participantes'> {
	Parceiro: string
	QuantidadeDisponivel: number
	QuantidadeParticipantes: number
	Participantes?: [{ Nome?: string; Cpf?: string }]
}
export async function getCupomPaginatedByAdmin(
	payload: IRequestPaginated
): Promise<IResponse<IPaginated<IResponsePaginated[]>>> {
	const { limite, page, search } = payload
	const response = await axiosInstance.get(
		`/cupom?pagina=${page}&limite=${limite}&termoDaBusca=${search}`
	)

	return response.data
}

export async function getCupomPaginatedByOperator(
	payload: IRequestPaginated,
	parceiroId: string
): Promise<IResponse<IPaginated<IResponsePaginated[]>>> {
	const { limite, page, search } = payload
	const response = await axiosInstance.get(
		`/cupom/parceiro/${parceiroId}?pagina=${page}&limite=${limite}&termoDaBusca=${search}`
	)

	return response.data
}
export interface IResquestBlock {
	Codigo: string
	Motivo: string
}

export interface IResquestCancel {
	CupomId: string
	Motivo: string
}

export async function cancelCupom(payload: IResquestCancel): Promise<void> {
	const response = await axiosInstance.patch(`/cupom/cancelar`, { ...payload })

	return response.data
}

export async function blockCupom(payload: IResquestBlock): Promise<void> {
	const response = await axiosInstance.patch(`/cupom/bloquear`, { ...payload })

	return response.data
}

export async function unblockCupom(id: string): Promise<void> {
	const response = await axiosInstance.patch(`/cupom/desbloquear/${id}`)

	return response.data
}

export interface IRequestPaginated {
	page: number
	limite: number
	search: string
}

export interface IResponseCupons {
	Ativo: boolean
	Cnpj: string
	DataDeValidade: string
	DataHoraCadastro: string
	Descricao: string
	Id: string
	Imagem: string
	Parceiro: string
	ParceiroId: string
	PorcentagemDeDesconto: number
	QtdParticipantes: number
	QuantidadeDisponivel: number
	Titulo: string | null
	UsuarioIdQueCadastrou: string
	Codigo: string
}

export async function getCupomPaginated(
	pagePayload: IRequestPaginated
): Promise<IResponse<IPaginated<IResponseCupons[]>>> {
	const { limite, page, search } = pagePayload

	const response = await axiosInstance.get(`/cupom?pagina=${page}
	&limite=${limite}
	&termoDaBusca=${search}`)

	return response.data
}

export async function getCupomPaginatedAvailable(
	pagePayload: IRequestPaginated
): Promise<IResponse<IPaginated<IResponseCupons[]>>> {
	const { limite, page, search } = pagePayload

	const response =
		await axiosInstance.get(`/cupom/disponiveis-para-resgate?pagina=${page}
	&limite=${limite}
	&termoDaBusca=${search}`)

	return response.data
}

export async function rescueCupomRequest(code: string) {
	const response = await axiosInstance.patch(
		`/cupom/efetuar-baixa-do-cupom/${code}`
	)

	return response.data
}

export async function rescueCupomRequestByParticipant(id: string) {
	const response = await axiosInstance.patch(`/cupom/resgatar/${id}`)

	return response.data
}

export interface IResponseParticipants {
	Id: string
	CupomId: string
	Codigo: string
	Status: string
	DataDeValidade: string
	Participante: string
	Cpf: string
	DataHoraResgate: string
	Motivo?: string
	Cnpj: string
}
export async function getParticipantsOfTheCupom(
	id: string,
	pagePayload: IRequestPaginated
): Promise<IResponse<IPaginated<IResponseParticipants[]>>> {
	const { limite, page, search } = pagePayload

	const response =
		await axiosInstance.get(`/cupom/usuarios-do-cupom/${id}?pagina=${page}
	&limite=${limite}
	&termoDaBusca=${search}`)

	return response.data
}
