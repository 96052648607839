import styled from "styled-components";
import { mediaQuery } from "../../styles/functions";

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  height: 105px;
  border-bottom: 1px solid #e0e0e0;
  background-color: var(--color-branco);
  h2 {
    font-size: 24px;
    font-weight: 300;
    font-family: var(--font-oswald);
  }

  .img-logo {
    width: 100px;
    background: 0;
    border: 0;
  }

  ${mediaQuery("sm")(`
      padding: 0 24px;
    `)}
`;
