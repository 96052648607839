import { CashGenerateTypeEnum } from './cashback'
import { IEndereco } from './endereco'
import { IPartner } from './partner'
import { Status } from './status'

export interface IUser {
	Id?: string
	Nome: string
	DataDeNascimento: string
	Cpf: string
	Parceiro?: string
	DataHoraCadastro?: string
	Email: string
	Celular?: string
	Apelido?: string
	Ativo: boolean
	NivelDeAcesso: NivelDeAcessoEnum | null
	Endereco: IEndereco | null
	PermiteWhatsApp: boolean
	MinhasCarteiras?: ICarteiras[]
	Status: Status
	Motivo?: string
}

export interface ICarteiras {
	Id: string
	LogoParceiro: string
	NomeFantasia: string
	ParceiroId: string
	Cnpj: string
	Cpf: string
	ParticipanteId: string
	Saldo: number
	Ativo: boolean
	DataHoraCadastro: string
	DiasVencimentoParceiro: number
	Vouchers: IVoucher[]
}

export interface IVoucher {
	Id: string
	DataHoraCadastro: string
	Ativo: boolean
	ParceiroId: string
	ParticipanteId: string
	Valor: number
	Validade: string
	JaFoiUtilizado: boolean
	DataHoraResgate: string | null
	TipoVoucher: string
	ComoFoiGerado: CashGenerateTypeEnum
}
export interface IOperator extends IUser {
	Parceiros: IPartner[]
}

export interface IParticipant extends IUser {}

export enum NivelDeAcessoEnum {
	Administrador = 'Administrador',
	Participante = 'Participante',
	Operador = 'Operador'
}

export interface ICreateParticipant {
	nome: string
	email: string
	cpf: string
	senha: string
	endereco: IEndereco
}
