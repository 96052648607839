import { useEffect } from 'react'
import { Container } from './styles'
import InputPrimary from '../InputPrimary'
import useForm from '../../hooks/useForm'
import Button from '../ButtonPrimary'
import Icon from 'assets/images/search.svg'
import SelectPrimary from '../SelectPrimary'
import { ISelectOption } from 'shared/interfaces/options'
import DatePicker from '../DatePicker'
import { IFromTo } from 'shared/interfaces/common'

export interface IProps {
	title: string
	buttonName?: string
	onClick?: () => void
	returnSearch?: (search: string) => void
	placeholder?: string
	id?: string
	selectedDates?: IFromTo
	handleSetDates?: (from: IFromTo) => void
}

function HeaderPage(props: IProps) {
	const {
		title,
		buttonName,
		onClick,
		returnSearch,
		placeholder,
		id,
		handleSetDates,
		selectedDates
	} = props
	const search = useForm('campoOpcional')

	function handleSearch() {
		returnSearch && returnSearch(search.value)
	}

	useEffect(handleSearch, [search.value])
	return (
		<Container className='header-wrapper'>
			<h1 className='title'>{title}</h1>
			<div className='header'>
				{selectedDates && handleSetDates ? (
					<div className='period'>
						<DatePicker dateSetter={handleSetDates} value={selectedDates} />
					</div>
				) : null}
				{returnSearch ? (
					<InputPrimary
						{...search}
						id='search'
						placeholder={placeholder}
						Icon={Icon}
					/>
				) : null}

				{buttonName ? (
					<Button
						name={buttonName}
						onClick={onClick}
						disabled={false}
						id={id}
					/>
				) : null}
			</div>
		</Container>
	)
}

export default HeaderPage
