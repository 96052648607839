import { useEffect, useState } from 'react'

import { Container } from './styles'
import { ICupomPage } from './types'
import HeaderPage from 'shared/components/HeaderPage'
import Ticket from 'shared/components/Ticket'
import Modal from 'shared/components/Modal'
import DiscountBadge from 'shared/components/DiscountBadge'
import {
	IResponseCupons,
	getCupomPaginatedAvailable,
	rescueCupomRequestByParticipant
} from 'shared/services/cupom.service'
import { toasterError, toasterSuccess } from 'shared/utils/toaster'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { formatDate } from 'shared/utils/date'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/ButtonPrimary'
import InfiniteScroll from 'react-infinite-scroll-component'

function ListCupons() {
	const ITEMS_PER_PAGE = 12

	const [cuponsPage, setCuponsPage] = useState<ICupomPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
		hasNextPage: false
	})

	const [classAnimated, setAnimated] = useState('animated-right')
	const [showModal, setShowModal] = useState(false)
	const [cashbackDetail, setCashbackdetail] = useState<
		IResponseCupons | undefined
	>()
	const [loading, setLoading] = useState(false)
	const [cupom, setCupom] = useState<IResponseCupons[] | null>(null)

	function openModal(cupom: IResponseCupons) {
		setShowModal((show) => !show)
		setAnimated('')
		setCashbackdetail(cupom)
	}

	function onClose() {
		setShowModal((show) => !show)
		setCashbackdetail(undefined)
	}

	useEffect(processTable, [cuponsPage.page])

	function processTable() {
		;(async () => {
			try {
				setLoading(true)
				const response = await getCupomPaginatedAvailable({
					limite: ITEMS_PER_PAGE,
					page: cuponsPage.page,
					search: ''
				})

				setCupom(
					cupom ? [...cupom, ...response.Dados.Itens] : response.Dados.Itens
				)
				setCuponsPage({
					numberOfPages: response.Dados.NumeroTotalDePaginas,
					page: cuponsPage.page || 1,
					totalDocs: response.Dados.NumeroTotalDeItens,
					hasNextPage: response.Dados.TemProximaPagina
				})
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao buscar os Cupons'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	function returnVouchers(ticket: IResponseCupons) {
		return (
			<Ticket
				key={ticket.Id}
				image={ticket?.Imagem || ''}
				title={ticket.Titulo || ''}
				qtd={ticket.QuantidadeDisponivel}
				value={ticket.PorcentagemDeDesconto + '%'}
				description={ticket.Descricao}
				name={ticket.Parceiro}
				percent={ticket.PorcentagemDeDesconto}
				date={new Date(ticket.DataDeValidade) ?? new Date()}
				openModal={() => openModal(ticket)}
				height={175}
			/>
		)
	}

	function returnDate(date: string) {
		return formatDate(new Date(date), "dd 'de' MMMM")
	}

	const loadMoreData = () => {
		setCuponsPage({
			...cuponsPage,
			page: cuponsPage.page + 1
		})
	}

	function rescueCupom() {
		;(async () => {
			try {
				if (!cashbackDetail?.Id || !!!cupom) return
				setLoading(true)

				const response = await rescueCupomRequestByParticipant(
					cashbackDetail.Id
				)

				const deleteCupom = cupom?.filter(
					(voucher) => voucher.Id !== cashbackDetail.Id
				)
				setCupom(deleteCupom)
				onClose()
				toasterSuccess(
					response.Mensagem ? response.Mensagem : 'Cupom Resgatado!'
				)
			} catch (error: any) {
				toasterError(
					error?.response.data.Mensagem
						? error?.response.data.Mensagem
						: 'Ocorreu um erro ao resgatar o Cupom'
				)
			} finally {
				setLoading(false)
			}
		})()
	}

	return (
		<Container className={classAnimated}>
			<div>
				<div>
					<HeaderPage title='Cupons Disponíveis' />
				</div>

				<div>
					{!!cupom?.length ? (
						<InfiniteScroll
							dataLength={cupom.length}
							next={loadMoreData}
							hasMore={cuponsPage.hasNextPage}
							loader={<Loading />}
							className='tickets'
						>
							{cupom.map((ticket) => returnVouchers(ticket))}
						</InfiniteScroll>
					) : (
						<DefaultPagePlaceholder />
					)}
				</div>
			</div>

			<Modal onClose={onClose} isActive={showModal} title='Gerar Ticket'>
				<section>
					<figure className='image-modal'>
						<img src={cashbackDetail?.Imagem} alt={'Imagem logo'} />
					</figure>

					<span>
						<div className='header'>
							<p className='partner-name'>{cashbackDetail?.Parceiro}</p>
							{cashbackDetail?.PorcentagemDeDesconto ? (
								<DiscountBadge
									background='#fdb7d0'
									color='var(--color-rosa)'
									percetage={cashbackDetail?.PorcentagemDeDesconto + '%'}
								/>
							) : null}
						</div>

						<p className='description'>{cashbackDetail?.Titulo}</p>
						<p className='description'>{cashbackDetail?.Descricao}</p>

						<span className='header-value'>
							<p className='value-total'>Validade: </p>
							<p className='value-total'>
								{cashbackDetail?.DataDeValidade
									? returnDate(cashbackDetail?.DataDeValidade)
									: '-'}
							</p>
						</span>
						<span className='header-value'>
							<p className='value-total'>Restantes: </p>
							<p className='value-total'>
								{cashbackDetail?.QuantidadeDisponivel}
							</p>
						</span>

						<span className='header-value'>
							<p className='value-total'>Valor: </p>
							<p className='value-total'>
								{cashbackDetail?.PorcentagemDeDesconto}%
							</p>
						</span>
					</span>

					<Button
						name='Resgatar Cupom'
						onClick={rescueCupom}
						disabled={false}
					/>
				</section>
			</Modal>
		</Container>
	)
}

export default ListCupons
