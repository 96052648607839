import styled from 'styled-components'

export const Container = styled.div``
export const DeleteContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const TextDelete = styled.p`
	font-size: 14px;
	color: var(--color-primary);
	text-align: center;
`

export const TargetName = styled.p`
	font-size: 14px;
	color: var(--color-primary);
	margin-bottom: 40px;
	text-align: center;
`

export const TargetDescription = styled.p`
	font-size: 12px;
	color: var(--color-primary);
	margin: 20px 0;
	text-align: center;
`
export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
