import { NivelDeAcessoEnum } from 'shared/interfaces/user'
import { store } from 'shared/store'

export function isAdmin() {
	const user = store.getState().user

	return user.NivelDeAcesso === NivelDeAcessoEnum.Administrador ? true : false
}

export function isOperator() {
	const user = store.getState().user

	return user.NivelDeAcesso === NivelDeAcessoEnum.Operador ? true : false
}