import styled from 'styled-components'

export const DiscountBadgeWrapper = styled.div<{
	background: string
	color: string
}>`
	align-items: center;
	border-radius: 50%;
	display: flex;
	flex-wrap: nowrap;
	font-size: 16px;
	font-weight: bold;
	font-feature-settings: 'palt' 1;
	height: 50px;
	justify-content: center;
	letter-spacing: 0.025em;
	line-height: 1;
	width: 50px;

	.outer-jagged-parts {
		background-color: ${({ background }) => background};
		position: relative;
		width: 68%;
		height: 68%;
	}
	.inner-jagged-parts,
	.inner-jagged-parts:after,
	.inner-jagged-parts:before,
	.main {
		background-color: ${({ background }) => background};
		content: '';
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.outer-jagged-parts,
	.inner-jagged-parts,
	.inner-jagged-parts:after,
	.inner-jagged-parts:before {
		transform: rotate(22.5deg);
	}
	.main {
		align-items: center;
		align-content: center;
		color: ${({ color }) => color};
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		transform: rotate(-45deg);
		z-index: 999;
	}

	.large-text {
		font-size: 12px;
		padding: 0.1em 0;
	}
`
